import React, { useEffect } from "react"
import axios from "axios"
import { useAuth } from "../../../hooks/auth"
import "../styles/analysisReportPopup.css"
import { useSelector } from "react-redux"
import { checkUserToken } from "../../../hooks/session"
import { useNavigate } from "react-router-dom"

const AnalysisReportPopup = ({ repoData, onClose }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const dependencyCheckResponse = useSelector(
    (state) => state.dependencyCheckResponse,
  )
  const navigate = useNavigate()

  const dependencyData = dependencyCheckResponse.find((item) => {
    return item.id === repoData.id
  })

  console.log("dependencyCheckResponse from Redux: ", dependencyCheckResponse)

  const dependencies = dependencyData ? dependencyData.dependencies : {}
  const extarnalApis = dependencyData ? dependencyData.externalApi : []
  const databaseProperties = dependencyData
    ? dependencyData.databaseProperties
    : []

  const { cookies, setCookies, logout } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  return (
    <div className='analysis-report-wrapper'>
      <div className='analysis-report-data-wrapper'>
        <div className='ar-close-container'>
          <h5>Analysis Report</h5>
          <button className='ar-close-btn' onClick={onClose}>
            &times;
          </button>
        </div>
        <div className='ar-content-container'>
          {/*> dependencies */}
          <h5>Dependencies</h5>
          {dependencies && dependencies.current && dependencies.latest ? (
            <div className='report-table-container'>
              <table className='report-table'>
                <thead>
                  <tr>
                    <th>Dependency</th>
                    <th>Current Version</th>
                    <th>Latest Version</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(dependencies.current).map(([key, value]) => (
                    <tr
                      key={key}
                      style={{
                        // backgroundColor:
                        //   value !== dependencies.latest[key]
                        //     ? "#faf1e3"
                        //     : // fae4e4
                        //       "transparent",
                        // backgroundColor: value.startsWith("font")
                        //   ? "#faf1e3"
                        //   : value !== dependencies.latest[key]
                        //   ? "#effdbb"
                        //   : "transparent",
                        backgroundColor: /^[a-uA-UW-Zw-z]/.test(value)
                          ? "#faf1e3"
                          : value !== dependencies.latest[key]
                          ? "#f0fdc6"
                          : "transparent",
                      }}
                    >
                      <td>{key}</td>
                      <td>{value}</td>
                      <td>{dependencies.latest[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className='not-avialable'>No dependency data available</p>
          )}
          {/*> Third party api calls */}
          <h5 className='second-sub-heading'>Third party api calls </h5>
          {extarnalApis ? (
            <div className='report-table-container'>
              <table className='report-table'>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>External API</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(extarnalApis).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className='not-avialable'>No external API available</p>
          )}
          <button className='ar-cancel-button' onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default AnalysisReportPopup
