import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../../../hooks/auth"
import plus from "../../../Images/bulkMigration/Vectorplus.png"
import loaderGif from "../../../Images/loader.gif"
import success from "../../../Images/success.jpg"
import "../styles/addProfilePopup.css"
import { useDispatch, useSelector } from "react-redux"
import {
  setArgoConnectedRepo,
  setJenkinsCredentials,
  setJenkinsCredentialsRedux,
} from "../../../redux/actions"
import { useNavigate } from "react-router-dom"
import { checkUserToken } from "../../../hooks/session"

const ConnectRepoPopup = ({ onClose }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const gitCredentials = useSelector((state) => state.gitCredentials)

  const [deploymentProfiles, setDeploymentProfiles] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const deploymentProfilesRedux = useSelector(
    (state) => state.deploymentProfiles,
  )
  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  //   const [formData, setFormData] = useState({
  //     id: "",
  //     name: "",
  //     kind: "",
  //     description: "",
  //     deployment_profile: "",
  //     // Add additional fields for each kind of credential
  //     username: "",
  //     password: "",
  //     privateKey: "",
  //     secretText: "",
  //     certificate: "",
  //     serviceAccountKey: null,
  //   })

  const [hemlURL, setHemlURL] = useState("")

  const dispatch = useDispatch()
  useEffect(() => {
    const allFieldsFilled =
      //   formData.name !== "" &&
      //   formData.kind !== "" &&
      //   formData.deployment_profile !== "" &&
      //   formData.id !== "" &&
      //   formData.description !== "" &&
      //   formData.username !== "" &&
      hemlURL !== ""
    setIsButtonDisabled(!allFieldsFilled)
  }, [hemlURL])

  useEffect(() => {
    if (deploymentProfilesRedux) {
      setDeploymentProfiles(deploymentProfilesRedux)
    } else {
      setDeploymentProfiles([])
    }
  }, [deploymentProfilesRedux])

  const [showCredentialCreatedPopup, setShowCredentialCreatedPopup] =
    useState(false)
  const [createCredentialLoader, setCreateCredentialLoader] = useState(false)

  const handleChange = (e) => {
    // const { name, value } = e.target
    // setFormData({ ...formData, [name]: value })
    setHemlURL(e.target.value)
  }

  const handleConnectRepo = async () => {
    // setCreateCredentialLoader(true)
    let { username, accessToken, domain } = gitCredentials
    try {
      let data = {
        // name: "example-repo",
        helm_repo_url: hemlURL,
        git_username: username,
        git_password: accessToken,
      }

      //   console.log("FormData:", data)

      onClose()

      const response = await axios.post(`${baseUrl}/argocd/connect-repo`, data)
      console.log("argocd/connect-repo:", response.data)

      // Fetch connected Repo from ArgoCD
      const ArgocdConnectedRepoResponse = await axios.get(
        `${baseUrl}/argocd/list-connected-repos`,
      )
      const connectedRepo = ArgocdConnectedRepoResponse.data.items
      console.log("connectedRepo>>>>", connectedRepo)
      if (connectedRepo) {
        dispatch(setArgoConnectedRepo(connectedRepo))
      } else {
        dispatch(setArgoConnectedRepo([]))
      }

      //   setCreateCredentialLoader(false)
      //   setShowCredentialCreatedPopup(true)

      //   const getResponse = await axios.get(`${baseUrl}/jenkins/get_credential`)
      //   console.log("/jenkins/get_credential: ", getResponse)
      //   dispatch(setJenkinsCredentialsRedux(getResponse.data))
    } catch (error) {
      console.error("Error:", error)
      alert("Error while connecting the Repo!")
      //   if (error.response && error.response.status === 403) {
      //     alert(
      //       "Profile name already exists! Please enter an unique Profile Name.",
      //     )
      //   } else {
      //     alert("Unable to reach Jenkins URL!")
      //   }
      //   setCreateCredentialLoader(false)
    }
  }

  const handleCancel = () => {
    setHemlURL("")
    onClose()
  }

  // Define a function to render additional fields based on the selected kind
  //   const renderAdditionalFields = () => {
  //     switch (formData.kind) {
  //       case "UsernameWithPassword":
  //         return (
  //           <>
  //             <label className='ap-box-label'>
  //               Username
  //               <input
  //                 className='ap-input'
  //                 type='text'
  //                 name='username'
  //                 value={formData.username}
  //                 onChange={handleChange}
  //                 placeholder='Enter Username'
  //               />
  //             </label>
  //             <label className='ap-box-label'>
  //               Password
  //               <input
  //                 className='ap-input'
  //                 type='password'
  //                 name='password'
  //                 value={formData.password}
  //                 onChange={handleChange}
  //                 placeholder='Enter Password'
  //               />
  //             </label>
  //           </>
  //         )
  //       case "SSHUsernamePrivateKey":
  //         return (
  //           <>
  //             <label className='ap-box-label'>
  //               Username
  //               <input
  //                 className='ap-input'
  //                 type='text'
  //                 name='username'
  //                 value={formData.username}
  //                 onChange={handleChange}
  //                 placeholder='Enter Username'
  //               />
  //             </label>
  //             <label className='ap-box-label'>
  //               Private Key
  //               <input
  //                 className='ap-input'
  //                 type='text'
  //                 name='privateKey'
  //                 value={formData.privateKey}
  //                 onChange={handleChange}
  //                 placeholder='Enter Private Key'
  //               />
  //             </label>
  //           </>
  //         )
  //       case "SecretText":
  //         return (
  //           <label className='ap-box-label'>
  //             Secret Text
  //             <input
  //               className='ap-input'
  //               type='text'
  //               name='secretText'
  //               value={formData.secretText}
  //               onChange={handleChange}
  //               placeholder='Enter Secret Text'
  //             />
  //           </label>
  //         )
  //       case "Certificate":
  //         return (
  //           <label className='ap-box-label'>
  //             Certificate
  //             <input
  //               className='ap-input'
  //               type='text'
  //               name='certificate'
  //               value={formData.certificate}
  //               onChange={handleChange}
  //               placeholder='Enter Certificate'
  //             />
  //           </label>
  //         )
  //       case "GoogleServiceAccount":
  //         return (
  //           <label className='ap-box-label'>
  //             Service Account Key
  //             <input
  //               className='ap-input'
  //               type='text'
  //               name='serviceAccountKey'
  //               value={formData.serviceAccountKey}
  //               onChange={handleChange}
  //               placeholder='Enter Service Account Key'
  //             />
  //           </label>
  //         )
  //       default:
  //         return null
  //     }
  //   }

  //   const handleFileChange = (e) => {
  //     const file = e.target.files[0] // Get the uploaded file
  //     setFormData({ ...formData, serviceAccountKey: file }) // Store the file object
  //   }
  console.log("deploymentProfiles", deploymentProfiles)
  return (
    <div className='add-profile-wrapper'>
      {/* {showCredentialCreatedPopup ? (
        <div className='success-profile-data-wrapper'>
          <div className='ap-close-btn-container'>
            <button className='ap-close-btn' onClick={handleCancel}>
              &times;
            </button>
          </div>
          <img className='success-icon' src={success} alt='done'></img>
          <p>Credential Created</p>
        </div>
      ) : ( */}
      <div className='add-profile-data-wrapper'>
        <div className='ap-close-container'>
          <h4>Connect Repository</h4>
          <button className='ap-close-btn' onClick={onClose}>
            &times;
          </button>
        </div>

        <div className='ap-content-container'>
          <div>
            <label className='ap-box-label'>
              Helm Repo URL
              <input
                className='ap-input'
                type='text'
                name='description'
                value={hemlURL}
                onChange={handleChange}
                placeholder='Enter Description'
              />
            </label>

            <button
              // className='create-profile-btn'
              className={`create-profile-btn ${
                isButtonDisabled ? "disabled-btn" : ""
              }`}
              onClick={handleConnectRepo}
              disabled={isButtonDisabled}
            >
              <img
                className='plus-icon'
                src={createCredentialLoader ? loaderGif : plus}
                alt='plus'
              />
              Connect Repo
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectRepoPopup
