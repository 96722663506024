import "../styles/dashboard.css"
import sshKey from "../images1/ssh.png"
import jenkins from "../images1/jenkins2.jpg"
import assess from "../images1/single-repo.png"
import git from "../images1/multi-repo.webp"
import cluster from "../Images/cluster1.png"
import logOut from "../Images/logout.png"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import "../styles/dashboard.css"
import { useAuth } from "../hooks/auth"
import { Logo } from "./constants"

import { checkUserToken } from "../hooks/session"

const Dashboard = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"

  const { cookies, setCookies, logout } = useAuth()

  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const navigate = useNavigate()

  const handleSshClick = () => {
    navigate("/sshgen")
  }
  const handleJenkinsClick = () => {
    navigate("/jenkins")
  }

  const handleClusterClick = () => {
    navigate("/cluster")
  }

  const handleAssessClick = () => {
    navigate("/assess")
  }

  const handleGitClick = () => {
    navigate("/bulk-migration/git-login")
  }

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const handlelogout = () => {
    logout()
    navigate("/")
  }

  return (
    <div className='home-wrapper slide-dashboard'>
      <div className='dashboard-home-data'>
        <div className='logo-logout-container'>
          <div className='logo-container'>
            <img src={Logo} alt='niveus' />
          </div>
          <div className='logout-container' onClick={handlelogout}>
            <p className='logout-font'>Logout</p>
            <img className='logout-icon' src={logOut} alt='' />
          </div>
        </div>

        <div className='dashboard-wrap'>
          <div className='dash-card-wrapper side-card'>
            <Card className='card' onClick={handleSshClick}>
              <Card.Img className='side-card-img' variant='top' src={sshKey} />
              <Card.Body className='card-body'>
                <Card.Title className='card-title'>
                  Generate SSH keys
                </Card.Title>
                <Card.Text className='card-text'>
                  User specific ssh-key value pairs generated. Use the public
                  key in git and jenkins VM to establish secure shell
                  connection.
                </Card.Text>
              </Card.Body>
              {/* <Button
                className='dashboard-button'
                variant='primary'
                onClick={handleSshClick}
              >
                SSH keygen
              </Button> */}
            </Card>
          </div>

          {/* <div className='center-dashboard-cards'> */}
          <div className='dash-card-wrapper side-card'>
            <Card onClick={handleAssessClick}>
              <Card.Img className='side-card-img' variant='top' src={assess} />
              <Card.Body>
                <Card.Title className='card-title'>
                  Deploy Single Repository{" "}
                </Card.Title>
                <Card.Text className='card-text'>
                  Assess your code in repository, generate artifactsfor
                  deployment and deploy to GKE through jenkins.
                </Card.Text>
              </Card.Body>
              {/* <Button
                  className='dashboard-button'
                  variant='primary'
                  onClick={handleAssessClick}
                >
                  Go
                </Button> */}
            </Card>
          </div>

          <div className='dash-card-wrapper side-card'>
            <Card onClick={handleGitClick}>
              <Card.Img
                // className='card-img card-img-assess'
                className='side-card-img'
                variant='top'
                src={git}
              />
              <Card.Body>
                <Card.Title className='card-title'>
                  Deploy Multiple Repositories
                </Card.Title>
                <Card.Text className='card-text'>
                  Login to your git, Generate deployment artifacts and Deploy
                  application on gke cluster
                </Card.Text>
              </Card.Body>
              {/* <Button
                  className='dashboard-button'
                  variant='primary'
                  onClick={handleGitClick}
                >
                  Git Login
                </Button> */}
            </Card>
          </div>
          {/* </div> */}

          <div className='dash-card-wrapper side-card'>
            <Card onClick={handleJenkinsClick}>
              <Card.Img className='side-card-img' variant='top' src={jenkins} />
              <Card.Body>
                <Card.Title className='card-title'>
                  Generate Jenkins VM
                </Card.Title>
                <Card.Text className='card-text'>
                  Generate a vitual machine in your GCp project from a jenkins
                  machine image and setup loadbalancer to expose IP.
                </Card.Text>
              </Card.Body>
              {/* <Button
                className='dashboard-button'
                variant='primary'
                onClick={handleJenkinsClick}
              >
                Create VM
              </Button> */}
            </Card>
          </div>

          <div className='dash-card-wrapper side-card'>
            <Card onClick={handleClusterClick}>
              <Card.Img className='side-card-img' variant='top' src={cluster} />
              <Card.Body>
                <Card.Title className='card-title'>Create Cluster</Card.Title>
                <Card.Text className='card-text'>
                  Generate a vitual machine in your GCp project from a jenkins
                  machine image and setup loadbalancer to expose IP.
                </Card.Text>
              </Card.Body>
              {/* <Button
                className='dashboard-button'
                variant='primary'
                onClick={handleJenkinsClick}
              >
                Create VM
              </Button> */}
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
