import React, { useEffect, useState, useRef } from "react";
import "../styles/signuplogin.css";
import LoadingGif from "../Images/loader.gif";
import backgroundImage from "../Images/background-image.jpg";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useAuth } from "../hooks/auth";
import { Logo } from "./constants";
import { useNavigate } from "react-router-dom";

function Login() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputData, setInputData] = useState({ email: "", password: "" });
  const [loader, setLoader] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const inputDataRef = useRef();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleChange = (e, label) => {
    setInputData({
      ...inputData,
      [label]: e.target.value,
    });
  };

  useEffect(() => {
    inputDataRef.current = inputData;
  }, [inputData]);

  useEffect(() => {
    const checkFormFilled = () => {
      for (const key in inputData) {
        if (inputData[key] === "") {
          return true;
        }
      }
      return false;
    };
    setIsButtonDisabled(checkFormFilled());
  }, [inputData]);

  const handleLogin = async () => {
    const data = inputDataRef.current;
    if (!isButtonDisabled) {
      setLoader(true);
      try {
        await login(data);
      } finally {
        setLoader(false);
      }
    }
  };

  const handleSignupClick = () => navigate("/signup");

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Enter" && !isButtonDisabled) {
        await handleLogin();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isButtonDisabled]);

  return (
    <div>
      <div className="login-wrapper">
        <div className="home-img">
          <img src={backgroundImage} alt="background" />
        </div>
        <div className="login-right">
          <img src={Logo} alt="logo" />

          <div className="login-details wrapper-l">
            <p className="login-details-heading">Sign into your account</p>
            <label>
              Email address
              <input
                className="input-login"
                type="email"
                name="git_repo"
                value={inputData.email}
                onChange={(e) => handleChange(e, "email")}
                placeholder="Enter your Email address"
              />
            </label>
            <label>
              Password
              <div className="password-input-wrapper input-login">
                <input
                  className="input-password"
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={inputData.password}
                  onChange={(e) => handleChange(e, "password")}
                  placeholder="Enter your password"
                />
                <div
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
                </div>
              </div>
            </label>
          </div>
          <div className="button-wrap-login">
            <button
              className="login-button"
              style={isButtonDisabled ? { backgroundColor: "#9F9F9F" } : {}}
              onClick={handleLogin}
            >
              {loader ? (
                <img
                  className="signup-loading-gif"
                  src={LoadingGif}
                  alt="loading..."
                />
              ) : (
                "Login"
              )}
            </button>
          </div>
          <p className="signup-redirect-heading">
            Don't have an account?{" "}
            <span className="signup-redirect" onClick={handleSignupClick}>
              Sign Up
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
