import backgroundimage from "../Images/background-image.jpg"
import "../styles/jenkins.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Bars, ColorRing } from "react-loader-spinner"
import LoadingGif from "../Images/loader.gif"
import SuccessIcon from "../Images/success.jpg"
import FailIcon from "../Images/bulkMigration/failIcon.png"
import { FiUpload } from "react-icons/fi"
import "../styles/jenkingen.css"
import Dropdown from "./Dropdown"
import JenkinsLb from "./JenkinsLbGen"
import { useAuth } from "../hooks/auth"
import { Logo } from "./constants"
import { checkUserToken } from "../hooks/session"

const Jenkinsgen = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const navigate = useNavigate()

  const [project, setProject] = useState("")
  const [region, setRegion] = useState("")
  const [zone, setZone] = useState("")
  const [instanceName, setInstanceName] = useState("")
  const [machineType, setMachineType] = useState("")
  const [network, setNetwork] = useState("")
  const [subnet, setSubnet] = useState("")

  const [isSshButtonDisabled, setSshButtonDisabled] = useState(true)
  const [isJsonFileUploaded, setIsJsonFileUploaded] = useState(false)

  const [jsonLoader, setJsonLoader] = useState(false)
  const [loader, setLoader] = useState(false)

  const [popup, setPopup] = useState(false)
  const [jsonFileName, setJsonFileName] = useState("")

  const [regionOptions, setRegionOptions] = useState()
  const [zoneOptions, setZoneOptions] = useState()
  const [machineTypeOptions, setMachineTypeOptions] = useState()

  const [regionLoading, setRegionLoading] = useState(false)
  const [zoneLoading, setZoneLoading] = useState(false)
  const [machineTypeLoading, setMachineTypeLoading] = useState(false)

  const [validate, setValidate] = useState(false)
  const [validateLoading, setValidateLoading] = useState(false)

  const [selectedOption, setSelectedOption] = useState("serviceAccount")
  const [error, setError] = useState(false)
  const [isVmGenerated, setIsVmGenerated] = useState(false)

  const handleProjectChange = (e) => {
    setProject(e.target.value)
  }
  const handleNetworkChange = (e) => {
    setNetwork(e.target.value)
  }
  const handleSubnetChange = (e) => {
    setSubnet(e.target.value)
  }

  const getApiUrl = (endpoint) => {
    if (selectedOption === "adc") {
      return `${baseUrl}/${endpoint}Adc`
    }
    return `${baseUrl}/${endpoint}`
  }

  const handleValidate = async () => {
    setValidateLoading(true)
    setValidate(false)
    await fetchRegions(project)
    // setValidate(true)
    setValidateLoading(false)
  }

  const handleRegionChange = (value) => {
    fetchZones(project, value)
  }

  const handleZoneChange = (value) => {
    fetchMachineType(project, value)
  }

  const handleInstanceNameChange = (e) => {
    setInstanceName(e.target.value)
  }

  const handleJsonFileChange = (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append("file", file)
    setJsonFileName(file.name)
    setJsonLoader(true)
    console.log(cookies.token)
    try {
      axios.post(getApiUrl("saveJsonFile"), formData).then((response) => {
        setJsonLoader(false)
        setIsJsonFileUploaded(true)
        setProject(response.data.project)
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    const isFormValid =
      project &&
      zone &&
      region &&
      instanceName &&
      machineType &&
      network &&
      subnet &&
      (selectedOption === "adc" || isJsonFileUploaded)

    setSshButtonDisabled(!isFormValid)
  }, [
    project,
    zone,
    region,
    instanceName,
    machineType,
    isJsonFileUploaded,
    subnet,
    network,
    selectedOption,
  ])

  const generateJenkinsVM = () => {
    setLoader(true)
    try {
      const requestData = {
        project: project,
        region: region,
        zone: zone,
        vm_name: instanceName,
        network,
        subnet,
        // sourceIp,
        // targetIp,
        machine_type: machineType,
        user_email: cookies.email,
      }

      axios.post(getApiUrl("createVM"), requestData).then((response) => {
        setLoader(false)
        if (response.data.terraformOutput.signal === null) {
          setError(true)
        } else {
          setIsVmGenerated(true)
        }
        setPopup(true)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const fetchRegions = async (project) => {
    setRegionLoading(true)
    try {
      const requestData = {
        project: project,
      }

      await axios
        .post(getApiUrl("getRegions"), requestData)
        .then((response) => {
          // Handle the response as needed
          setRegionLoading(false)
          const regionsData = response.data.regions.map((opt, index) => ({
            id: index + 1,
            name: opt,
          }))
          setRegionOptions(regionsData)
          setValidate(true)
        })
    } catch (err) {
      console.error(err)
      alert("something went wrong!")
      setValidate(false)
    }
  }

  const fetchZones = async (project, region) => {
    setZoneLoading(true)
    try {
      const requestData = {
        project: project,
        region: region,
      }

      await axios.post(getApiUrl("getZones"), requestData).then((response) => {
        // Handle the response as needed
        setZoneLoading(false)
        const zonesData = response.data.zones.map((opt, index) => ({
          id: index + 1,
          name: opt,
        }))
        setZoneOptions(zonesData)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const fetchMachineType = async (project, zone) => {
    setMachineTypeLoading(true)
    try {
      const requestData = {
        project: project,
        zone: zone,
      }

      await axios
        .post(getApiUrl("getMachineTypes"), requestData)
        .then((response) => {
          // Handle the response as needed
          setMachineTypeLoading(false)
          const machineTypeData = response.data.machineTypes.map(
            (opt, index) => ({ id: index + 1, name: opt }),
          )
          setMachineTypeOptions(machineTypeData)
        })
    } catch (err) {
      console.error(err)
    }
  }

  const handleLogoClick = () => {
    navigate("/dashboard")
  }

  const handleClose = () => {
    setPopup(false)
  }

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
    resetForm()
  }

  const resetForm = () => {
    setProject("")
    setRegion("")
    setZone("")
    setInstanceName("")
    setMachineType("")
    setNetwork("")
    setSubnet("")
    setIsJsonFileUploaded(false)
    setJsonFileName("")
    setValidate(false)
  }

  // console.log("selectedOption>>>", selectedOption)
  return (
    <div className='home-wrapper'>
      <div className='home-img'>
        <img src={backgroundimage} alt='backgroundimage' />
      </div>
      <div className='home-data'>
        <img src={Logo} onClick={handleLogoClick} alt='logo' />
        <p className='jenkin-details-heading'>Create Jenkins vm</p>

        <div className='jenkin-wrapper'>
          <label className='jenkins-input-vm '>
            Select user authentication
            <div className='radio-buttons'>
              <label
                className={`radio-button ${
                  selectedOption === "serviceAccount" ? "selected" : ""
                }`}
              >
                <input
                  type='radio'
                  value='serviceAccount'
                  checked={selectedOption === "serviceAccount"}
                  onChange={handleOptionChange}
                />
                Service Account
              </label>
              <label
                className={`radio-button ${
                  selectedOption === "adc" ? "selected" : ""
                }`}
              >
                <input
                  type='radio'
                  value='adc'
                  checked={selectedOption === "adc"}
                  onChange={handleOptionChange}
                />
                ADC
              </label>
            </div>
          </label>

          {selectedOption === "serviceAccount" && (
            <label className='jenkins-input-vm '>
              Upload service account JSON file
              <div className='input-json-container'>
                <div className='upload-logo-container'>
                  <div className='ring-or-upload-icon'>
                    {jsonLoader ? (
                      <ColorRing
                        visible={true}
                        height='25'
                        width='25'
                        ariaLabel='color-ring-loading'
                        wrapperClass='color-ring-wrapper'
                        wrapperStyle={{
                          position: "absolute",
                          left: "6px",
                        }}
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    ) : (
                      <FiUpload className='upload-icon' />
                    )}
                  </div>

                  <p className='select-file-text'>
                    {jsonFileName ? jsonFileName : "Upload file"}
                  </p>
                </div>
                <input
                  className='custom-file-input'
                  type='file'
                  accept='.json'
                  onChange={handleJsonFileChange}
                />
              </div>
            </label>
          )}

          <label className='jenkins-input-vm'>
            Project
            <div className='jenkins-project'>
              <input
                type='text'
                placeholder='niveus-llm'
                disabled={selectedOption !== "adc" && !isJsonFileUploaded}
                value={project}
                onChange={handleProjectChange}
              />
              <button
                style={
                  validate
                    ? { backgroundColor: "#00ab66" }
                    : selectedOption === "adc" || isJsonFileUploaded
                    ? { backgroundColor: "#f7b526" }
                    : { backgroundColor: "#9F9F9F" }
                }
                disabled={
                  validate || (selectedOption !== "adc" && !isJsonFileUploaded)
                }
                onClick={handleValidate}
              >
                {validateLoading ? (
                  <ColorRing
                    visible={true}
                    height='25'
                    width='25'
                    ariaLabel='color-ring-loading'
                    wrapperClass='color-ring-wrapper'
                    wrapperStyle={{
                      position: "relative",
                    }}
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : validate ? (
                  "Validated"
                ) : (
                  "Validate"
                )}
              </button>
            </div>
          </label>

          <div className='region-zone-container'>
            <label className='jenkins-input-vm region-input'>
              Region
              <Dropdown
                options={regionOptions ? regionOptions : []}
                label='name'
                id='id'
                placeholder='Enter region'
                selectedVal={region}
                handleChange={(val) => {
                  handleRegionChange(val)
                  setRegion(val)
                }}
                loading={regionLoading}
                validate={validate}
                dropdownup={false}
              />
            </label>
            <label className='jenkins-input-vm'>
              Zone
              <Dropdown
                options={zoneOptions ? zoneOptions : []}
                label='name'
                id='id'
                placeholder='Enter geographical zone'
                selectedVal={zone}
                handleChange={(val) => {
                  handleZoneChange(val)
                  setZone(val)
                }}
                loading={zoneLoading}
                validate={validate}
                dropdownup={false}
              />
            </label>
          </div>
          <div className='region-zone-container'>
            <label className='jenkins-input-vm region-input'>
              Machine type
              <Dropdown
                options={machineTypeOptions ? machineTypeOptions : []}
                label='name'
                id='id'
                placeholder='Enter machine type'
                selectedVal={machineType}
                handleChange={(val) => setMachineType(val)}
                loading={machineTypeLoading}
                validate={validate}
                dropdownup={true}
              />
              <span
                className='machine-type-note'
                style={!validate ? { display: "none" } : {}}
              >
                Recommended: e2-medium or higher
              </span>
            </label>
            <label className='jenkins-input-vm'>
              Instance name
              <input
                type='text'
                placeholder='test-instance'
                value={instanceName}
                onChange={handleInstanceNameChange}
              />
            </label>
          </div>

          <div className='region-zone-container'>
            <label className='jenkins-input-vm'>
              Network
              <input
                type='text'
                placeholder='Enter vpc network'
                value={network}
                onChange={handleNetworkChange}
              />
            </label>
            <label className='jenkins-input-vm' style={{ marginLeft: "1rem" }}>
              Subnet
              <input
                type='text'
                placeholder='Enter vpc subnet'
                value={subnet}
                onChange={handleSubnetChange}
              />
            </label>
          </div>

          <button
            className={`assess-button-jenkins vm-button-jenkins ${
              isSshButtonDisabled ? "disabled" : ""
            }`}
            onClick={generateJenkinsVM}
            disabled={isSshButtonDisabled || isVmGenerated} // Disable the button if the VM is already generated
          >
            {loader ? (
              <img
                className='loading-gif-vm'
                src={LoadingGif}
                alt='loading...'
              />
            ) : isVmGenerated ? (
              "Generated Jenkins VM"
            ) : (
              "Generate Jenkins VM"
            )}
          </button>

          <JenkinsLb
            project={project}
            region={region}
            zone={zone}
            instanceName={instanceName}
          />
          {popup && (
            <div className='migrate-pop-wrapper'>
              <div className='migrate-pop ' id='migrate-pop-height'>
                {error ? (
                  <div className='pop-wrapper-start'>
                    <div>
                      {" "}
                      <img className='failIconVm' src={FailIcon} alt='fail' />
                    </div>
                    <p className='app-migrated'>Error creating Jenkins VM</p>
                    <button className='assess-button-vm' onClick={handleClose}>
                      OK
                    </button>
                  </div>
                ) : (
                  <div className='pop-wrapper-start'>
                    <div>
                      {" "}
                      <img
                        className='successIconVm'
                        src={SuccessIcon}
                        alt='sucess'
                      />
                    </div>
                    <p className='app-migrated'>Jenkins VM is created</p>
                    <button className='assess-button-vm' onClick={handleClose}>
                      OK
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Jenkinsgen
