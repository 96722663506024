import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import "../styles/assess.css"
import backgroundImage from "../Images/background-image.jpg"
import LoadingGif from "../Images/loader.gif"
import vector from "../Images/vector.png"
import { FaArrowRight } from "react-icons/fa6"
import { useAuth } from "../hooks/auth"
import { checkUserToken } from "../hooks/session"
import { Logo } from "./constants"
import jsonData from "../jsonData/assess_repo_input.json"

const Assess = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const navigate = useNavigate()

  const [assess, setAssess] = useState(false)
  const [appType, setAppType] = useState("")
  const [repo, setRepo] = useState("")
  const [token, setToken] = useState("")
  const [branch, setBranch] = useState("")
  const [loading, setLoading] = useState(false)
  // const [migrateLoading, setMigrateLoading] = useState(false);
  const [skipAssessLoading, setSkipAssessLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState("private")
  const [repoHasDocker, setRepoHasDocker] = useState(false)
  const [json, setJson] = useState({
    application_Type: "",
    artifactId: "",
    name: "",
    version: "",
    dependencies: [],
    db_properties: [],
    external_api: [],
    url: "",
    username: "",
    password: "",
    driver: "",
    port: "",
  })
  const [isChecked, setIsChecked] = useState(false)
  const [validity, setValidity] = useState(false)
  const [urlNotSupported, setUrlNotSupported] = useState(false)
  const [connection, setConnection] = useState(false)
  const [repoName, setRepoName] = useState("")
  // const [isProceedButtonDisabled, setProceedButtonDisabled] = useState(true);
  // const [ssh_email, setSshEmail] = useState("");
  const [user_name, setUserName] = useState("")
  const [oneTimeSkip, setOneTimeSkip] = useState(false)

  const handleErrors = ["notValid", "connectError", "invalidUrl"]

  const handleChangeRepo = (e) => {
    setRepo(e.target.value)
  }

  const handleChangeToken = (e) => {
    setToken(e.target.value)
  }

  const handleChangeBranch = (e) => {
    setBranch(e.target.value)
    // setProceedButtonDisabled(branch !== "" && repo !== "");
  }

  const assessData = async () => {
    console.log(repo)
    setAssess(false)
    setValidity(false)
    setUrlNotSupported(false)
    setConnection(false)
    setLoading(true)

    try {
      await axios
        .post(`${baseUrl}/assess-repo`, {
          gitRepo: repo,
          repoType: selectedOption,
          branch: branch,
          git_user_name: user_name,
          token: token,
          user_email: cookies.email,
        })
        .then((response) => {
          setLoading(false)
          setRepoHasDocker(response.data.dockerfile)
          setRepoName(response.data.name)
          if (handleErrors.includes(response.data)) {
            setValidity(response.data === "notValid" ? true : false)
            setConnection(response.data === "connectError" ? true : false)
            setUrlNotSupported(response.data === "invalidUrl" ? true : false)
          } else {
            setAppType(response.data.type)
            console.log("Response:", response.data)
            response.data.applicationProperties
              ? setJson({
                  application_Type: response.data.type,
                  version: response.data.version,
                  properties: response.data.applicationProperties,
                  dependencies: response.data.dependencies,
                  latestversion: response.data.latestVersion
                    ? response.data.latestVersion
                    : response.data.LatestVersion,
                  db_properties: response.data.databaseProperties,
                  external_api: response.data.externalApi,
                })
              : setJson({
                  application_Type: response.data.type,
                  version: response.data.version,
                  dependencies: response.data.dependencies,
                  latestversion: response.data.latestVersion
                    ? response.data.latestVersion
                    : response.data.LatestVersion,
                  db_properties: response.data.databaseProperties,
                  external_api: response.data.externalApi,
                })
            setAssess(true)
          }
        })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    if (!isChecked) {
      setRepo(jsonData.git_repo)
      setToken(jsonData.git_token)
      setBranch(jsonData.git_branch)
      setUserName(jsonData.git_username)
    } else {
      setRepo("")
      setToken("")
      setBranch("")
      setUserName("")
    }
  }

  const handleLogoClickVector = async () => {
    try {
      setAssess(false)
      await axios.get(`${baseUrl}/clear_project_directory`)
      setConnection(false)
      setValidity(false)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const handleLogoClick = async () => {
    try {
      navigate("/dashboard")
      setAssess(false)
      setConnection(false)
      setValidity(false)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
    }
  }
  const handleErrorClose = () => {
    setConnection(false)
    setValidity(false)
    setUrlNotSupported(false)
  }

  const handleMigrate = () => {
    const jsonData = {
      application: json.application_Type,
      repo: repo,
      token: token,
      branch: branch,
      selectedOption: selectedOption,
      repoHasDocker: repoHasDocker,
      repoName: repoName,
      user_email: cookies.email,
    }
    navigate("/migrate", {
      state: jsonData,
    })
  }

  const handleChangeUserName = (e) => {
    setUserName(e.target.value)
  }

  const handleForwardClick = async () => {
    setValidity(false)
    setOneTimeSkip(false)
    setSkipAssessLoading(true)
    const jsonData = {
      application: json.application_Type,
      repo: repo,
      token: token,
      branch: branch,
      selectedOption: selectedOption,
      repoHasDocker: repoHasDocker,
      repoName: repoName,
      user_email: cookies.email,
    }
    try {
      await axios
        .post(`${baseUrl}/clone-repo`, {
          gitRepo: repo,
          repoType: selectedOption,
          branch: branch,
          git_user_name: user_name,
          token: token,
          user_email: cookies.email,
        })
        .then((response) => {
          setSkipAssessLoading(false)
          setOneTimeSkip(true)
          setRepoHasDocker(response.data.dockerfile)
          setRepoName(response.data.name)
          jsonData.application = response.data.type
          jsonData.repoName = response.data.name
          if (handleErrors.includes(response.data)) {
            setValidity(response.data === "notValid" ? true : false)
          } else {
            setAppType(response.data.type)
            console.log("Response:", response.data)
            navigate("/migrate", {
              state: jsonData,
            })
          }
        })
    } catch (error) {
      setSkipAssessLoading(false)
      console.error(error)
    }
    console.log(jsonData)
  }

  return (
    <div className='assess-wrapper'>
      <div className='home-img'>
        <img src={backgroundImage} />
      </div>
      <div className='assess-right'>
        <div className='header-container'>
          <div className='logo-container'>
            <img src={Logo} alt='logo' onClick={handleLogoClick} />
          </div>
          <div className='nav-container'>
            <button
              className='nav-forward'
              style={
                !repo || !branch || !oneTimeSkip
                  ? { backgroundColor: "#9F9F9F" }
                  : {}
              }
              disabled={(!branch && !repo) || !oneTimeSkip}
              onClick={handleMigrate}
            >
              <FaArrowRight />
            </button>
          </div>
        </div>
        <div className='assess-git-options'>
          <div className='wrapper-p'>
            <p>Select repository type</p>
            <label>
              <input
                className='assess-input'
                type='radio'
                value='private'
                checked={selectedOption === "private"}
                onChange={handleOptionChange}
              />
              Private(https)
            </label>

            <label>
              <input
                className='assess-input'
                type='radio'
                value='public/ssh'
                checked={selectedOption === "public/ssh"}
                onChange={handleOptionChange}
              />
              Public/SSH
            </label>
          </div>
        </div>
        <div className='repo-details wrapper-p'>
          <p>Git Repository Details</p>
          <label>
            <input
              className='assess-input'
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />{" "}
            Show sample inputs
          </label>
          <input
            className='input-assess'
            type='text'
            name='git_repo'
            value={repo}
            onChange={handleChangeRepo}
            placeholder='Git repository url'
          />
          {selectedOption === "private" && (
            <>
              <input
                className='input-assess'
                type='user_name'
                name='user_name'
                value={user_name}
                onChange={handleChangeUserName}
                placeholder='Git Username'
              />

              <input
                className='input-assess'
                type='password'
                name='token'
                value={token}
                onChange={handleChangeToken}
                placeholder='Accesstoken'
              />
            </>
          )}
          <input
            className='input-assess'
            type='text'
            name='branch'
            value={branch}
            onChange={handleChangeBranch}
            placeholder='Branch'
          />
        </div>
        <div className='button-wrap-assess'>
          <button
            className='assess-button '
            style={
              !repo || !branch
                ? { backgroundColor: "#9F9F9F" }
                : {} && loading
                ? { backgroundColor: "#5a57ff" }
                : {}
            }
            onClick={assessData}
            disabled={!branch && !repo}
          >
            {loading ? (
              <img className='loading-gif' src={LoadingGif} />
            ) : (
              "Assess code"
            )}
          </button>
          <button
            className='assess-button '
            style={
              !repo || !branch
                ? { backgroundColor: "#9F9F9F" }
                : {} && skipAssessLoading
                ? { backgroundColor: "#5a57ff" }
                : {}
            }
            onClick={handleForwardClick}
            disabled={!branch && !repo}
          >
            {skipAssessLoading ? (
              <img className='loading-gif' src={LoadingGif} />
            ) : (
              "Skip"
            )}
          </button>
        </div>
      </div>

      {validity && (
        <div className='errorpopup'>
          <div className='docker-errorpopup-content'>
            <h3>Error</h3>
            <p className='unsupportedProject'>Unsupported Project Type !!!</p>
            <button className='error-close' onClick={handleErrorClose}>
              close
            </button>
          </div>
        </div>
      )}
      {urlNotSupported && (
        <div className='errorpopup'>
          <div className='docker-errorpopup-content'>
            <h3>Error</h3>
            <p className='unsupportedProject'>Unsupported git url</p>
            <button className='error-close' onClick={handleErrorClose}>
              close
            </button>
          </div>
        </div>
      )}
      {connection && (
        <div className='errorpopup'>
          <div className='docker-errorpopup-content'>
            <h3>Warning</h3>
            <p className='unsupportedProject'>
              Connection refused! Make sure the server is running
            </p>
            <button className='error-close' onClick={handleErrorClose}>
              close
            </button>
          </div>
        </div>
      )}

      {assess && (
        <div className='dependency-popup'>
          <div className='popup-content'>
            <div className='popup-topbar'>
              <p>{appType}</p>
              <img src={vector} onClick={handleLogoClickVector} />
            </div>
            <div className='dependency-tables'>
              <table>
                <thead>
                  <tr>
                    <th>Dependencies</th>
                    <th>Current Version</th>
                    <th>Latest Version</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(json.dependencies).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                      <td>{json.latestversion[key]}</td>
                    </tr>
                  ))}
                  {json.db_properties &&
                  Object.entries(json.db_properties).length > 0 ? (
                    <>
                      <tr>
                        <th>DB properties</th>
                        <th colspan='2'>Data</th>
                        {/* <th></th> */}
                      </tr>
                      {Object.entries(json.db_properties).map(
                        ([key, value]) => (
                          <tr key={key}>
                            <td>{key}</td>
                            <td
                              colspan='2'
                              style={
                                key.toLowerCase() === "password"
                                  ? { WebkitTextSecurity: "disc" }
                                  : {}
                              }
                            >
                              {value}
                            </td>
                          </tr>
                        ),
                      )}
                    </>
                  ) : null}
                  {json.external_api &&
                  Object.entries(json.external_api).length > 0 ? (
                    <>
                      <tr>
                        <th>File Name</th>
                        <th colspan='2'>External API</th>
                      </tr>
                      {Object.entries(json.external_api).map(([key, value]) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td colspan='2'>{value}</td>
                        </tr>
                      ))}
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>

            <button className=' popbutton' onClick={handleMigrate}>
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Assess
