import React, { useEffect, useState } from "react"
import LeftPanel from "./LeftPanel"
import axios from "axios"
import "./styles/argocd.css"
import { useAuth } from "../../hooks/auth"
import { useNavigate } from "react-router-dom"
import { checkUserToken } from "../../hooks/session"
import LoadingGif from "../../Images/loader.gif"
import ConnectRepoPopup from "./popup_screens/ArgocdConnectRepo"
import AddAppPopup from "./popup_screens/ArgocdAddApp"
import plus from "../../Images/bulkMigration/Vectorplus.png"
import { useDispatch, useSelector } from "react-redux"
import {
  setArgoApp,
  setArgoConnectedRepo,
  setArgocdLoginData,
  setIsArgocdLoggedIn,
} from "../../redux/actions"

function Argocd() {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [signinLoader, setSigninLoader] = useState(false)
  const [isArgocdSignedin, setIsArgocdSignedin] = useState(false)
  const [showConnectRepoPopup, setShowConnectRepoPopup] = useState(false)
  const [showAddAppPopup, setShowAddAppPopup] = useState(false)

  const argoConnectedRepoRedux = useSelector((state) => state.connectedRepo)
  const argoAppRedux = useSelector((state) => state.argoApp)
  const argoLoginDataRedux = useSelector((state) => state.argoLoginData)
  const isArgocdSignedinRedux = useSelector((state) => state.isLoggedIn)

  const [formData, setFormData] = useState(argoLoginDataRedux)

  console.log("argoAppRedux>>", argoAppRedux)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  console.log("formdata>>", formData)

  const handleArgocdLogin = async () => {
    setSigninLoader(true)

    dispatch(
      setArgocdLoginData({
        argocdUrl: formData.argocdUrl,
        argocdUsername: formData.argocdUsername,
        argocdPassword: formData.argocdPassword,
      }),
    )

    let Data = {
      server: formData.argocdUrl,
      username: formData.argocdUsername,
      password: formData.argocdPassword,
    }

    try {
      const response = await axios.post(
        `${baseUrl}/argocd/argocd-login`,
        Data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      console.log(response.data)
      setSigninLoader(false)
      setIsArgocdSignedin(true)
      dispatch(setIsArgocdLoggedIn(true))

      // Fetch connected Repo from ArgoCD
      const ArgocdConnectedRepoResponse = await axios.get(
        `${baseUrl}/argocd/list-connected-repos`,
      )
      const connectedRepo = ArgocdConnectedRepoResponse.data.items
      console.log("connectedRepo>>>>", connectedRepo)
      if (connectedRepo) {
        dispatch(setArgoConnectedRepo(connectedRepo))
      } else {
        dispatch(setArgoConnectedRepo([]))
      }

      // Fetch connected Repo from ArgoCD
      const ArgocdAppResponse = await axios.get(`${baseUrl}/argocd/list-app`)
      const app = ArgocdAppResponse.data.items
      console.log("list app>>>>", app)
      if (app) {
        dispatch(setArgoApp(app))
      } else {
        dispatch(setArgoApp([]))
      }
    } catch (error) {
      console.error("Error connecting to the project:", error)
      setSigninLoader(false)
      alert("Error while logging into ArgoCD!")
    }
  }
  const allFieldsFilled = Object.values(formData).every(
    (field) => typeof field === "string" && field.trim() !== "",
  )

  const handleConnectRepo = () => {
    setShowConnectRepoPopup(true)
  }

  const handleAddApp = () => {
    setShowAddAppPopup(true)
  }

  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel-argo'>
        <h4 className='title'>ArgoCD Setup</h4>
        <div className='form-container'>
          <div className='form-group'>
            <label className='label'>
              ArgoCD Server URL
              <input
                type='text'
                name='argocdUrl'
                value={formData.argocdUrl}
                onChange={handleChange}
                className='input'
                placeholder='Enter ArgoCD URL'
              />
            </label>
          </div>
          <div className='form-group'>
            <label className='label'>
              ArgoCD Username
              <input
                type='text'
                name='argocdUsername'
                value={formData.argocdUsername}
                onChange={handleChange}
                className='input'
                placeholder='Enter ArgoCD Username'
              />
            </label>
          </div>
          <div className='form-group'>
            <label className='label'>
              ArgoCD Password
              <input
                type='password'
                name='argocdPassword'
                value={formData.argocdPassword}
                onChange={handleChange}
                className='input'
                placeholder='Enter ArgoCD Password'
              />
            </label>
          </div>

          <button
            onClick={handleArgocdLogin}
            className={`connect-button ${
              !allFieldsFilled ? "disabled-btn" : ""
            } ${isArgocdSignedin || isArgocdSignedinRedux ? "signed-in" : ""}`}
            disabled={
              !allFieldsFilled || isArgocdSignedin || isArgocdSignedinRedux
            }
          >
            {signinLoader ? (
              <img
                className='loading-gif-vm'
                src={LoadingGif}
                alt='loading...'
              />
            ) : isArgocdSignedin || isArgocdSignedinRedux ? (
              "SIGNED IN"
            ) : (
              "SIGN IN"
            )}
          </button>
        </div>

        <div className='break-line'></div>
        {(isArgocdSignedin || isArgocdSignedinRedux) && (
          <div className='button-container'>
            <div className='button-wrapper first'>
              <button
                onClick={handleConnectRepo}
                className='connect-button ca-btn'
              >
                <img className='plus-icon' src={plus} alt='plus' />
                Connect Repo
              </button>

              <div className='profile-table-container-argo'>
                <table className='profile-table-argo'>
                  <thead>
                    <tr>
                      <th>Connected Repo URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {argoConnectedRepoRedux &&
                    argoConnectedRepoRedux.length > 0 ? (
                      argoConnectedRepoRedux.map((repoObj, index) => (
                        <tr key={index}>
                          <td className='truncate-url' title={repoObj.repo}>
                            {repoObj.repo}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>Fetching data..</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='vertical-break-line'></div>
            <div className='button-wrapper second'>
              <button onClick={handleAddApp} className='connect-button ca-btn'>
                <img className='plus-icon' src={plus} alt='plus' />
                New App
              </button>

              {/* <div className='profile-table-container-argo'>
              <table className='profile-table-argo'>
                <thead>
                  <tr>
                    <th>App Name</th>
                    <th>Repository</th>
                  </tr>
                </thead>
                <tbody>
                  <td>nodejsapp</td>
                  <td>
                    https://gitlab.niveussolutions.com/prajwal.amin/helm-repo.git
                  </td>
                </tbody>
              </table>
            </div> */}
              <div className='profile-table-container-argo'>
                <table className='profile-table-argo'>
                  <thead>
                    <tr>
                      <th>App Name</th>
                      <th>Repository</th>
                    </tr>
                  </thead>
                  <tbody>
                    {argoAppRedux && argoAppRedux.length > 0 ? (
                      argoAppRedux.map((repoObj, index) => (
                        <tr key={index}>
                          <td className='truncate-app-name'>
                            {repoObj.metadata.name}
                          </td>
                          <td
                            className='truncate-url'
                            title={repoObj.spec.source.repoURL}
                          >
                            {repoObj.spec.source.repoURL}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='2'>Fetching data..</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      {showConnectRepoPopup && (
        <ConnectRepoPopup onClose={() => setShowConnectRepoPopup(false)} />
      )}

      {showAddAppPopup && (
        <AddAppPopup onClose={() => setShowAddAppPopup(false)} />
      )}
    </div>
  )
}

export default Argocd
