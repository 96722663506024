import backgroundimage from "../Images/background-image.jpg"
// import "../styles/jenkins.css"
// import "../styles/jenkinsLbGen.css"
import "../styles/cluster.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Bars, ColorRing } from "react-loader-spinner"
import LoadingGif from "../Images/loader.gif"
import SuccessIcon from "../Images/success.jpg"
import FailIcon from "../Images/bulkMigration/failIcon.png"
import { FiUpload } from "react-icons/fi"
import Dropdown from "./Dropdown"
import JenkinsLb from "./JenkinsLbGen"
import { useAuth } from "../hooks/auth"
import { Logo } from "./constants"
import { checkUserToken } from "../hooks/session"
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1"
// import VisibilityIcon from "@mui/icons-material/Visibility"
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { FaEye } from "react-icons/fa"
import { FaEyeSlash } from "react-icons/fa"

const CreateCluster = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const navigate = useNavigate()

  const [project, setProject] = useState("")
  const [region, setRegion] = useState("")
  const [zone, setZone] = useState("")
  const [clusterName, setClusterName] = useState("")
  const [machineType, setMachineType] = useState("")
  const [network, setNetwork] = useState("")
  const [subnet, setSubnet] = useState("")
  const [numNode, setNumNode] = useState("")
  const [namespace, setNamspace] = useState("")
  const [authNetwork, setAuthNetwork] = useState("")

  const [isSshButtonDisabled, setSshButtonDisabled] = useState(true)
  const [isJsonFileUploaded, setIsJsonFileUploaded] = useState(false)
  const [isInstallBtnDisabled, setIsInstallBtnDisabled] = useState(true)

  const [jsonLoader, setJsonLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loaderInstall, setLoaderInstall] = useState(false)

  const [popup, setPopup] = useState(false)
  const [argocdSuccessPopup, setArgocdSuccessPopup] = useState(false)
  const [jsonFileName, setJsonFileName] = useState("")

  const [regionOptions, setRegionOptions] = useState()
  const [zoneOptions, setZoneOptions] = useState()
  const [machineTypeOptions, setMachineTypeOptions] = useState()

  const [regionLoading, setRegionLoading] = useState(false)
  const [zoneLoading, setZoneLoading] = useState(false)
  const [machineTypeLoading, setMachineTypeLoading] = useState(false)

  const [validate, setValidate] = useState(false)
  const [validateLoading, setValidateLoading] = useState(false)

  const [selectedOption, setSelectedOption] = useState("serviceAccount")
  const [error, setError] = useState(false)
  const [errorInstallArgo, setErrorInstallArgo] = useState(false)

  const [isClusterGenerated, setIsClusterGenerated] = useState(false)
  const [isArgocdInstalled, setIsArgocdInstalled] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")

  const [showPassword, setShowPassword] = useState(false)
  const [argocdPassword, setArgocdPassword] = useState("")

  const handleProjectChange = (e) => {
    setProject(e.target.value)
  }
  const handleNetworkChange = (e) => {
    setNetwork(e.target.value)
  }
  const handleSubnetChange = (e) => {
    setSubnet(e.target.value)
  }

  const handleAuthNetworkChange = (e) => {
    setAuthNetwork(e.target.value)
  }

  const getApiUrl = (endpoint) => {
    if (selectedOption === "adc") {
      return `${baseUrl}/cluster/${endpoint}Adc`
    }
    return `${baseUrl}/cluster/${endpoint}`
  }

  const handleValidate = async () => {
    setValidateLoading(true)
    setValidate(false)
    await fetchRegions(project)
    // setValidate(true)
    setValidateLoading(false)
  }

  const handleRegionChange = (value) => {
    fetchZones(project, value)
  }

  const handleZoneChange = (value) => {
    fetchMachineType(project, value)
  }

  const handleClusterNameChange = (e) => {
    setClusterName(e.target.value)
  }

  const handleNamSpaceChange = (e) => {
    setNamspace(e.target.value)
  }

  const handleNumNodeChange = (e) => {
    setNumNode(e.target.value)
  }

  const handleJsonFileChange = (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append("file", file)
    setJsonFileName(file.name)
    setJsonLoader(true)
    // console.log(cookies.token)
    try {
      axios.post(getApiUrl("saveJsonFile"), formData).then((response) => {
        setJsonLoader(false)
        setIsJsonFileUploaded(true)
        setProject(response.data.project)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    const isFormValid =
      project &&
      zone &&
      region &&
      clusterName &&
      machineType &&
      network &&
      subnet &&
      numNode &&
      (selectedOption === "adc" || isJsonFileUploaded)

    setSshButtonDisabled(!isFormValid)
  }, [
    project,
    zone,
    region,
    clusterName,
    machineType,
    isJsonFileUploaded,
    subnet,
    network,
    selectedOption,
    numNode,
  ])

  useEffect(() => {
    setIsInstallBtnDisabled(!namespace)
  }, [namespace])

  const generateCluster = async () => {
    setLoader(true)

    const requestData = {
      project_id: project,
      region: region,
      zone: zone,
      cluster_name: clusterName,
      vpc: network,
      subnet,
      number_of_nodes: numNode,
      machine_type: machineType,
      auth_network: authNetwork,
      user_email: cookies.email,
    }

    const extractErrorMessage = (message) => {
      // const regex = /Error 409:(.*)/g
      const regex = /Error \d+:(.*)/g
      const matches = regex.exec(message)
      if (matches) {
        let errorMessage = matches[1].trim()
        // Remove ANSI escape codes
        // eslint-disable-next-line no-control-regex
        errorMessage = errorMessage.replace(/\u001b\[.*?m/g, "")
        return errorMessage
      }
      return "No error message found"
    }

    try {
      const response = await axios.post(getApiUrl("createCluster"), requestData)

      setLoader(false)

      console.log("response.data.message>>", response.data.message)

      if (response.data.result === "Failure") {
        // setError(true)
        const extractedMessage = extractErrorMessage(
          response.data.message || "Unknown error",
        )
        setErrorMessage(extractedMessage)
        console.error("Cluster creation failed: ", extractedMessage)

        // Check if extracted message contains "firewall"
        if (response.data.message.includes("firewall")) {
          setIsClusterGenerated(true)
          setPopup(true)
          setLoader(false)
        } else {
          setError(true)
          setLoader(false)
        }
      } else {
        setIsClusterGenerated(true)
        setPopup(true)
        setLoader(false)
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data.message === "string"
      ) {
        if (err.response.data.message.includes("firewall")) {
          setIsClusterGenerated(true)
          setPopup(true)
          setLoader(false)
        } else {
          const extractedMessage = extractErrorMessage(
            err.response.data.message || "Unknown error",
          )
          setErrorMessage(extractedMessage)
          console.error("Error during cluster creation: ", extractedMessage)
          setLoader(false)
          setError(true)
        }
      } else {
        const extractedMessage = extractErrorMessage("Unknown error")
        setErrorMessage(extractedMessage)
        console.error("Error during cluster creation: ", extractedMessage)
        setLoader(false)
        setError(true)
      }
    }
  }

  const installArgocd = async () => {
    setLoaderInstall(true)

    const requestData = {
      namespace,
    }

    try {
      const response = await axios.post(getApiUrl("install-argo"), requestData)

      setLoaderInstall(false)
      setIsArgocdInstalled(true)
      setArgocdSuccessPopup(true)

      console.log("response.data.message>>", response.data)
      setArgocdPassword(response.data.initialPassword)
    } catch (err) {
      console.error("Error during cluster creation: ", err)
      setLoaderInstall(false)
      setErrorInstallArgo(true)
    }
  }

  const fetchRegions = async (project) => {
    setRegionLoading(true)
    try {
      const requestData = {
        project: project,
      }

      await axios
        .post(getApiUrl("getRegions"), requestData)
        .then((response) => {
          // Handle the response as needed
          setRegionLoading(false)
          const regionsData = response.data.regions.map((opt, index) => ({
            id: index + 1,
            name: opt,
          }))
          setRegionOptions(regionsData)
          setValidate(true)
        })
    } catch (err) {
      console.error(err)
      alert("something went wrong!")
      setValidate(false)
    }
  }

  const fetchZones = async (project, region) => {
    setZoneLoading(true)
    try {
      const requestData = {
        project: project,
        region: region,
      }

      await axios.post(getApiUrl("getZones"), requestData).then((response) => {
        // Handle the response as needed
        setZoneLoading(false)
        const zonesData = response.data.zones.map((opt, index) => ({
          id: index + 1,
          name: opt,
        }))
        setZoneOptions(zonesData)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const fetchMachineType = async (project, zone) => {
    setMachineTypeLoading(true)
    try {
      const requestData = {
        project: project,
        zone: zone,
      }

      await axios
        .post(getApiUrl("getMachineTypes"), requestData)
        .then((response) => {
          // Handle the response as needed
          setMachineTypeLoading(false)
          const machineTypeData = response.data.machineTypes.map(
            (opt, index) => ({ id: index + 1, name: opt }),
          )
          setMachineTypeOptions(machineTypeData)
        })
    } catch (err) {
      console.error(err)
    }
  }

  const handleLogoClick = () => {
    navigate("/dashboard")
  }

  const handleClose = () => {
    setPopup(false)
    setError(false)
    setArgocdSuccessPopup(false)
    setErrorInstallArgo(false)
  }

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
    resetForm()
  }

  const resetForm = () => {
    setProject("")
    setRegion("")
    setZone("")
    setClusterName("")
    setMachineType("")
    setNetwork("")
    setSubnet("")
    setIsJsonFileUploaded(false)
    setJsonFileName("")
    setValidate(false)
  }

  // console.log("selectedOption>>>", selectedOption)
  return (
    <div className='home-wrapper'>
      <div className='home-img'>
        <img src={backgroundimage} alt='backgroundimage' />
      </div>
      <div className='home-data'>
        <img src={Logo} onClick={handleLogoClick} alt='logo' />
        <p className='jenkin-details-heading'>Create Cluster</p>

        <div className='jenkin-wrapper'>
          <label className='jenkins-input-vm '>
            Select user authentication
            <div className='radio-buttons'>
              <label
                className={`radio-button ${
                  selectedOption === "serviceAccount" ? "selected" : ""
                }`}
              >
                <input
                  type='radio'
                  value='serviceAccount'
                  checked={selectedOption === "serviceAccount"}
                  onChange={handleOptionChange}
                />
                Service Account
              </label>
              <label
                className={`radio-button ${
                  selectedOption === "adc" ? "selected" : ""
                }`}
              >
                <input
                  type='radio'
                  value='adc'
                  checked={selectedOption === "adc"}
                  onChange={handleOptionChange}
                  disabled
                />
                ADC
              </label>
            </div>
          </label>

          {selectedOption === "serviceAccount" && (
            <label className='jenkins-input-vm '>
              Upload service account JSON file
              <div className='input-json-container'>
                <div className='upload-logo-container'>
                  <div className='ring-or-upload-icon'>
                    {jsonLoader ? (
                      <ColorRing
                        visible={true}
                        height='25'
                        width='25'
                        ariaLabel='color-ring-loading'
                        wrapperClass='color-ring-wrapper'
                        wrapperStyle={{
                          position: "absolute",
                          left: "6px",
                        }}
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    ) : (
                      <FiUpload className='upload-icon' />
                    )}
                  </div>

                  <p className='select-file-text'>
                    {jsonFileName ? jsonFileName : "Upload file"}
                  </p>
                </div>
                <input
                  className='custom-file-input'
                  type='file'
                  accept='.json'
                  onChange={handleJsonFileChange}
                />
              </div>
            </label>
          )}

          <label className='jenkins-input-vm'>
            Project
            <div className='jenkins-project'>
              <input
                type='text'
                placeholder='niveus-llm'
                disabled={selectedOption !== "adc" && !isJsonFileUploaded}
                value={project}
                onChange={handleProjectChange}
              />
              <button
                style={
                  validate
                    ? { backgroundColor: "#00ab66" }
                    : selectedOption === "adc" || isJsonFileUploaded
                    ? { backgroundColor: "#f7b526" }
                    : { backgroundColor: "#9F9F9F" }
                }
                disabled={
                  validate || (selectedOption !== "adc" && !isJsonFileUploaded)
                }
                onClick={handleValidate}
              >
                {validateLoading ? (
                  <ColorRing
                    visible={true}
                    height='25'
                    width='25'
                    ariaLabel='color-ring-loading'
                    wrapperClass='color-ring-wrapper'
                    wrapperStyle={{
                      position: "relative",
                    }}
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : validate ? (
                  "Validated"
                ) : (
                  "Validate"
                )}
              </button>
            </div>
          </label>

          <div className='region-zone-container'>
            <label className='jenkins-input-vm region-input'>
              Region
              <Dropdown
                options={regionOptions ? regionOptions : []}
                label='name'
                id='id'
                placeholder='Enter region'
                selectedVal={region}
                handleChange={(val) => {
                  handleRegionChange(val)
                  setRegion(val)
                }}
                loading={regionLoading}
                validate={validate}
                dropdownup={false}
              />
            </label>
            <label className='jenkins-input-vm'>
              Zone
              <Dropdown
                options={zoneOptions ? zoneOptions : []}
                label='name'
                id='id'
                placeholder='Enter geographical zone'
                selectedVal={zone}
                handleChange={(val) => {
                  handleZoneChange(val)
                  setZone(val)
                }}
                loading={zoneLoading}
                validate={validate}
                dropdownup={false}
              />
            </label>
          </div>
          <div className='region-zone-container'>
            <label className='jenkins-input-vm region-input'>
              Machine type
              <Dropdown
                options={machineTypeOptions ? machineTypeOptions : []}
                label='name'
                id='id'
                placeholder='Enter machine type'
                selectedVal={machineType}
                handleChange={(val) => setMachineType(val)}
                loading={machineTypeLoading}
                validate={validate}
                dropdownup={true}
              />
              <span
                className='machine-type-note'
                style={!validate ? { display: "none" } : {}}
              >
                Recommended: e2-medium or higher
              </span>
            </label>
            <label className='jenkins-input-vm'>
              num-nodes
              <input
                type='text'
                placeholder='Enter number of nodes'
                value={numNode}
                onChange={handleNumNodeChange}
              />
            </label>
          </div>

          <div className='region-zone-container'>
            <label className='jenkins-input-vm'>
              Network
              <input
                type='text'
                placeholder='Enter vpc network'
                value={network}
                onChange={handleNetworkChange}
              />
            </label>
            <label className='jenkins-input-vm' style={{ marginLeft: "1rem" }}>
              Subnet
              <input
                type='text'
                placeholder='Enter vpc subnet'
                value={subnet}
                onChange={handleSubnetChange}
              />
            </label>
          </div>

          <div className='region-zone-container'>
            <label className='jenkins-input-vm'>
              Cluster name
              <input
                type='text'
                placeholder='test-cluster'
                value={clusterName}
                onChange={handleClusterNameChange}
              />
            </label>
            <label className='jenkins-input-vm' style={{ marginLeft: "1rem" }}>
              Authorised Network
              <input
                type='text'
                placeholder='0.0.0.0/32'
                value={authNetwork}
                onChange={handleAuthNetworkChange}
              />
            </label>
          </div>

          {/* <label className='cluster-input'>
            Cluster name
            <input
              type='text'
              placeholder='test-cluster'
              value={clusterName}
              onChange={handleClusterNameChange}
            />
          </label> */}

          <button
            className={`assess-button-jenkins vm-button-jenkins ${
              isClusterGenerated
                ? "generated disabled"
                : isSshButtonDisabled
                ? "disabled"
                : ""
            }`}
            onClick={generateCluster}
            disabled={isClusterGenerated || isSshButtonDisabled}
          >
            {loader ? (
              <img
                className='loading-gif-vm'
                src={LoadingGif}
                alt='loading...'
              />
            ) : isClusterGenerated ? (
              "Generated Cluster"
            ) : (
              "Generate Cluster"
            )}
          </button>

          <label className='cluster-input' style={{ marginTop: "1rem" }}>
            Namespace
            <input
              type='text'
              placeholder='Enter namespace'
              value={namespace}
              onChange={handleNamSpaceChange}
            />
          </label>

          <button
            className={`assess-button-jenkins vm-button-jenkins 
        ${
          isArgocdInstalled
            ? "generated disabled"
            : isInstallBtnDisabled
            ? "disabled"
            : ""
        }`}
            onClick={installArgocd}
            disabled={isInstallBtnDisabled || isArgocdInstalled}
          >
            {loaderInstall ? (
              <img
                className='loading-gif-vm'
                src={LoadingGif}
                alt='loading...'
              />
            ) : isArgocdInstalled ? (
              "ArgoCD Installed"
            ) : (
              "Install ArgoCD"
            )}
          </button>

          {popup && (
            <div className='migrate-pop-wrapper'>
              <div
                className='migrate-pop '
                id='migrate-pop-height'
                // style={{ height: "40%", width: "27%" }}
              >
                <div className='pop-wrapper-start'>
                  <div>
                    {" "}
                    <img
                      className='successIconVm'
                      src={SuccessIcon}
                      alt='sucess'
                    />
                  </div>
                  <p className='app-migrated'>Cluster is created!</p>
                  <button className='assess-button-vm' onClick={handleClose}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}

          {error && (
            <div className='migrate-pop-wrapper'>
              <div
                className='migrate-pop '
                id='migrate-pop-height'
                style={{ height: "40%", width: "27%" }}
              >
                <div className='pop-wrapper-start'>
                  <div>
                    {" "}
                    <img
                      className='failIconVm'
                      src={FailIcon}
                      alt='fail'
                      style={{ marginTop: "2%" }}
                    />
                  </div>
                  <h4 className='error-message-cluster-heading'>Error!</h4>
                  <p className='error-message-cluster' title={errorMessage}>
                    {/* Error creating Cluster */}
                    {errorMessage}
                  </p>
                  <button className='assess-button-vm' onClick={handleClose}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* initial login password of argocd */}
          {/* Error: Unable to connect to cluster */}
          {argocdSuccessPopup && (
            <div className='migrate-pop-wrapper'>
              <div
                className='migrate-pop'
                id='migrate-pop-height'
                style={{ height: "48%", width: "32%" }}
              >
                <div className='pop-wrapper-start'>
                  <div>
                    <img
                      className='successIconVm'
                      src={SuccessIcon}
                      alt='success'
                    />
                  </div>
                  <p className='app-migrated'>ArgoCD Installed!</p>
                  <p className='argocd-password-label'>
                    ArgoCD Login password:
                  </p>
                  <div className='Password-wrapper'>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={argocdPassword}
                      readOnly
                      className='Password-input'
                    />
                    <button
                      className='eye-icon-button'
                      onClick={togglePasswordVisibility}
                    >
                      {/* <img */}
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                      {/* src={SuccessIcon} */}
                      {/* alt={showPassword ? "Hide" : "Show"} */}
                      {/* /> */}
                    </button>
                  </div>
                  <button className='assess-button-vm' onClick={handleClose}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}

          {errorInstallArgo && (
            <div className='migrate-pop-wrapper'>
              <div
                className='migrate-pop '
                id='migrate-pop-height'
                style={{ height: "40%", width: "27%" }}
              >
                <div className='pop-wrapper-start'>
                  <div>
                    {" "}
                    <img
                      className='failIconVm'
                      src={FailIcon}
                      alt='fail'
                      style={{ marginTop: "2%" }}
                    />
                  </div>
                  <h4 className='error-message-cluster-heading'>Error!</h4>
                  <p>
                    {/* Error creating Cluster */}
                    Unable to connect to the Cluster!
                  </p>
                  <button className='assess-button-vm' onClick={handleClose}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default CreateCluster
