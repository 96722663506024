// Deployments.js
import React, { useEffect, useState } from "react"
import LeftPanel from "./LeftPanel"
import plus from "../../Images/bulkMigration/Vectorplus.png"
import deleteIcon from "../../Images/bulkMigration/delete.png"
import editIcon from "../../Images/bulkMigration/editIcon.png"
import loaderGif from "../../Images/loader.gif"
import "./styles/deploymentProf.css"
import AddProfilePopup from "./deployment_profile/AddProfilePopup.jsx"
import { useAuth } from "../../hooks/auth"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { setDeploymentProfiles } from "../../redux/actions"
import EditProfilePopup from "./deployment_profile/EditProfilePopup.jsx"
import { checkUserToken } from "../../hooks/session"
import { useNavigate } from "react-router-dom"

function Deployments() {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const dispatch = useDispatch()

  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const deploymentProfilesRedux = useSelector(
    (state) => state.deploymentProfiles,
  )

  const [showAddProfilePopup, setShowAddProfilePopup] = useState(false)
  const [showEditProfilePopup, setShowEditProfilePopup] = useState(false)
  const [deploymentProfiles, setDeploymentProfilesState] = useState([])
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false)
  const [clickedProfileName, setClickedProfileName] = useState("")
  const [clickedProfileNameForEdit, setClickedProfileNameForEdit] = useState("")
  const [deleteLoader, setDeleteLoader] = useState(false)

  console.log("deploymentProfiles>>>", deploymentProfilesRedux)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    if (deploymentProfilesRedux) {
      setDeploymentProfilesState(deploymentProfilesRedux)
    } else {
      setDeploymentProfilesState([])
    }
  }, [deploymentProfilesRedux])

  const handleAddProfile = () => {
    setShowAddProfilePopup(true)
  }

  const onEditIconClick = (profile) => {
    setClickedProfileNameForEdit(profile)
    setShowEditProfilePopup(true)
  }

  const onDeleteIconClick = (profile) => {
    setClickedProfileName(profile.profile_name)
    setShowDeleteConfirmPopup(true)
  }

  const onConfirmDeleteProfile = async () => {
    setDeleteLoader(true)
    await axios
      .delete(`${baseUrl}/deployment/delete`, {
        data: { profile_name: clickedProfileName },
      })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error)
        if (error.response && error.response.status === 400) {
          alert("Cannot delete profile referenced by jenkins credentials...")
        } else {
          alert("Somethingwent wrong!")
        }
        setShowDeleteConfirmPopup(false)
      })

    await axios
      .get(`${baseUrl}/deployment/get`)
      .then((response) => {
        console.log("/deployment/get after delete: ", response.data)
        setShowDeleteConfirmPopup(false)
        dispatch(setDeploymentProfiles(response.data))
        setDeleteLoader(false)
      })
      .catch((error) => {
        console.error(error)
        alert("Somethingwent wrong!")
        setShowDeleteConfirmPopup(false)
        setDeleteLoader(false)
      })
  }

  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel'>
        <div className='heading-btn-container'>
          <h4>Deployment profile</h4>
          <button className='add-profile-btn' onClick={handleAddProfile}>
            <img className='plus-icon' src={plus} alt='plus' />
            Add profile
          </button>
        </div>

        <div className='profile-table-container'>
          <table className='profile-table'>
            <thead>
              <tr>
                <th>Profile</th>
                <th>Name</th>
                <th>VM IP</th>
                {/* <th>Job Name</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {deploymentProfiles.map((profile, index) => (
                <tr key={index}>
                  <td>{profile.ci_cd_type}</td>
                  <td>{profile.profile_name}</td>
                  <td>{profile.vm_ip}</td>
                  {/* <td>{profile.job_name}</td> */}
                  <td>
                    <div className='edit-delete-container'>
                      <div
                        className='edit-container'
                        onClick={() => onEditIconClick(profile)}
                      >
                        <img src={editIcon} alt='edit'></img>
                        <p>Edit</p>
                      </div>
                      <div
                        className='delete-container'
                        onClick={() => onDeleteIconClick(profile)}
                      >
                        <img src={deleteIcon} alt='delete'></img>
                        <p>Delete</p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showAddProfilePopup && (
        <AddProfilePopup onClose={() => setShowAddProfilePopup(false)} />
      )}

      {showEditProfilePopup && (
        <EditProfilePopup
          onClose={() => setShowEditProfilePopup(false)}
          clickedProfileNameForEdit={clickedProfileNameForEdit}
        />
      )}

      {showDeleteConfirmPopup && (
        <div className='add-delete-wrapper '>
          <div className='success-delete-data-wrapper'>
            <p>Are you sure want to delete this profile?</p>
            <div className='button-delete-container'>
              <button
                className='cancel-btn'
                onClick={() => setShowDeleteConfirmPopup(false)}
              >
                Cancel
              </button>
              <button className='confirm-btn' onClick={onConfirmDeleteProfile}>
                {deleteLoader ? (
                  <img className='plus-icon' src={loaderGif} alt='plus' />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Deployments
