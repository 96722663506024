import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import AllRepositories from "./AllRepo"
import Deployments from "./DeploymentProf"
import JobHistory from "./JobHistory"
import JenkinsCredentials from "./JenkinsCredentials"
import niveusLogo from "../../Images/niveusLogo.png"
import allRepoIcon from "../../Images/bulkMigration/allRepo.png"
import deploymentProfIcon from "../../Images/bulkMigration/deploymentProfile.png"
import jobHistory from "../../Images/bulkMigration/jobHistory.png"
import "./styles/bulkMigration.css"
import { checkUserToken } from "../../hooks/session"
import { useAuth } from "../../hooks/auth"

function BulkMigration() {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState("All Repositories")
  const { repositories, gitLogged } = location.state || {}

  // If gitLogged is undefined, you can set it to false as a fallback
  const isGitLogged = gitLogged || false
  console.log("isGitLogged", isGitLogged)
  const { cookies, setCookies, logout } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    if (!isGitLogged) {
      navigate("/bulk-migration/git-login")
    }
  }, [])

  return (
    <div className='bulk-migration-container'>
      <div className='left-panel'>
        <img className='niveus-logo' src={niveusLogo} alt='logo'></img>
        <ul>
          <li
            className={selectedTab === "All Repositories" ? "selected" : ""}
            onClick={() => setSelectedTab("All Repositories")}
          >
            <img className='li-icon' src={allRepoIcon} alt='allRepo'></img>
            All Repositories
          </li>
          <li
            className={selectedTab === "Deployments" ? "selected" : ""}
            onClick={() => setSelectedTab("Deployments")}
          >
            <img
              className='li-icon'
              src={deploymentProfIcon}
              alt='deploymentProfile'
            ></img>
            Deployments Profile
          </li>
          <li
            className={selectedTab === "JenkinsCredentials" ? "selected" : ""}
            onClick={() => setSelectedTab("JenkinsCredentials")}
          >
            <img
              className='li-icon'
              src={deploymentProfIcon}
              alt='jenkinscredentials'
            ></img>
            Jenkins Credentials
          </li>
          <li
            className={selectedTab === "Job History" ? "selected" : ""}
            onClick={() => setSelectedTab("Job History")}
          >
            <img className='li-icon' src={jobHistory} alt='jobHistory'></img>
            Job History
          </li>
        </ul>
      </div>
      <div className='right-panel'>
        {selectedTab === "All Repositories" && (
          <AllRepositories repositories={repositories} />
        )}
        {selectedTab === "Deployments" && <Deployments />}
        {selectedTab === "JenkinsCredentials" && <JenkinsCredentials />}
        {selectedTab === "Job History" && <JobHistory />}
      </div>
    </div>
  )
}

export default BulkMigration
