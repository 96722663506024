import React from "react"
import LeftPanel from "./LeftPanel"

function Settings() {
  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel'>
        <h4>Settings</h4>
      </div>
    </div>
  )
}

export default Settings
