import React, { useEffect, useRef, useState } from "react"
import LeftPanel from "./LeftPanel"
import repoIcon from "../../Images/bulkMigration/blackRepo.png"
import historyIcon from "../../Images/bulkMigration/history.png"
import reportIcon from "../../Images/bulkMigration/reportIcon.png"
import plusIcon from "../../Images/bulkMigration/Vectorplus.png"
import analyzeIcon from "../../Images/bulkMigration/analyzeIcon.png"
import loadingIcon from "../../Images/bulkMigration/GenLoadingAnimation.gif"
import analyzedIcon from "../../Images/bulkMigration/GenTick.png"
import deployIcon from "../../Images/bulkMigration/deploy.png"
import triggeredIcon from "../../Images/bulkMigration/triggeredIcon.png"
import disabledPlusIcon from "../../Images/bulkMigration/disabledPlusIcon.png"
import disabledDeployIcon from "../../Images/bulkMigration/disabledDeployIcon.png"
import "./styles/genArtifact.css"
import { useNavigate } from "react-router-dom"
import Select from "react-dropdown-select"
import { styled } from "@mui/material/styles"

import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { useAuth } from "../../hooks/auth"
import { checkUserToken } from "../../hooks/session"
import GitRepoGenerateArtifact from "./popup_screens/CreateArtifactPopup"
import ViewHistoryPopup from "./reports/ViewHistoryPopup"
import DeployPopup from "./popup_screens/DeployPopup"
import {
  setRepoAnalysisResponse,
  setCreateArtifactCICDBranch,
  deploymentCurrentStatus,
  setDependencyCheckResponse,
  clearDeploymentCurrentStatusRedux,
  clearDependencyCheckResponse,
  setJobHistory,
} from "../../redux/actions"
import { clearSelectedRepoRedux } from "../../redux/actions"
import AnalysisReportPopup from "./reports/AnalysisReportPopup"

function GenArtifact() {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedRepoRedux = useSelector((state) => state.selectedRepoRedux)
  const gitCredentials = useSelector((state) => state.gitCredentials)
  const repoAnalysisResponse = useSelector(
    (state) => state.repoAnalysisResponse,
  )
  const deploymentCurrentStatusRedux = useSelector(
    (state) => state.deploymentCurrentStatus,
  )

  const [selectedBranch, setSelectedBranch] = useState({})
  const [showArtifactPopup, setShowArtifactPopup] = useState(false)
  const [showDeployPopup, setShowDeployPopup] = useState(false)
  const [showViewHistory, setShowViewHistory] = useState(false)
  const [showAnalysisReport, setShowAnalysisReport] = useState(false)
  const [analyzingRepoId, setAnalyzingRepoId] = useState(null)
  const [selectedRepo, setSelectedRepo] = useState([])
  const [repoIdFromCreateArtifact, setRepoIdFromCreateArtifact] = useState(null)
  const [statusResponse, setStatusResponse] = useState({})
  const [reportResponse, setReportResponse] = useState({})
  const [repoDetailsForDeploy, setRepoDetailsForDeploy] = useState({})
  const [updateJobHistoryForApi, setUpdateJobHistoryForApi] = useState([])
  const deploymentCurrentStatusForApiRef = useRef([])

  useEffect(() => {
    setSelectedRepo(selectedRepoRedux)
  }, [selectedRepoRedux])

  useEffect(() => {
    setUpdateJobHistoryForApi(deploymentCurrentStatusRedux)
    deploymentCurrentStatusForApiRef.current = deploymentCurrentStatusRedux
  }, [deploymentCurrentStatusRedux])

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  // Filter branches based on selected repository
  const getBranchOptions = (repo) =>
    repo.branches.map((branch) => ({ value: branch, label: branch }))

  const handleAllRepoClick = () => {
    navigate("/bulk-migration/all-repo")
    dispatch(clearSelectedRepoRedux())
    dispatch(clearDeploymentCurrentStatusRedux())
    dispatch(clearDependencyCheckResponse())
  }

  const handleBranchSelect = (repoId, selected) => {
    const branch = selected[0]?.value
    console.log("selected branch: ", branch)

    setSelectedBranch((prevState) => ({
      ...prevState,
      [repoId]: branch,
    }))
  }

  // const handleBranchSelect = (repoId, selected) => {
  //   const branch = selected[0].value
  //   console.log("nramch:", branch)
  //   if (branch) {
  //     setSelectedBranch((prevState) => ({
  //       ...prevState,
  //       [repoId]: branch,
  //     }))
  //   } else {
  //     const repo = selectedRepo.find((repo) => repo.id === repoId)
  //     console.log("repo.selected_branch:", repo.selected_branch)
  //     if (repo) {
  //       setSelectedBranch((prevState) => ({
  //         ...prevState,
  //         [repoId]: repo.selected_branch,
  //       }))
  //     }
  //   }
  // }

  // useEffect(() => {
  //   handleBranchSelect()
  // }, [])

  // console.log("repoAnalysisResponse: ", repoAnalysisResponse)

  //  ANALYSE REPO API CALLS
  const repoAnalysis = (repo, project_id) => {
    console.log("project_id: ", project_id)
    let repoId = repo.id
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/bulk/repo_analysis`, {
          repo_name: repo.repo_name,
          user_email: cookies.email,
        })
        .then((response) => {
          console.log("/bulk/repo_analysis: ", response.data)
          resolve(response)
          dispatch(
            setRepoAnalysisResponse({
              id: repo.id,
              project_id: project_id,
              repo_name: response.data.repoName,
              repo_path: repo.repo_path,
              app_type: response.data.appType,
              has_dockerfile: response.data.hasDockerfile,
            }),
          )
        })
        .catch((error) => {
          console.log(error)
          reject(error) // Reject the promise with the error
          const updatedSelectedRepo = [...selectedRepo].map((repo) => {
            if (repo.id === repoId) {
              repo.analyze_status_btn = "initial"
              repo.analyze_repo = "failed"
              repo.overall_status = {
                status: "Repo Analysis Failed",
                class: "fail-4",
              }
              // console.log("repop.id: ", repo.id)
            }
            return repo
          })
          setSelectedRepo(updatedSelectedRepo)
        })

      axios
        .post(`${baseUrl}/bulk/dependency_check`, {
          repo_name: repo.repo_name,
          user_email: cookies.email,
        })
        .then((response) => {
          console.log("/bulk/dependency_check: ", response.data)
          resolve(response)
          dispatch(
            setDependencyCheckResponse({
              id: repo.id,
              project_id: project_id,
              repo_name: response.data.repoName,
              dependencies: {
                current: response.data.dependencies,
                latest: response.data.latestVersion,
              },
              // latestVersion: response.data.latestVersion,
              databaseProperties: response.data.databaseProperties,
              externalApi: response.data.externalApi,
            }),
          )
          const updatedSelectedRepo = [...selectedRepo].map((repo) => {
            if (repo.id === repoId) {
              repo.analyze_status_btn = "completed"
              repo.analyze_repo = "success"
              repo.overall_status = {
                status: "Repo Analysed",
                class: "success-3",
              }
            }
            return repo
          })
          setSelectedRepo(updatedSelectedRepo)
        })
        .catch((error) => {
          console.log(error)
          reject(error) // Reject the promise with the error
          const updatedSelectedRepo = [...selectedRepo].map((repo) => {
            if (repo.id === repoId) {
              repo.analyze_status_btn = "initial"
              repo.analyze_repo = "failed"
              repo.overall_status = {
                status: "Repo Analysis Failed",
                class: "fail-4",
              }
            }
            return repo
          })
          setSelectedRepo(updatedSelectedRepo)
        })
    })
  }

  const onAnalyzeRepo = (repo) => {
    setAnalyzingRepoId(repo.id)
    let repoId = repo.id
    if (!selectedBranch[repo.id]) {
      alert("Please select a branch before analyzing the repository.")
      console.log("No branch selected for repository:", repo.repo_name)
      return
    }

    const selectedBranchValue = selectedBranch[repo.id]
    console.log("Analyzing repository:", repo.repo_name)
    console.log("Selected branch for repository:", selectedBranchValue)
    let { username, accessToken, domain } = gitCredentials

    if (selectedBranchValue) {
      const updatedSelectedRepo = [...selectedRepo].map((repo) => {
        if (repo.id === repoId) {
          repo.analyze_status_btn = "progress"
          repo.selected_branch = selectedBranchValue
        }
        return repo
      })
      setSelectedRepo(updatedSelectedRepo)

      if (domain === "github.com") {
        axios
          .post(`${baseUrl}/bulk/clone_github_repo`, {
            username: username,
            access_token: accessToken,
            repo_name: repo.repo_path,
            user_id: cookies.userId,
            branch: selectedBranchValue,
            user_email: cookies.email,
            domain_name: domain,
          })
          .then((response) => {
            console.log(
              "/bulk/clone_github_repo",
              response.data.project_data_id,
            )
            repoAnalysis(repo, response.data.project_data_id)
              .then(() => {})
              .catch((error) => {
                console.log(error)
                // repo.analyze_status_btn = "initial"
                const updatedSelectedRepo = [...selectedRepo].map((repo) => {
                  if (repo.id === repoId) {
                    repo.analyze_status_btn = "initial"
                    repo.analyze_repo = "failed"
                    repo.overall_status = {
                      status: "Repo Analysis Failed",
                      class: "fail-4",
                    }
                  }
                  return repo
                })
                setSelectedRepo(updatedSelectedRepo)
              })
          })
          .catch((error) => {
            console.log(error)
            const updatedSelectedRepo = [...selectedRepo].map((repo) => {
              if (repo.id === repoId) {
                repo.analyze_status_btn = "initial"
                repo.analyze_repo = "failed"
                repo.overall_status = {
                  status: "Repo Analysis Failed",
                  class: "fail-4",
                }
              }
              return repo
            })
            setSelectedRepo(updatedSelectedRepo)
          })
      } else if (domain === "gitlab.com" || domain.startsWith("gitlab")) {
        axios
          .post(`${baseUrl}/bulk/clone_gitlab_repo`, {
            username: username,
            access_token: accessToken,
            repo_name: repo.repo_path,
            domain_name: domain.value,
            user_id: cookies.userId,
            branch: selectedBranchValue,
            user_email: cookies.email,
            domain_name: domain,
          })
          .then((response) => {
            repoAnalysis(repo, response.data.project_data_id)
              .then(() => {})
              .catch((error) => {
                console.log(error)
                const updatedSelectedRepo = [...selectedRepo].map((repo) => {
                  if (repo.id === repoId) {
                    repo.analyze_status_btn = "initial"
                    repo.analyze_repo = "failed"
                    repo.overall_status = {
                      status: "Repo Analysis Failed",
                      class: "fail-4",
                    }
                  }
                  return repo
                })
                setSelectedRepo(updatedSelectedRepo)
              })
          })
          .catch((error) => {
            console.log(error)
            const updatedSelectedRepo = [...selectedRepo].map((repo) => {
              if (repo.id === repoId) {
                repo.analyze_status_btn = "initial"
                repo.analyze_repo = "failed"
                repo.overall_status = {
                  status: "Repo Analysis Failed",
                  class: "fail-4",
                }
              }
              return repo
            })
            setSelectedRepo(updatedSelectedRepo)
          })
      }
    } else {
      alert("Please enter branch before creating the artifact")
    }

    // setShowArtifactPopup(true);
  }

  // CREATE ARTIFACT API CALLS
  const onCreateArtifact = (repo) => {
    console.log(repo)
    setRepoIdFromCreateArtifact(repo.id)
    setShowArtifactPopup(true)
  }

  //deploy api calls
  const handleSave = async ({
    data,
    deploymentOption,
    cloudService,
    sonarqubeRequired,
    repoId,
    selectedOption,
  }) => {
    let { username, accessToken, domain } = gitCredentials

    const selectedBranchValue = selectedBranch[repoId]
    console.log("data: ", data)

    dispatch(
      setCreateArtifactCICDBranch({
        id: repoId,
        cicd_branch: data.ci_cd_branch,
      }),
    )
    let repo_path
    let repo_name
    selectedRepo.forEach((repoData) => {
      if (repoData.id === repoId) {
        repo_path = repoData.repo_path
        repo_name = repoData.repo_name
      }
    })
    console.log("repo_path: ", repo_path)
    const updatedSelectedRepo = [...selectedRepo].map((repo) => {
      if (repo.id === repoId) {
        repo.create_artifact_status_btn = "progress"
      }
      return repo
    })
    setSelectedRepo(updatedSelectedRepo)

    try {
      await axios.post(`${baseUrl}/`, data)

      if (deploymentOption === "helm") {
        console.log("helm repo", data.helm_repo)
        console.log("accesstoken===>", accessToken)
        await axios.post(`${baseUrl}/bulk/clone_helm_repo`, {
          access_token: accessToken,
          repo_url: data.helm_repo,
          branch: data.helm_branch,
          user_email: cookies.email,
          username: username,
        })
        console.log("helm data cloned successfully")
      }

      let isAdc = selectedOption === "adc"
      await axios.get(
        `${baseUrl}/bulk/run-script?deployment=${deploymentOption}&cloud_service=${cloudService}&sonar_required=${sonarqubeRequired}&user=${cookies.email}&repo_name=${data.src_folder_name}&adc=${isAdc}`,
      )

      if (deploymentOption === "helm") {
        console.log("helm repo", data.helm_repo)
        await axios.post(`${baseUrl}/bulk/single_helm_push`, {
          data: {
            repo_url: data.helm_repo,
            branch: data.helm_branch,
            access_token: accessToken,
          },
          user_email: cookies.email,
          username: username,
        })
        console.log("helm data pushed successfully")
      }

      await axios.post(`${baseUrl}/bulk/single_git_push`, {
        data: { repo_name: repo_path, branch: data.ci_cd_branch },
        user_email: cookies.email,
        token: accessToken,
        username: username,
        domain_name: domain,
      })

      const updatedSelectedRepo = [...selectedRepo].map((repo) => {
        if (repo.id === repoId) {
          repo.create_artifact_status_btn = "completed"
          repo.generate_artifact = "success"
          repo.overall_status = {
            status: "Artifact Generated",
            class: "success-3",
          }
        }
        return repo
      })
      setSelectedRepo(updatedSelectedRepo)
      console.log("selectedRepo>>", selectedRepo)
      console.log("3 api calls finished")
    } catch (error) {
      console.error(error)
      const updatedSelectedRepo = [...selectedRepo].map((repo) => {
        if (repo.id === repoId) {
          repo.create_artifact_status_btn = "initial"
          repo.generate_artifact = "failed"
          repo.overall_status = {
            status: "Artifact Generation Failed",
            class: "fail-4",
          }
        }
        return repo
      })
      setSelectedRepo(updatedSelectedRepo)
    }
  }

  const onDeploy = (repo) => {
    console.log("onDeploy>>>>>>>>>>>>>>", repo)
    setRepoDetailsForDeploy(repo)
    setShowDeployPopup(true)
  }

  const handleDeploy = async ({
    job_name,
    repoId,
    profile_name,
    repository_path_name,
    deployment_branch,
  }) => {
    let { username, accessToken, domain } = gitCredentials

    let test = []

    console.log("repoId(GA): ", repoId)
    console.log("git_domain_name: ", domain)
    console.log("GA: ", profile_name, repository_path_name, deployment_branch)

    const updatedSelectedRepo = [...selectedRepo].map((repo) => {
      if (repo.id === repoId) {
        repo.deploy_status_btn = "progress"
      }
      return repo
    })
    setSelectedRepo(updatedSelectedRepo)

    try {
      const createJobResponse = await axios.post(
        `${baseUrl}/jenkins/createjob`,
        {
          job_name,
          profile_name,
          repository_path_name,
          git_domain_name: domain,
          deployment_branch,
        },
      )
      console.log("/jenkins/createjob: ", createJobResponse.data)

      const updatedSelectedRepo = [...selectedRepo].map((repo) => {
        if (repo.id === repoId) {
          repo.create_jenkins_job = "success"
          repo.overall_status = "Jenkins Job Created"
        }
        return repo
      })
      setSelectedRepo(updatedSelectedRepo)

      try {
        const buildJobResponse = await axios.get(
          `${baseUrl}/jenkins/buildjob?profile_name=${profile_name}&job_name=${job_name}`,
        )
        console.log("/jenkins/buildjob: ", buildJobResponse.data)

        const updatedSelectedRepo = [...selectedRepo].map((repo) => {
          if (repo.id === repoId) {
            repo.trigger_build = "success"
            // repo.deploy_status_btn = "completed"
            repo.deploy_status_btn = "triggered"
            repo.overall_status = {
              status: "Jenkins build triggered",
              class: "success-3",
            }
          }
          return repo
        })
        setSelectedRepo(updatedSelectedRepo)
      } catch (error) {
        console.error(error)
        const updatedSelectedRepo = [...selectedRepo].map((repo) => {
          if (repo.id === repoId) {
            repo.trigger_build = "failed"
            repo.deploy_status_btn = "initial"
            repo.overall_status = {
              status: "Build trigger Failed",
              class: "fail-4",
            }
          }
          return repo
        })
        setSelectedRepo(updatedSelectedRepo)
      }
    } catch (error) {
      console.error(error)
      const updatedSelectedRepo = [...selectedRepo].map((repo) => {
        if (repo.id === repoId) {
          repo.create_jenkins_job = "failed"
          repo.deploy_status_btn = "initial"
          repo.overall_status = {
            status: "Jenkins Job Creation Failed",
            class: "fail-4",
          }
        }
        return repo
      })
      setSelectedRepo(updatedSelectedRepo)
      alert("Invalid deployment profile!")
      // No return here, allowing the flow to continue to the next block
    }

    // DEPLOYMENT CHECK & JOB HISTORY UPDATE

    const updateJobHistoryStatus = async (project_id) => {
      console.log("test: ", test)
      let t = test
      let deployment_status
      let deployment_date_time
      let id
      t.forEach((repo) => {
        if (repo.id === repoId) {
          deployment_status = repo.deploymentStatus
          deployment_date_time = repo.deploymentDateTime
          id = repo.id
        }
      })
      // Check if deployment is complete or failed, if not, schedule the next check after 30 seconds
      if (deployment_status) {
        deployment_status = deployment_status.toLowerCase()
      }
      console.log(
        `deployment_status before condition  of ${id} `,
        deployment_status,
      )

      if (deployment_status === "complete" || deployment_status === "success") {
        const updatedSelectedRepo = [...selectedRepo].map((repo) => {
          if (repo.id === repoId) {
            repo.deployment = "success"
            repo.deploy_status_btn = "completed"
            repo.overall_status = {
              status: "Deployment successfull",
              class: "success-3",
            }
          }
          return repo
        })
        setSelectedRepo(updatedSelectedRepo)
      } else if (
        deployment_status === "failure" ||
        deployment_status === "aborted"
      ) {
        const updatedSelectedRepo = [...selectedRepo].map((repo) => {
          if (repo.id === repoId) {
            repo.deployment = "failed"
            repo.deploy_status_btn = "initial"
            repo.overall_status = {
              status: "Deployment Failed",
              class: "fail-4",
            }
          }
          return repo
        })
        setSelectedRepo(updatedSelectedRepo)
        // dispatch(clearJobHistoryRedux())
      } else if (deployment_status === "pending") {
        const updatedSelectedRepo = [...selectedRepo].map((repo) => {
          if (repo.id === repoId) {
            repo.deployment = "in-progress"
            // repo.deploy_status_btn = "initial" //triggered
            repo.overall_status = {
              status: "Deployment In-Progress",
              class: "pending-2",
            }
          }
          return repo
        })
        setSelectedRepo(updatedSelectedRepo)
      } else {
        const updatedSelectedRepo = [...selectedRepo].map((repo) => {
          if (repo.id === repoId) {
            repo.deployment = "in-progress"
            // repo.deploy_status_btn = "initial" //triggered
            repo.overall_status = {
              status: "Deployment In-Progress",
              class: "pending-2",
            }
          }
          return repo
        })
        setSelectedRepo(updatedSelectedRepo)
      }

      if (
        deployment_status === undefined ||
        !["complete", "success", "failure", "aborted"].includes(
          deployment_status,
        )
      ) {
        setTimeout(checkAndUpdateDeploymentStatus, 20 * 1000) // 30 seconds interval
      }

      try {
        //update Job History
        const updateJobHistoryResponse = await axios.post(
          `${baseUrl}/jenkins/update_job_history`,
          {
            profile_name,
            project_id: project_id,
            deployment_status: deployment_status,
            deployment_date_time: deployment_date_time,
          },
        )

        console.log("/jenkins/update_job_history: ", updateJobHistoryResponse)

        //get Job History
        const jobHistoryResponse = await axios.get(
          `${baseUrl}/jenkins/get_job_history`,
        )
        const jobHistory = jobHistoryResponse.data
        console.log("/jenkins/get_job_history", jobHistory)
        if (jobHistory) {
          dispatch(setJobHistory(jobHistory))
        } else {
          dispatch(setJobHistory([]))
        }
      } catch (error) {
        console.error(error)
      }
    }

    const checkAndUpdateDeploymentStatus = async () => {
      try {
        const getBuildInfoResponse = await axios.post(
          `${baseUrl}/jenkins/getBuildInfo`,
          {
            profile_name,
          },
        )

        getBuildInfoResponse.data.jobHistory.id = repoId
        const existingIndex = test.findIndex((repo) => repo.id === repoId)

        if (existingIndex !== -1) {
          // If an object with the same ID exists, replace it with the new one
          test[existingIndex] = getBuildInfoResponse.data.jobHistory
        } else {
          // If no object with the same ID exists, add the new object to the array
          test.push(getBuildInfoResponse.data.jobHistory)
        }

        dispatch(deploymentCurrentStatus(getBuildInfoResponse.data.jobHistory))

        let project_id
        const foundRepo = repoAnalysisResponse.find(
          (repo) => repo.id === repoId,
        )
        if (foundRepo) {
          project_id = foundRepo.project_id
        }

        updateJobHistoryStatus(project_id)
      } catch (error) {
        console.error(error)
      }
    }

    // Schedule the initial deployment status check after 30 seconds
    setTimeout(checkAndUpdateDeploymentStatus, 30 * 1000)
  }

  //VIEW HISTORY
  const onViewHistory = async (repo) => {
    console.log("repo: ", repo)
    setShowViewHistory(true)
    setStatusResponse(repo)
  }

  //ANALYSIS REPORT
  const onAnalysisReport = async (repo) => {
    setShowAnalysisReport(true)
    setReportResponse(repo)
  }

  //BUTTON CLASSNAMES
  const getAnalyseButtonProperties = (repo) => {
    if (repo.analyze_status_btn === "progress") {
      return {
        buttonText: "",
        buttonClassName: "analyzing-btn",
        buttonIcon: (
          <img className='loading-gif' src={loadingIcon} alt='analyzing' />
        ),
      }
    } else if (repo.analyze_status_btn === "completed") {
      return {
        buttonText: " Analysed",
        buttonClassName: "analyzed-btn",
        buttonIcon: <img src={analyzedIcon} alt='analyzed' />,
      }
    } else if (repo.analyze_status_btn === "initial") {
      return {
        buttonText: " Analyse",
        buttonClassName: "analyze-btn",
        buttonIcon: <img src={analyzeIcon} alt='analyze' />,
      }
    }
  }

  const getArtifactButtonProperties = (repo) => {
    if (repo.create_artifact_status_btn === "progress") {
      return {
        buttonText: "",
        buttonClassName: "creating-artifact-btn",
        buttonIcon: (
          <img className='loading-gif' src={loadingIcon} alt='creating' />
        ),
      }
    } else if (repo.create_artifact_status_btn === "completed") {
      return {
        buttonText: " Artifact Generated",
        buttonClassName: "created-artifact-btn",
        buttonIcon: <img src={analyzedIcon} alt='created' />,
      }
    } else if (repo.create_artifact_status_btn === "initial") {
      return {
        buttonText: " Deployment Inputs",
        buttonClassName:
          repo.analyze_status_btn !== "completed"
            ? "disabled-button-create-artifact"
            : "create-artifact-btn",
        buttonIcon:
          repo.analyze_status_btn !== "completed" ? (
            <img src={disabledPlusIcon} alt='disabled' />
          ) : (
            <img src={plusIcon} alt='analyze' />
          ),
      }
    }
  }

  const getDeployButtonProperties = (repo) => {
    if (repo.deploy_status_btn === "progress") {
      return {
        buttonText: "",
        buttonClassName: "deploying-btn",
        buttonIcon: (
          <img className='loading-gif' src={loadingIcon} alt='analyzing' />
        ),
      }
    } else if (repo.deploy_status_btn === "completed") {
      return {
        buttonText: " Deployed",
        buttonClassName: "deployed-btn",
        buttonIcon: <img src={analyzedIcon} alt='analyzed' />,
      }
    } else if (repo.deploy_status_btn === "triggered") {
      return {
        buttonText: " Build Triggered",
        buttonClassName: "triggered-deploy-btn",
        buttonIcon: <img src={triggeredIcon} alt='triggered' />,
      }
    } else if (repo.deploy_status_btn === "initial") {
      return {
        buttonText: " Deploy",
        buttonClassName:
          repo.analyze_status_btn !== "completed" ||
          repo.create_artifact_status_btn !== "completed"
            ? "disabled-button-deploy"
            : "bulk-deploy-btn",
        buttonIcon:
          repo.analyze_status_btn !== "completed" ||
          repo.create_artifact_status_btn !== "completed" ? (
            <img src={disabledDeployIcon} alt='disabled' />
          ) : (
            <img src={deployIcon} alt='analyze' />
          ),
      }
    }
  }

  // Truncate helper function
  const truncate = (str, length) => {
    return str.length > length ? str.substring(0, length) + "..." : str
  }

  const CustomListbox = styled("ul")({
    "& .MuiAutocomplete-option": {
      color: "black", // Change the font color of the options to black
    },
  })

  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel'>
        <div className='all-repo-bulk-gen-heading'>
          <h4 className='allrepo-heading'>
            <span className='all-repo-font' onClick={handleAllRepoClick}>
              All repositories
            </span>{" "}
            / selected repositories
          </h4>
        </div>

        <div className='gen-repo-scroll-container'>
          {selectedRepo.map((repo) => (
            <div className='gen-repo-container'>
              <div className='repo-name-container'>
                <div className='repo-name-sub-container'>
                  <p key={repo.id} className='repo-name'>
                    <img className='repo-icon' src={repoIcon} alt='icon'></img>
                    {repo.repo_name}
                  </p>
                  <p
                    className={`repo-status ${
                      // selectedRepoDetailsRedux.find(
                      //   (details) => details.git_repo_name === repo.repo_name,
                      // )?.status
                      repo.overall_status.class
                    }`}
                  >
                    {repo.overall_status.status}
                    {/* {
                      selectedRepoDetailsRedux.find(
                        (details) => details.git_repo_name === repo.repo_name,
                      ).status
                    } */}
                  </p>
                </div>

                <div className='report-history-sub-container'>
                  <p
                    className='analysis-report'
                    onClick={() => onAnalysisReport(repo)}
                  >
                    <img
                      className='repo-icon'
                      src={reportIcon}
                      alt='icon'
                    ></img>
                    Analysis Report
                  </p>
                  <p
                    className='view-history'
                    onClick={() => onViewHistory(repo)}
                  >
                    <img
                      className='repo-icon'
                      src={historyIcon}
                      alt='icon'
                    ></img>
                    View Status
                  </p>
                </div>
              </div>
              <div className='branch-button-container'>
                <Select
                  style={{
                    width: "320px",
                    height: "50px",
                    paddingLeft: "1rem",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                  }}
                  // value={selectedBranch}
                  // onChange={setSelectedBranch}
                  value={
                    selectedBranch[repo.id]
                      ? [
                          {
                            value: selectedBranch[repo.id],
                            label: selectedBranch[repo.id],
                          },
                        ]
                      : null
                    // repo.selected_branch
                  }
                  onChange={(selected) => handleBranchSelect(repo.id, selected)}
                  // options={getBranchOptions(repo)}
                  options={getBranchOptions(repo).map((option) => ({
                    ...option,
                    label: truncate(option.label, 30),
                  }))}
                  placeholder='Select branch name'
                  isSearchable
                  // title={getBranchOptions(repo)}
                  // isClearable
                />
                <div>
                  <button
                    className={` ${
                      getAnalyseButtonProperties(repo).buttonClassName
                    }`}
                    onClick={() => onAnalyzeRepo(repo)}
                    disabled={
                      repo.analyze_status_btn === "progress" ||
                      repo.analyze_status_btn === "completed"
                    }
                  >
                    {getAnalyseButtonProperties(repo).buttonIcon}
                    {getAnalyseButtonProperties(repo).buttonText}
                  </button>
                  <button
                    // className='create-artifact-btn'
                    className={` ${
                      getArtifactButtonProperties(repo).buttonClassName
                    }`}
                    // onClick={onCreateArtifact}
                    onClick={() => onCreateArtifact(repo)}
                    disabled={
                      repo.analyze_status_btn !== "completed" ||
                      repo.create_artifact_status_btn === "progress" ||
                      repo.create_artifact_status_btn === "completed"
                    }
                  >
                    {/* <img src={plusIcon} alt='plus'></img> */}
                    {getArtifactButtonProperties(repo).buttonIcon}
                    {getArtifactButtonProperties(repo).buttonText}
                  </button>
                  <button
                    // className='bulk-deploy-btn'
                    className={` ${
                      getDeployButtonProperties(repo).buttonClassName
                    }`}
                    onClick={() => onDeploy(repo)}
                    disabled={
                      repo.analyze_status_btn !== "completed" ||
                      repo.create_artifact_status_btn !== "completed" ||
                      repo.deploy_status_btn === "progress" ||
                      repo.deploy_status_btn === "triggered" ||
                      repo.deploy_status_btn === "completed"
                    }
                  >
                    {/* <img src={deployIcon} alt='deploy'></img> Deploy */}
                    {getDeployButtonProperties(repo).buttonIcon}
                    {getDeployButtonProperties(repo).buttonText}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showArtifactPopup && (
        <GitRepoGenerateArtifact
          // repoData={repoDetails}
          repoIdFromCreateArtifact={repoIdFromCreateArtifact}
          onClose={() => setShowArtifactPopup(false)}
          onSave={handleSave}
        />
      )}

      {showDeployPopup && (
        <DeployPopup
          repoDetailsForDeploy={repoDetailsForDeploy}
          onClose={() => setShowDeployPopup(false)}
          onSaveDeploy={handleDeploy}
        />
      )}

      {showViewHistory && (
        <ViewHistoryPopup
          repoData={statusResponse}
          onClose={() => setShowViewHistory(false)}
        />
      )}

      {showAnalysisReport && (
        <AnalysisReportPopup
          repoData={reportResponse}
          onClose={() => setShowAnalysisReport(false)}
        />
      )}
    </div>
  )
}

export default GenArtifact
