// import { createStore } from "redux"
// import rootReducer from "./reducers"

// const store = createStore(rootReducer)

// export default store

// store.js
import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

import rootReducer from "./reducers"

// Configuration for Redux Persist
const persistConfig = {
  key: "root",
  storage,
}

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create Redux store with persisted reducer
const store = createStore(persistedReducer)
const persistor = persistStore(store)

export { store, persistor }
