import React, { useEffect } from "react"
import axios from "axios"
import { useAuth } from "../../../hooks/auth"
import repoIcon from "../../../Images/bulkMigration/repoBlack.png"
import success from "../../../Images/bulkMigration/vh-success.png"
import pending from "../../../Images/bulkMigration/vh-pending.png"
import failed from "../../../Images/bulkMigration/vh-failed.png"
import inProgress from "../../../Images/bulkMigration/vh-inProgress.png"
import "../styles/viewHistoryPopup.css"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { checkUserToken } from "../../../hooks/session"

const ViewHistoryPopup = ({ repoData, onClose }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const repoAnalysisResponse = useSelector(
    (state) => state.repoAnalysisResponse,
  )

  console.log("repoData(VH)", repoData)
  console.log("repoAnalysisResponse(VH)", repoAnalysisResponse)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  let app_type
  let has_dockerfile
  repoAnalysisResponse.forEach((repo) => {
    if (repo.id === repoData.id) {
      app_type = repo.app_type
      has_dockerfile = repo.has_dockerfile
      // return; // Stop iteration after finding the matching repo
    }
  })

  console.log(app_type, has_dockerfile)
  // Function to determine class name based on status
  const getStatusClass = (status) => {
    switch (status) {
      case "success":
        return { className: "success", src: success }
      case "in-progress":
        return { className: "in-progress", src: inProgress }
      case "failed":
        return { className: "failed", src: failed }
      case "pending":
        return { className: "pending", src: pending }
      default:
        return { className: "", src: "" }
    }
  }
  return (
    <div className='view-history-wrapper'>
      <div className='view-history-data-wrapper'>
        <div className='vh-close-container'>
          <h5>
            <img src={repoIcon} alt='repo'></img> {repoData.repo_name}
          </h5>
          <button className='vh-close-btn' onClick={onClose}>
            &times;
          </button>
        </div>
        <div className='vh-content-container'>
          <div className='il-status-container'>
            <div className='icon-line-container'>
              <img
                className='vh-icon'
                src={getStatusClass(repoData.analyze_repo).src}
                alt=''
              ></img>
              <div className='vertical-line'></div>
            </div>
            <div className='status-container'>
              <div className='status-container-sub1'>
                <p className='staus-description'>Analyzing Repository</p>
                <p className={getStatusClass(repoData.analyze_repo).className}>
                  {repoData.analyze_repo}
                </p>
              </div>
              <p className='status-container-description'>
                {repoData.analyze_repo === "success" ? (
                  <>
                    &#8226; Successfully cloned repository...
                    <br />
                    &#8226; Repository analysis : (a)Application:{" "}
                    <b>{app_type}</b>
                    {"  "}
                    (b)Dockerfile: <b>{String(has_dockerfile)}</b>
                  </>
                ) : repoData.analyze_repo === "failed" ? (
                  <>
                    &#8226; Failed to clone repository...
                    <br />
                    &#8226; Repository analysis failed
                  </>
                ) : (
                  <>&#8226; Not Available</>
                )}
              </p>
            </div>
          </div>
          <div className='il-status-container'>
            <div className='icon-line-container'>
              <img
                className='vh-icon'
                src={getStatusClass(repoData.generate_artifact).src}
                alt=''
              ></img>
              <div className='vertical-line'></div>
            </div>
            <div className='status-container'>
              <div className='status-container-sub1'>
                <p className='staus-description'>Generating Artifact</p>
                <p
                  className={
                    getStatusClass(repoData.generate_artifact).className
                  }
                >
                  {repoData.generate_artifact}
                </p>
              </div>
              <p className='status-container-description'>
                {repoData.generate_artifact === "success" ? (
                  <>
                    &#8226; Artifacts edited successfully...
                    <br />
                    &#8226; Artifacts pushed to deployment branch in git...
                  </>
                ) : repoData.generate_artifact === "failed" ? (
                  <>
                    &#8226; Failed to edit artifacts...
                    <br />
                    &#8226; Failed to push deployment branch to git...
                  </>
                ) : (
                  <>&#8226; Not Available</>
                )}
              </p>
            </div>
          </div>
          <div className='il-status-container'>
            <div className='icon-line-container'>
              <img
                className='vh-icon'
                src={getStatusClass(repoData.create_jenkins_job).src}
                alt=''
              ></img>
              <div className='vertical-line'></div>
            </div>
            <div className='status-container'>
              <div className='status-container-sub1'>
                <p className='staus-description'>Creating Jenkins Job</p>
                <p
                  className={
                    getStatusClass(repoData.create_jenkins_job).className
                  }
                >
                  {repoData.create_jenkins_job}
                </p>
              </div>
              <p className='status-container-description'>
                {repoData.create_jenkins_job === "success" ? (
                  <>&#8226; Successfully created a job in jenkins</>
                ) : repoData.create_jenkins_job === "failed" ? (
                  <>&#8226; Jenkins job creation failed</>
                ) : (
                  <>&#8226; Not Available</>
                )}
              </p>
            </div>
          </div>
          <div className='il-status-container'>
            <div className='icon-line-container'>
              <img
                className='vh-icon'
                src={getStatusClass(repoData.trigger_build).src}
                alt=''
              ></img>
              <div className='vertical-line'></div>
            </div>
            <div className='status-container'>
              <div className='status-container-sub1'>
                <p className='staus-description'>Trigger build</p>
                <p className={getStatusClass(repoData.trigger_build).className}>
                  {repoData.trigger_build}
                </p>
              </div>
              <p className='status-container-description'>
                {repoData.trigger_build === "success" ? (
                  <>&#8226; Successfully triggered job build</>
                ) : repoData.trigger_build === "failed" ? (
                  <>&#8226; Job build trigger failed</>
                ) : (
                  <>&#8226; Not Available</>
                )}
              </p>
            </div>
          </div>
          <div className='il-status-container'>
            <div className='icon-line-container'>
              <img
                className='vh-icon'
                src={getStatusClass(repoData.deployment).src}
                alt=''
              ></img>
            </div>
            <div className='status-container'>
              <div className='status-container-sub1'>
                <p className='staus-description'>Deployment</p>
                <p className={getStatusClass(repoData.deployment).className}>
                  {repoData.deployment}
                </p>
              </div>
              <p className='status-container-description'>
                {repoData.deployment === "success" ? (
                  <>&#8226; Application deployment successful</>
                ) : repoData.deployment === "in-progress" ? (
                  <>&#8226; Application deployment in progress</>
                ) : repoData.deployment === "failed" ? (
                  <>&#8226; Application deployment failed</>
                ) : (
                  <>&#8226; Not Available</>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewHistoryPopup
