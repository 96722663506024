// import { jwtDecode } from "jwt-decode"

// export const checkUserToken = (cookies, logout) => {
//   const userToken = cookies.token
//   if (!userToken || userToken === "undefined") {
//     logout()
//     return false
//   }

//   try {
//     const decodedToken = jwtDecode(userToken)
//     const currentTime = Date.now() / 1000
//     if (decodedToken.exp < currentTime) {
//       logout()
//       return false
//     }
//     return true
//   } catch (error) {
//     console.error("Error decoding token:", error)
//     logout()
//     return false
//   }
// }
import { jwtDecode } from "jwt-decode"
import api from "../../services/api"

export const checkUserToken = async (cookies, setCookies, logout) => {
  const userToken = cookies.token
  const refreshToken = cookies.refresh_token
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"

  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  if (!userToken || userToken === "undefined") {
    logout()
    return false
  }

  try {
    const decodedToken = jwtDecode(userToken)
    const currentTime = Date.now() / 1000
    if (decodedToken.exp < currentTime) {
      // Access token is expired, use refresh token to obtain a new access token
      const res = await api.post(`${baseUrl}/refresh_token`, {
        refresh_token: refreshToken,
      })

      if (res.status === 200) {
        console.log("inside refresh token ")
        console.log("token is", res.data.token)
        // Update cookies with new access token
        setCookies("token", res.data.token)
        return true // New access token obtained successfully
      } else {
        console.log("inside else of refresh tokn")
        logout() // Logout if refresh token failed
        return false
      }
    } else {
      // Access token is still valid
      return true
    }
  } catch (error) {
    console.error("Error decoding token:", error)
    logout()
    return false
  }
}
