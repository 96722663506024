import React from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import niveusLogo from "../../Images/niveusLogo.png"
import allRepoIcon from "../../Images/bulkMigration/allRepo.png"
import deploymentProfIcon from "../../Images/bulkMigration/deploymentProfile.png"
import jobHistory from "../../Images/bulkMigration/jobHistory.png"
import settings from "../../Images/bulkMigration/settings.png"
import jenkins from "../../Images/bulkMigration/jenkinWhiteicon.png"
import logout from "../../Images/bulkMigration/gitLogout.png"
import { useSelector } from "react-redux"
import "./styles/bulkMigration.css"
import "./styles/leftPanel.css"

const LeftPanel = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const selectedRepoRedux = useSelector((state) => state.selectedRepoRedux)
  const dynamicPath = selectedRepoRedux
    ? "/bulk-migration/generate-artifact"
    : "/bulk-migration/all-repo"

  const isActive = (path1, path2) => {
    return location.pathname === path1 || location.pathname === path2
  }

  const handleLogoClick = () => {
    navigate("/bulk-migration/git-login")
  }

  return (
    <div className='left-panel'>
      <img
        className='niveus-logo'
        src={niveusLogo}
        alt='logo'
        onClick={handleLogoClick}
      />
      <NavLink
        className={`nav-link ${
          isActive(
            "/bulk-migration/all-repo",
            "/bulk-migration/generate-artifact",
          )
            ? "selected"
            : ""
        }`}
        to={dynamicPath}
      >
        <img className='li-icon' src={allRepoIcon} alt='allRepo' />
        All Repositories
      </NavLink>
      <NavLink
        className={`nav-link ${
          isActive("/bulk-migration/deployment-profile") ? "selected" : ""
        }`}
        to='/bulk-migration/deployment-profile'
      >
        <img
          className='li-icon'
          src={deploymentProfIcon}
          alt='deploymentProfile'
        />
        Deployment Profile
      </NavLink>
      <NavLink
        className={`nav-link ${
          isActive("/bulk-migration/jenkins-credentials") ? "selected" : ""
        }`}
        to='/bulk-migration/jenkins-credentials'
      >
        {/* <img className='li-icon jenkins-icon'></img> */}
        <img className='li-icon' src={jenkins} alt='jobHistory' />
        Jenkins Credentials
      </NavLink>

      <NavLink
        className={`nav-link ${
          isActive("/bulk-migration/argocd") ? "selected" : ""
        }`}
        to='/bulk-migration/argocd'
      >
        <img className='li-icon' src={settings} alt='icon' />
        ArgoCD Setup
      </NavLink>

      {/* <NavLink
        className={`nav-link ${
          isActive("/bulk-migration/settings") ? "selected" : ""
        }`}
        to='/bulk-migration/settings'
      >
        <img className='li-icon' src={settings} alt='jobHistory' />
        Settings
      </NavLink> */}
      <NavLink
        className={`nav-link ${
          isActive("/bulk-migration/job-history") ? "selected" : ""
        }`}
        to='/bulk-migration/job-history'
      >
        <img className='li-icon' src={jobHistory} alt='jobHistory' />
        Job History
      </NavLink>

      <div className='logout-seperator'></div>
      <NavLink className='nav-link' to='/bulk-migration/git-login'>
        <img className='li-icon' src={logout} alt='jobHistory' />
        Logout
      </NavLink>
    </div>
  )
}

export default LeftPanel
