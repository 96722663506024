export const setRepositories = (repositories) => ({
  type: "SET_REPOSITORIES",
  payload: repositories,
})

export const setGitLogged = (value) => ({
  type: "SET_GIT_LOGGED",
  payload: value,
})

export const selectedRepoRedux = (selectedRepo) => ({
  type: "SET_SELECTED_REPO",
  payload: selectedRepo,
})

export const setSelectedBranch = (selectedBranch) => ({
  type: "SET_SELECTED_BRANCH",
  payload: selectedBranch,
})

export const clearSelectedRepoRedux = () => ({
  type: "CLEAR_SELECTED_REPO",
  payload: null,
})

export const clearDeploymentCurrentStatusRedux = () => ({
  type: "CLEAR_DEPLOYMENT_CURRENT_STATUS",
  payload: [],
})

export const clearDependencyCheckResponse = () => ({
  type: "CLEAR_DEPENDENCY_CHECK_RESPONSE",
  payload: [],
})

export const clearJenkinsCredentials = () => ({
  type: "CLEAR_JENKINS_CREDENTIALS",
  payload: null,
})

export const clearConnectedRepo = () => ({
  type: "CLEAR_CONNECTED_REPO",
  payload: null,
})

export const clearArgoApp = () => ({
  type: "CLEAR_ARGO_APP",
  payload: null,
})

export const clearArgoLoginData = () => ({
  type: "CLEAR_ARGO_LOGIN_DATA",
  payload: null,
})

export const clearArgoLoggedIn = () => ({
  type: "CLEAR_ARGO_LOGIN",
  payload: null,
})

export const selectedRepoDetailsRedux = (selectedRepoDetails) => ({
  type: "SET_SELECTED_REPO_DETAILS",
  payload: selectedRepoDetails,
})

export const gitCredentials = (gitCredentials) => ({
  type: "SET_GIT_CREDENTIALS",
  payload: gitCredentials,
})

export const setDeploymentProfiles = (deploymentProfiles) => ({
  type: "SET_DEPLOYMENT_PROFILES",
  payload: deploymentProfiles,
})

export const setJenkinsCredentialsRedux = (jenkinsCredentials) => ({
  type: "SET_JENKINS_CREDENTIALS",
  payload: jenkinsCredentials,
})

export const setRepoAnalysisResponse = (repoAnalysisResponse) => ({
  type: "SET_REPO_ANALYSIS_RESPONSE",
  payload: repoAnalysisResponse,
})

export const setDependencyCheckResponse = (dependencyCheckResponse) => ({
  type: "SET_DEPENDENCY_CHECK_RESPONSE",
  payload: dependencyCheckResponse,
})

export const setCreateArtifactCICDBranch = (createArtifactCICDBranch) => ({
  type: "SET_CICD_BRANCH",
  payload: createArtifactCICDBranch,
})

export const setJobHistory = (jobHistory) => ({
  type: "SET_JOB_HISTORY",
  payload: jobHistory,
})

export const deploymentCurrentStatus = (deploymentCurrentStatus) => ({
  type: "DEPLOYMENT_CURRENT_STATUS",
  payload: deploymentCurrentStatus,
})

export const setArgoConnectedRepo = (connectedRepo) => ({
  type: "SET_CONNECTED_REPO",
  payload: connectedRepo,
})

export const setArgoApp = (argoApp) => ({
  type: "SET_ARGO_APP",
  payload: argoApp,
})

export const setArgocdLoginData = (loginData) => ({
  type: "SET_ARGOCD_LOGIN_DATA",
  payload: loginData,
})

export const setIsArgocdLoggedIn = (isLoggedIn) => ({
  type: "SET_ARGOCD_LOGGED_IN",
  payload: isLoggedIn,
})
