import React, { useState } from "react"
import Select from "react-select"
import "./styles/customSelect.css"

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#F7F7F7",
    border: "none",
    fontWeight: 200,
    padding: "0.4rem",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 200,
    }
  },
}

const CustomSelect = ({ options, placeholder, onSelectChange }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [customOption, setCustomOption] = useState("")

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    onSelectChange(selectedOption)
  }

  const handleInputChange = (newValue) => {
    setCustomOption(newValue)
  }

  const handleKeyDown = (event) => {
    if (
      !selectedOption &&
      event.key === "Enter" &&
      customOption.trim() !== ""
    ) {
      const newOption = {
        value: customOption.toLowerCase(),
        label: customOption,
      }
      handleChange(newOption)
      setCustomOption("")
      onSelectChange(newOption)
    }
  }

  return (
    <Select
      styles={colourStyles}
      value={selectedOption}
      onChange={handleChange}
      options={[...options, selectedOption].filter((option) => option)}
      placeholder={placeholder}
      isClearable
      isSearchable
      onKeyDown={handleKeyDown}
      onInputChange={handleInputChange}
    />
  )
}

export default CustomSelect
