// AllRepositories.js
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import loaderGif from "../../Images/loader.gif"
import sortIcon from "../../Images/bulkMigration/sort.png"
import searchIcon from "../../Images/bulkMigration/search.png"
import {
  selectedRepoRedux,
  selectedRepoDetailsRedux,
} from "../../redux/actions"
import "./styles/allRepo.css"
import "./styles/bulkMigration.css"
import LeftPanel from "./LeftPanel"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useAuth } from "../../hooks/auth"
import { checkUserToken } from "../../hooks/session"

function AllRepositories() {
  const dispatch = useDispatch()
  const repositories = useSelector((state) => state.repositories)

  const navigate = useNavigate()

  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const [selectedRepositories, setSelectedRepositories] = useState({})
  const [searchQuery, setSearchQuery] = useState("")
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const handleCheckboxChange = (id) => {
    setSelectedRepositories((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const handleGenerateArtifact = () => {
    setLoader(true)
    const selectedRepoDetails = repositories
      .filter((repo) => selectedRepositories[repo.id])
      .map((repo) => {
        repo.analyze_status_btn = "initial"
        repo.create_artifact_status_btn = "initial"
        repo.deploy_status_btn = "initial"
        repo.analyze_repo = "pending"
        repo.generate_artifact = "pending"
        repo.create_jenkins_job = "pending"
        repo.trigger_build = "pending"
        repo.deployment = "pending"
        repo.overall_status = {
          status: "Pending for Analysis",
          class: "init-1",
        }
        repo.selected_branch = []
        return repo
      })

    const selectedRepoNames = selectedRepoDetails.map((repo) => repo.repo_name)
    dispatch(selectedRepoRedux(selectedRepoDetails))
    axios
      .post(`${baseUrl}/bulk/get_repo_details`, {
        repositories: selectedRepoNames,
      })
      .then((response) => {
        setLoader(false)
        dispatch(selectedRepoDetailsRedux(response.data))
        navigate("/bulk-migration/generate-artifact")
      })
      .catch((error) => {
        console.log(error)
        setLoader(false)
      })
  }

  // Filter repositories based on search query
  const filteredRepositories = repositories.filter((repo) =>
    repo.repo_name.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  // console.log("filteredRepositories: ", filteredRepositories)

  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel'>
        <div className='all-repo-container'>
          <div className='sticky-heading-container'>
            <h4>All Repositories</h4>
            <div className='search-gen-container'>
              <div className='search-container'>
                <div className='search-icon'>
                  <img src={searchIcon} alt='search' />
                </div>
                <input
                  className='search-input'
                  type='text'
                  placeholder='Search Repositories'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button
                className='generate-artifact-btn'
                onClick={handleGenerateArtifact}
              >
                {loader ? (
                  <img className='loader-gif' src={loaderGif} alt='loading' />
                ) : (
                  "Next >> "
                )}
              </button>
            </div>
          </div>

          <div className='repo-container'>
            <div className='total-sort-container'>
              <p className='total-repo'>
                {filteredRepositories.length} Repositories
              </p>
              <p className='sort'>
                Sort <img src={sortIcon} alt='sort' />
              </p>
            </div>

            {filteredRepositories.map((repo) => (
              <div
                className={
                  "repo-content-container " +
                  (selectedRepositories[repo.id] ? "checked" : "")
                }
                key={repo.id}
                onClick={() => handleCheckboxChange(repo.id)}
              >
                <div className='repo-checkbox'>
                  <input
                    type='checkbox'
                    checked={selectedRepositories[repo.id] || false}
                    onChange={() => {}}
                    readOnly
                  />
                </div>
                <div>
                  <h5 className='repo-name'>{repo.repo_name}</h5>
                  <p className='repo-description'>{repo.repo_path}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllRepositories
