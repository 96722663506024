import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import niveusLogo from "../../Images/logo.jpg"
import LoadingGif from "../../Images/loader.gif"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../hooks/auth"
import axios from "axios"
import { checkUserToken } from "../../hooks/session"
import CustomSelect from "./CustomSelect"
import {
  setRepositories,
  setGitLogged,
  gitCredentials,
  setDeploymentProfiles,
  setJobHistory,
  clearDeploymentCurrentStatusRedux,
  clearDependencyCheckResponse,
  clearJenkinsCredentials,
  clearSelectedRepoRedux,
  setJenkinsCredentialsRedux,
  setArgoConnectedRepo,
  clearConnectedRepo,
  clearArgoApp,
  clearArgoLoginData,
  clearArgoLoggedIn,
} from "../../redux/actions"
import "./styles/gitLogin.css"

const BulkMigrationGitLogin = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const [username, setUsername] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [domain, setDomain] = useState({})
  const [loader, setLoader] = useState(false)
  const [loginDisabled, setLoginDisabled] = useState(true)

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    // Enable login button only if all fields are filled
    if (username && accessToken && domain.value) {
      setLoginDisabled(false)
    } else {
      setLoginDisabled(true)
    }
  }, [username, accessToken, domain])

  const options = [
    { value: "github.com", label: "github.com" },
    { value: "gitlab.com", label: "gitlab.com" },
    {
      value: "gitlab.niveussolutions.com",
      label: "gitlab.niveussolutions.com",
    },
  ]

  const handleLogoClick = () => {
    navigate("/dashboard")
  }

  const onUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const onAcessTokenChange = (e) => {
    setAccessToken(e.target.value)
  }

  const handleSelectChange = (selectedOption) => {
    setDomain(selectedOption)
  }

  const handleGitLogin = async () => {
    setLoader(true)
    dispatch(clearDeploymentCurrentStatusRedux())
    dispatch(clearDependencyCheckResponse())
    dispatch(clearJenkinsCredentials())
    dispatch(clearSelectedRepoRedux())
    dispatch(clearConnectedRepo())
    dispatch(clearArgoApp())
    dispatch(clearArgoLoginData())
    dispatch(clearArgoLoggedIn())

    try {
      let data = {
        git_username: username,
        token: accessToken,
        userId: cookies.userId,
        hostUrl: domain.value,
      }

      let response

      if (domain.value === "github.com") {
        response = await axios.post(`${baseUrl}/bulk/access_usr_github`, data)
      } else if (
        domain.value === "gitlab.com" ||
        domain.value.startsWith("gitlab")
      ) {
        response = await axios.post(`${baseUrl}/bulk/access_usr_gitlab`, data)
      }

      console.log(response.data)
      setLoader(false)

      dispatch(
        gitCredentials({
          username: username,
          accessToken: accessToken,
          domain: domain.value,
        }),
      )
      dispatch(setRepositories(response.data))
      dispatch(setGitLogged(true))
      navigate("/bulk-migration/all-repo")

      const deploymentResponse = await axios.get(`${baseUrl}/deployment/get`)
      const deploymentProfiles = deploymentResponse.data
      if (deploymentProfiles) {
        dispatch(setDeploymentProfiles(deploymentProfiles))
      } else {
        dispatch(setDeploymentProfiles([]))
      }

      const jenkinsCredResponse = await axios.get(
        `${baseUrl}/jenkins/get_credential`,
      )
      const jenkinsCredentials = jenkinsCredResponse.data
      if (jenkinsCredentials) {
        dispatch(setJenkinsCredentialsRedux(jenkinsCredentials))
      } else {
        dispatch(setJenkinsCredentialsRedux([]))
      }

      // Fetch job history from Jenkins
      const jobHistoryResponse = await axios.get(
        `${baseUrl}/jenkins/get_job_history`,
      )
      const jobHistory = jobHistoryResponse.data
      console.log("jobHistory>>>>", jobHistory)
      if (jobHistory) {
        dispatch(setJobHistory(jobHistory))
      } else {
        dispatch(setJobHistory([]))
      }
    } catch (error) {
      console.log(error)
      if (error.response.data.error === "error") {
        alert("Invalid Credentials!")
      } else {
        alert("Something Went wrong!")
      }
      setLoader(false)
    }
  }

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Enter" && !loginDisabled) {
        await handleGitLogin()
      }
    }

    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [loginDisabled])

  return (
    <div className='git-container'>
      <div className='background-container'></div>
      <img
        className='logo'
        src={niveusLogo}
        onClick={handleLogoClick}
        alt='logo'
      />
      <div>
        <div className='git-login-container'>
          <h4 className='git-login-heading'>Login to Git</h4>
          <label className='input-container git-domain-container'>
            Git Domain Name
            <CustomSelect
              options={options}
              placeholder='Select an option or type a new one'
              onSelectChange={handleSelectChange}
            />
          </label>

          <label className='input-container'>
            Git User Name
            <input
              type='text'
              placeholder='Enter Git user name'
              value={username}
              onChange={onUsernameChange}
            />
          </label>
          <label className='input-container'>
            Access Token
            <input
              type='password'
              placeholder='Enter Access Token'
              value={accessToken}
              onChange={onAcessTokenChange}
            />
          </label>

          <button
            className='git-login-btn'
            onClick={handleGitLogin}
            disabled={loginDisabled}
          >
            {loader ? (
              <img
                className='git-loading-gif'
                src={LoadingGif}
                alt='loading...'
              />
            ) : (
              "Login"
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BulkMigrationGitLogin
