import React, { useEffect, useState } from "react"
import LeftPanel from "./LeftPanel"
import searchIcon from "../../Images/bulkMigration/search.png"
import "./styles/jobHistory.css"
import axios from "axios"
import { useAuth } from "../../hooks/auth"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { checkUserToken } from "../../hooks/session"

function JobHistory() {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const jobHistory = useSelector((state) => state.jobHistory)
  const gitCredentials = useSelector((state) => state.gitCredentials)

  const [searchQuery, setSearchQuery] = useState("")
  const [loading, setLoading] = useState(false)

  // Function to determine class name based on status
  const getStatusClass = (status) => {
    let statusUpperCase = status.toUpperCase()
    switch (statusUpperCase) {
      case "SUCCESS":
        return "success"
      case "In Progress":
        return "in-progress"
      case "FAILURE":
        return "failure"
      case "ABORTED":
        return "failure"
      case "aborted":
        return "failure"
      case "COMPLETE":
        return "complete"
      case "PENDING":
        return "pending"
      default:
        return ""
    }
  }

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const filteredData = jobHistory
    ? jobHistory.filter((job) =>
        job.repository_name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : []

  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel'>
        <h4>Job History</h4>

        <div className='search-container-jh'>
          <div className='search-icon-jh'>
            <img src={searchIcon} alt='search' />
          </div>
          <input
            className='search-input-jh'
            type='text'
            placeholder='Search Repositories'
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>

        <div className='job-table-container'>
          <table className='job-table'>
            <thead>
              <tr>
                <th>Repo Name</th>
                <th>Application Type</th>
                <th>Job Name</th>
                <th>Deployment Status</th>
                <th>Last Deployment Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='7' className='loading-ring-container'>
                    <div className='loading-ring'></div>
                  </td>
                </tr>
              ) : (
                filteredData.map((job, index) => (
                  <tr key={index}>
                    <td>{job.repository_name}</td>
                    <td>{job.application_type}</td>
                    <td>{job.job_name}</td>
                    <td>
                      <span className={getStatusClass(job.deployment_status)}>
                        {job.deployment_status.toUpperCase()}
                      </span>
                    </td>
                    <td>{job.deployment_date_time}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default JobHistory
