import React, { useEffect, useState } from "react"
import axios from "axios"
import { useAuth } from "../../../hooks/auth"
import plus from "../../../Images/bulkMigration/Vectorplus.png"
import loaderGif from "../../../Images/loader.gif"
import success from "../../../Images/success.jpg"
import "../styles/addProfilePopup.css"
import { useDispatch } from "react-redux"
import { setDeploymentProfiles } from "../../../redux/actions"
import { checkUserToken } from "../../../hooks/session"
import { useNavigate } from "react-router-dom"

const EditProfilePopup = ({ onClose, clickedProfileNameForEdit }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { cookies, setCookies, logout } = useAuth()

  const [showProfileCreatedPopup, setShowProfileCreatedPopup] = useState(false)
  const [createProfileLoader, setCreateProfileLoader] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      } else {
        axios.defaults.headers.common = { Authorization: `${cookies.token}` }
      }
    }

    fetchData()
  }, [cookies, setCookies, logout, navigate])

  const [formData, setFormData] = useState({
    ci_cd_type: clickedProfileNameForEdit.ci_cd_type,
    profile_name: clickedProfileNameForEdit.profile_name,
    vm_ip: clickedProfileNameForEdit.vm_ip,
    username: clickedProfileNameForEdit.username,
    password: clickedProfileNameForEdit.password,
    // job_name: clickedProfileNameForEdit.job_name,
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleUpdateProfile = async () => {
    setCreateProfileLoader(true)
    try {
      let data = formData
      console.log("FormData:", data)

      const response = await axios.post(`${baseUrl}/deployment/update`, data)
      console.log("Deployment profile creation:", response.data)

      setCreateProfileLoader(false)
      setShowProfileCreatedPopup(true)

      const getResponse = await axios.get(`${baseUrl}/deployment/get`)
      console.log("Deployment profiles:", getResponse.data)
      dispatch(setDeploymentProfiles(getResponse.data))
    } catch (error) {
      console.error("Error:", error)
      if (error.response && error.response.status === 403) {
        alert(
          "Profile name already exists! Please enter a unique Profile Name.",
        )
      } else {
        alert("Unable to reach Jenkins URL!")
      }
      setCreateProfileLoader(false)
    }
  }

  const handleCancel = () => {
    // Reset form data and close the current popup
    setFormData({
      ci_cd_type: "",
      profile_name: "",
      jenkin_git_ssh_credential: "",
      vm_ip: "",
      username: "",
      password: "",
      // job_name: "",
    })
    onClose()
  }

  return (
    <div className='add-profile-wrapper'>
      {showProfileCreatedPopup ? (
        <div className='success-profile-data-wrapper'>
          <div className='ap-close-btn-container'>
            <button className='ap-close-btn' onClick={handleCancel}>
              &times;
            </button>
          </div>
          <img className='success-icon' src={success} alt='done'></img>
          <p>Profile Updated</p>
        </div>
      ) : (
        <div className='add-profile-data-wrapper'>
          <div className='ap-close-container'>
            <h4>Edit Deployment Profile</h4>
            <button className='ap-close-btn' onClick={onClose}>
              &times;
            </button>
          </div>

          <div className='ap-content-container'>
            <div>
              <label className='ap-box-label'>
                Pipeline
                <select
                  className='ap-input'
                  name='ci_cd_type'
                  value={formData.ci_cd_type}
                  onChange={handleChange}
                  disabled
                >
                  <option value=''>Select Pipeline</option>
                  <option value='jenkins'>Jenkins</option>
                  <option value='gitlab'>GitLab</option>
                </select>
              </label>
              <label className='ap-box-label'>
                Profile Name
                <input
                  className='ap-input'
                  type='text'
                  name='profile_name'
                  value={formData.profile_name}
                  onChange={handleChange}
                  placeholder='Enter Profile Name'
                  disabled
                />
              </label>
              <label className='ap-box-label'>
                VM IP
                <input
                  className='ap-input'
                  type='text'
                  name='vm_ip'
                  value={formData.vm_ip}
                  onChange={handleChange}
                  placeholder='Enter VM IP'
                />
              </label>
              <label className='ap-box-label'>
                Username
                <input
                  className='ap-input'
                  type='text'
                  name='username'
                  value={formData.username}
                  onChange={handleChange}
                  placeholder='Enter Jenkins username'
                  disabled
                />
              </label>
              <label className='ap-box-label'>
                Password
                <input
                  className='ap-input'
                  type='password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  placeholder='Enter password'
                  disabled
                />
              </label>
              {/* <label className="ap-box-label">
                Job Name
                <input
                  className="ap-input"
                  type="text"
                  name="job_name"
                  value={formData.job_name}
                  onChange={handleChange}
                  placeholder="Enter job name"
                />
              </label> */}
              <button
                className='create-profile-btn'
                onClick={handleUpdateProfile}
              >
                Update profile
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EditProfilePopup
