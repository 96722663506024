import { useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Bars, ColorRing } from "react-loader-spinner";

const Dropdown = ({
  options,
  label,
  id,
  placeholder,
  selectedVal,
  handleChange,
  loading,
  validate,
  dropdownup,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    setQuery(() => "");
    if (isOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, [isOpen]);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option[label]);
    setIsOpen(true);
  };

  function toggle(e) {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };
  return (
    <div className="dropdown" ref={inputRef}>
      <div className={`control ${validate ? "" : "disabled"}`}>
        <div className="selected-value">
          <input
            ref={searchRef}
            type="text"
            placeholder={placeholder}
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            disabled={!validate}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <div>
          <BsChevronDown />
        </div>
      </div>
      <div
        className={`options ${isOpen ? "open" : ""}`}
        style={dropdownup ? { top: "auto", bottom: "100%" } : {}}
      >
        {loading ? (
          <div className="option loading">loading...</div>
        ) : (
          filter(options).map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option)}
                className={`option ${
                  option[label] === selectedVal ? "selected" : ""
                }`}
                key={`${id}-${index}`}
              >
                {option[label]}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Dropdown;
