import React, { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate, useLocation } from "react-router-dom"
import "../styles/start.css"
import Loadinggif from "../Images/loader.gif"
import Vector from "../Images/vector.png"
import SuccessIcon from "../Images/success.jpg"
import { FaArrowLeft } from "react-icons/fa6"
import { useAuth } from "../hooks/auth"
import { Logo } from "./constants"
import { checkUserToken } from "../hooks/session"

const Deployment = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const [loading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState(null)
  const [json, setJsonData] = useState({})
  const [isChecked, setIsChecked] = useState(false)
  const [fieldTitles, setFieldTitles] = useState({})
  const [isFormFilled, setFormFilled] = useState(false)
  const [popup, setPopup] = useState(false)
  const [buildTriggered, setBuildTriggered] = useState(false)
  const [successPipeline, setSuccessPipeline] = useState(false)

  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("field_comments_jenkins.json")
      const jsonFieldTitle = await response.json()
      setFieldTitles(jsonFieldTitle)
    }
    async function fetchFieldData() {
      const response = await fetch("pipeline.json")
      const jsondata = await response.json()
      setJsonData(jsondata)
    }
    fetchData()
    fetchFieldData()
  }, [])

  const [data, setData] = useState({
    VM_IP: "",
    Github_url: location.state.data,
    Branch: "",
    Job_Name: "",
    Username: "",
    Password: "",
    user_email: location.state.user_email,
    Credentials_ID: json.Credentials_ID,
    Description: json.Description,
    Token: location.state.token,
    Gitlabrepo: location.state.data,
    GitLabID: "",
    Private_SSH_Key: "",
  })

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    const checkFormFilled = () => {
      for (const key in data) {
        if (data.hasOwnProperty(key) && key !== "Token" && data[key] === "") {
          return false
        }
      }
      return true
    }
    setFormFilled(checkFormFilled())
  }, [data])

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const handleSave = async () => {
    setLoading(true)
    await axios
      .post(`${baseUrl}/pipeline`, data)
      .then((response) => {
        console.log(response.status)
      })
      .catch((error) => {
        console.error(error)
      })

    await axios
      .get(`${baseUrl}/create-pipeline?user=${data.user_email}`)
      .then((response) => {
        response.status === 200
          ? setResponseData(response.data)
          : setResponseData(null)
        setLoading(false)
        setPopup(true)
        setSuccessPipeline(true)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setPopup(true)
        setSuccessPipeline(false)
      })
  }

  const handleClose = () => {
    setPopup(false)
  }

  const triggerBuild = () => {
    axios
      .post(`${baseUrl}/trigger?user=${data.user_email}`, {
        url: `http://${data.VM_IP}:8080`,
        username: data.Username,
        password: data.Password,
        job: data.Job_Name,
      })
      .then((response) =>
        response.status === 200
          ? setBuildTriggered(true)
          : setBuildTriggered(false),
      )
  }

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked)
    if (!isChecked) {
      setData({
        VM_IP: json.VM_IP,
        Github_url: location.state.data,
        User: json.User,
        Branch: json.Branch,
        Job_Name: json.Job_Name,
        Username: json.Username,
        Password: json.Password,
        Credentials_ID: json.Credentials_ID,
        Description: json.Description,
        user_email: location.state.user_email,
        Token: location.state.token,
        Gitlabrepo: location.state.data,
        GitLabID: json.GitLabID,
        Private_SSH_Key: json.Private_SSH_Key,
      })
    } else {
      setData({
        VM_IP: "",
        Github_url: "",
        User: "",
        Branch: "",
        Job_Name: "",
        Username: "",
        Password: "",
        Credentials_ID: "",
        Description: "",
        user_email: location.state.user_email,
        Token: "",
        Gitlabrepo: "",
        GitLabID: "",
        Private_SSH_Key: "",
      })
    }
  }

  const handleLogoClick = async () => {
    try {
      navigate("/dashboard")
      const response = await axios.get(`${baseUrl}/clear_project_directory`)
      console.log(response)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const handleBackwardClick = async () => {
    const jsonData = {
      application: location.state.application,
      repo: location.state.data,
      token: location.state.token,
      branch: location.state.branch,
      selectedOption: location.state.repo_type,
      repoHasDocker: location.state.repoHasDocker,
      repoName: location.state.repoName,
      user_email: location.state.user_email,
    }
    navigate("/migrate", {
      state: jsonData,
    })
  }

  return (
    <div className='migrate-wrapper'>
      <div className='Navbar'>
        <div className='header-container'>
          <div className='logo-container'>
            <img src={Logo} onClick={handleLogoClick} />
          </div>
          <div className='nav-container'>
            <button className='nav-forward' onClick={handleBackwardClick}>
              <FaArrowLeft />
            </button>
          </div>
        </div>
      </div>
      <div className='migrate-data-wrapper'>
        <div className='migrate-options'>
          <div>
            <label>
              <input
                className='assess-input'
                checked={isChecked}
                onChange={handleCheckboxChange}
                type='checkbox'
              />{" "}
              Show sample inputs
            </label>
          </div>
        </div>
        <div className='details-wrapper'>
          <div className='detail-box'>
            <div className='detail-box-header'>
              <p>Deployment details</p>
            </div>
            <label className='detail-box-label'>
              {" "}
              VM ip address
              <input
                className='migrate-input-assess'
                type='text'
                name='VM_IP'
                value={data.VM_IP}
                title={fieldTitles.VM_IP_title}
                onChange={handleChange}
                placeholder='external ip of VM'
              />
            </label>
            <label className='detail-box-label'>
              {" "}
              VM User
              <input
                className='migrate-input-assess'
                type='text'
                name='User'
                value={data.User}
                title={fieldTitles.User_title}
                onChange={handleChange}
                placeholder='VM user'
              />
            </label>
            <label className='detail-box-label'>
              {" "}
              Branch name{" "}
              <input
                className='migrate-input-assess'
                type='text'
                name='Branch'
                value={data.Branch}
                title={fieldTitles.Branch_title}
                onChange={handleChange}
                placeholder='Git branch name'
              />
            </label>
            <label className='detail-box-label'>
              {" "}
              Jenkins job name{" "}
              <input
                className='migrate-input-assess'
                type='text'
                name='Job_Name'
                value={data.Job_Name}
                title={fieldTitles.Job_Name_title}
                onChange={handleChange}
                placeholder='Jenkins job'
              />
            </label>
            <label className='detail-box-label'>
              {" "}
              Jenkins user name{" "}
              <input
                className='migrate-input-assess'
                type='text'
                name='Username'
                value={data.Username}
                title={fieldTitles.Username_title}
                onChange={handleChange}
                placeholder='Jenkins username'
              />
            </label>
            <label className='detail-box-label'>
              {" "}
              Jenkins password{" "}
              <input
                className='migrate-input-assess'
                type='password'
                name='Password'
                value={data.Password}
                title={fieldTitles.Password_title}
                onChange={handleChange}
                placeholder='Jenkins Password'
              />
            </label>
            {location.state.repo_type === "private" && (
              <label className='detail-box-label'>
                Credentials ID:
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='Credentials_ID'
                  value={data.Credentials_ID}
                  onChange={handleChange}
                  placeholder='Credential ID'
                  title={fieldTitles.Credentials_ID_title}
                ></input>
              </label>
            )}
            {location.state.repo_type === "public/ssh" && (
              <label className='detail-box-label'>
                Git ID:
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='GitLabID'
                  value={data.GitLabID}
                  onChange={handleChange}
                  placeholder='Git ID'
                  title={fieldTitles.GitLabID_title}
                ></input>
              </label>
            )}

            {/* <label className="detail-box-label">
              Private Key of jenkins:
              <input
                className="migrate-input-assess"
                type="password"
                name="Private_SSH_Key"
                value={data.Private_SSH_Key}
                onChange={handleChange}
                placeholder="Private key of jenkins vm"
                title={fieldTitles.Private_SSH_Key_title}
              ></input>
            </label> */}
            <button
              className='assess-button'
              disabled={!isFormFilled}
              onClick={handleSave}
              style={{
                ...(!isFormFilled ? { backgroundColor: "#9F9F9F" } : {}),
              }}
            >
              {loading ? (
                <img className='loading-gif' src={Loadinggif} />
              ) : (
                "Create pipeline"
              )}{" "}
            </button>
          </div>
        </div>
      </div>
      {popup && (
        <div className='migrate-pop-wrapper'>
          <div className='migrate-pop ' id='migrate-pop-height'>
            <div className='migrate-pop-img'>
              <img src={Vector} onClick={handleClose} />
            </div>
            {!buildTriggered ? (
              <div className='pop-wrapper-start'>
                <div>
                  {" "}
                  <img className='successIcon' src={SuccessIcon} />{" "}
                </div>
                <p className='app-migrated'>Pipeline is created</p>
                <button
                  className='assess-button '
                  // style={loading ? { backgroundColor: "#EA4535" } : {}}
                  onClick={triggerBuild}
                >
                  Trigger
                </button>
              </div>
            ) : (
              <div className='pop-wrapper-start'>
                <div>
                  {" "}
                  <img className='successIcon' src={SuccessIcon} />{" "}
                </div>
                <p className='app-migrated'>
                  First build triggered check jenkins
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Deployment
