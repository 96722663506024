import { useEffect, useState } from "react"
import backgroundimage from "../Images/background-image.jpg"
import ellipse from "../Images/Ellipse.png"
import { useNavigate } from "react-router-dom"
import { Logo } from "./constants"
import LoadingGif from "../Images/loader.gif"
import "../styles/gitrepo.css"
import { useAuth } from "../hooks/auth"
import { checkUserToken } from "../hooks/session"
import axios from "axios"
import GitRepoGenerateArtifact from "./BulkArtifactGen"
import Select from "react-dropdown-select"
import CustomSelect from "./bulkMigration/CustomSelect"
import success from "../Images/success.jpg"
import RepoSelectPopup from "./helpers/RepoSelectPopup"
import SuccessIcon from "../Images/success.jpg"
import Vector from "../Images/vector.png"
import { SlRefresh } from "react-icons/sl"

const statusClassMapping = {
  new: "status-new",
  pending: "status-pending",
  completed: "status-completed",
}

const GitRepo = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [loaderGitPush, setLoaderGitPush] = useState(false)
  const [isLoginButtonDisabled, setLoginButtonDisabled] = useState(true)
  const [isPushToGitButtonDisabled, setPushToGitButtonDisabled] = useState(true)
  const [username, setUsername] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [branch, setBranch] = useState("")
  const [domain, setDomain] = useState({})

  const [repositories, setRepositories] = useState([])
  const [loggedIn, setLoggedIn] = useState(false)
  const [selectedRepo, setSelectedRepo] = useState("")
  const [showArtifactPopup, setShowArtifactPopup] = useState(false)
  const [showRepoSelect, setShowRepoSelect] = useState(false)
  const [popupLoading, setPopupLoading] = useState(false)
  const [repoAnalysisLoader, setRepoAnalysisLoader] = useState(false)
  const [updatingLoader, setUpdatingLoader] = useState(false)

  const [selectedReposForGit, setSelectedReposForGit] = useState([])
  const [selectedRepoDetails, setSelectedRepoDetails] = useState([])
  const [dataArrayForBulkGitPush, setDataArrayForBulkGitPush] = useState([])

  const [pushGitSuccessPopoup, setPushGitSuccessPopoup] = useState(false)

  const [updateStatus, setUpdateStatus] = useState("")
  const [isRotating, setIsRotating] = useState(false)

  const [repoDetails, setRepoDetails] = useState({
    repo_name: "",
    app_type: "",
    has_dockerfile: false,
  })

  const handleLogoClick = () => {
    navigate("/dashboard")
  }

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const onUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const onAcessTokenChange = (e) => {
    setAccessToken(e.target.value)
  }

  const onAcessBranchChange = (event, gitRepoName) => {
    const { value } = event.target
    setBranch((prevState) => ({
      ...prevState,
      [gitRepoName]: value,
    }))
  }

  const toggleRepoSelection = (repo) => {
    const index = selectedReposForGit.indexOf(repo)
    if (index === -1) {
      setSelectedReposForGit([...selectedReposForGit, repo])
      setPushToGitButtonDisabled(false)
    } else {
      setSelectedReposForGit(selectedReposForGit.filter((r) => r !== repo))
      setPushToGitButtonDisabled(true)
    }
  }

  const toggleAllRepos = () => {
    if (selectedReposForGit.length === selectedRepo.length) {
      setSelectedReposForGit([])
      setPushToGitButtonDisabled(true)
    } else {
      setSelectedReposForGit([...selectedRepo])
      setPushToGitButtonDisabled(false)
    }
  }

  //Select repos from popup

  const refreshStatus = async () => {
    setIsRotating(true)
    setUpdatingLoader(true)
    const repoNamesArray = selectedRepoDetails.map(
      (repoDetail) => repoDetail.git_repo_name,
    )
    console.log(repoNamesArray)

    axios
      .post(`${baseUrl}/bulk/get_repo_details`, {
        repositories: repoNamesArray,
      })
      .then((response) => {
        console.log("=======response here")
        console.log(response.data)
        setSelectedRepoDetails(response.data)
        // setPopupLoading(false);
        // setShowArtifactPopup(true);
        // onSubmit(selectedRepos, response.data);
        // onClose();
        // setSubmitLoader(false);
        setIsRotating(false)
        setUpdatingLoader(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const pushToGit = async () => {
    setLoaderGitPush(true)
    console.log(selectedReposForGit)
    let dataToGetBranch = {
      src_folder_names: selectedReposForGit,
      user_email: cookies.email,
    }

    try {
      const response = await axios.post(
        `${baseUrl}/bulk/get_deployment_branch_names`,
        dataToGetBranch,
      )
      console.log(response.data)
      const mappedData = response.data.map((item) => {
        return {
          repo_name: item.src_folder_name,
          branch: item.ci_cd_branch,
        }
      })
      console.log(mappedData)
      setDataArrayForBulkGitPush(mappedData)
      console.log("dataArrayForBulkGitPush: ", dataArrayForBulkGitPush)

      let data = {
        dataArray: mappedData, // Use mappedData here instead of dataArrayForBulkGitPush
        user_email: cookies.email,
        token: accessToken,
        username: username,
        domain_name: domain.value,
      }
      console.log(data)

      await axios.post(`${baseUrl}/bulk/bulk_git_push`, data)
      console.log(response.data)

      await axios.post(`${baseUrl}/bulk/check_update_status_in_db`, {
        repo_names: selectedReposForGit,
        user_id: cookies.userId,
        manifestGen: true,
        status: "completed",
      })
      setUpdateStatus("completed")
      setPushGitSuccessPopoup(true)
      setLoaderGitPush(false)
      setSelectedReposForGit([])
      setPushToGitButtonDisabled(true)
    } catch (error) {
      console.log(error)
      alert("Something went wrong!")
      setLoaderGitPush(false)
      // Handle errors
    }
  }

  const handleSelectChange = (selectedOption) => {
    setDomain(selectedOption)
    console.log("app: ", selectedOption)
  }

  console.log(domain.value)

  useEffect(() => {
    console.log(username, accessToken, domain)
    if (username && accessToken && domain) {
      setLoginButtonDisabled(false)
    } else {
      setLoginButtonDisabled(true)
    }
  }, [username, accessToken, domain])

  const handleLogin = () => {
    setLoader(true)

    if (domain.value === "github.com") {
      let data = {
        git_username: username,
        token: accessToken,
        userId: cookies.userId,
        hostUrl: domain.value,
      }
      console.log(data)
      axios
        .post(`${baseUrl}/bulk/access_usr_github`, data)
        .then((response) => {
          console.log(response.data)
          setRepositories(response.data)
          setLoggedIn(true)
          setLoader(false)
          setLoginButtonDisabled(true)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.data.error === "error") {
            alert("Invalid Credentials!")
          } else {
            alert("Something Went wrong!")
          }
          setLoader(false)
        })
    } else if (
      domain.value === "gitlab.com" ||
      domain.value.startsWith("gitlab")
    ) {
      console.log("Inside Gitlab")
      let hostUrl = domain.value
      let data = { hostUrl, token: accessToken }
      console.log(data)

      axios
        .post(`${baseUrl}/bulk/access_usr_gitlab`, data)
        .then((response) => {
          console.log(response.data)
          setRepositories(response.data)
          setLoggedIn(true)
          setLoader(false)
          setLoginButtonDisabled(true)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.data.error === "error") {
            alert("Invalid Credentials!")
          } else {
            alert("Something Went wrong!")
          }
          setLoader(false)
        })
    }
  }

  const deleteRepo = (index) => {
    const updatedRepoList = [...selectedRepo]
    updatedRepoList.splice(index, 1)
    setSelectedRepo(updatedRepoList)
  }

  console.log(cookies.userId)
  console.log(branch)

  // Function to get the value associated with any key
  const getBranchValue = (key) => {
    return branch[key]
  }

  const repoAnalysis = (repo) => {
    setRepoAnalysisLoader(true)
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/bulk/repo_analysis`, {
          repo_name: repo,
          user_email: cookies.email,
        })
        .then((response) => {
          console.log("Repo Analysis: ", response)
          resolve(response) // Resolve the promise with the response
          setRepoDetails({
            repo_name: response.data.repoName,
            app_type: response.data.appType,
            has_dockerfile: response.data.hasDockerfile,
          })
          setRepoAnalysisLoader(false)
        })
        .catch((error) => {
          console.log(error)
          reject(error) // Reject the promise with the error
          setRepoAnalysisLoader(false)
        })
    })
  }

  console.log(repoDetails)

  const generateArtifact = (repo) => {
    const repoKey = repo
    const branchValue = getBranchValue(repoKey)
    if (branchValue) {
      setPopupLoading(true)
      if (domain.value === "github.com") {
        axios
          .post(`${baseUrl}/bulk/clone_github_repo`, {
            git_user: username,
            access_token: accessToken,
            repo_name: repo,
            user_id: cookies.userId,
            branch: branchValue,
            user_email: cookies.email,
          })
          .then((response) => {
            console.log("=======response here", response)
            setPopupLoading(false)
            repoAnalysis(repo)
              .then(() => {
                setShowArtifactPopup(true)
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
            setPopupLoading(false)
          })
      } else if (
        domain.value === "gitlab.com" ||
        domain.value.startsWith("gitlab")
      ) {
        axios
          .post(`${baseUrl}/bulk/clone_gitlab_repo`, {
            git_user: username,
            access_token: accessToken,
            repo_name: repo,
            domain_name: domain.value,
            user_id: cookies.userId,
            branch: branchValue,
            user_email: cookies.email,
          })
          .then((response) => {
            console.log("=======response here", response)
            setPopupLoading(false)
            // setShowArtifactPopup(true);
            repoAnalysis(repo)
              .then(() => {
                setShowArtifactPopup(true)
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      alert("Please enter branch before creating the artifact")
    }

    // setShowArtifactPopup(true);
  }

  const listRepo = () => {
    setShowRepoSelect(true)
  }

  const handleSelectedRepos = (selectedRepos, selectedReposDetails) => {
    setSelectedRepo(selectedRepos)
    setSelectedRepoDetails(selectedReposDetails)
    console.log("app: ", selectedReposDetails)
  }

  const options = [
    { value: "github.com", label: "github.com" },
    { value: "gitlab.com", label: "gitlab.com" },
    {
      value: "gitlab.niveussolutions.com",
      label: "gitlab.niveussolutions.com",
    },
  ]

  const handleClose = () => {
    setPushGitSuccessPopoup(false)
    // setMigrationDone(false);
  }

  const onRefreshStatus = () => {
    setPushGitSuccessPopoup(false)
    refreshStatus()
  }

  // useEffect(() => {
  //   // Refresh status when repoData updates
  //   setUpdatingLoader(true);
  //   refreshStatus();
  //   setUpdatingLoader(false);
  // }, [repoDetails, updateStatus]);

  return (
    <div className='home-wrapper'>
      {/* <div className="home-img">
        <img src={backgroundimage} alt="backgroundimage" />
      </div> */}
      <div className='git-home-data'>
        <img src={Logo} onClick={handleLogoClick} alt='logo' />
        <p className='git-details-heading'>Sign into your account</p>

        <label className='git-select-label'>
          Git Domain Name
          {/* <input
            type="text"
            placeholder="Enter Git user name"
            // value={username}
            // onChange={onUsernameChange}
          /> */}
          <CustomSelect
            options={options}
            placeholder='Select an option or type a new one'
            onSelectChange={handleSelectChange}
          />
        </label>

        <div className='git-wrapper'>
          <div className='user-token-container'>
            <label className='git-input git-user-input'>
              Git User Name
              <input
                type='text'
                placeholder='Enter Git user name'
                value={username}
                onChange={onUsernameChange}
              />
            </label>
            <label className='git-input'>
              Access Token
              <input
                type='password'
                placeholder='Enter Access Token'
                value={accessToken}
                onChange={onAcessTokenChange}
              />
            </label>
            {/* <button
              className="git-login-button"
              disabled={isLoginButtonDisabled}
              style={
                isLoginButtonDisabled ? { backgroundColor: "#9F9F9F" } : {}
              }
              onClick={handleLogin}
            >
              {loader ? (
                <img
                  className="loading-gif"
                  src={LoadingGif}
                  alt="loading..."
                />
              ) : (
                ">"
              )}
            </button> */}
            {loggedIn && (
              <img className='verify-img' src={success} alt=''></img>
            )}
          </div>
        </div>

        {!loggedIn && (
          <button
            className='git-button'
            disabled={isLoginButtonDisabled}
            style={isLoginButtonDisabled ? { backgroundColor: "#9F9F9F" } : {}}
            onClick={handleLogin}
          >
            {loader ? (
              <img className='loading-gif' src={LoadingGif} alt='loading...' />
            ) : (
              "Login"
            )}
          </button>
        )}

        {loggedIn && (
          <div className='git-wrapper'>
            <label className='git-input'>
              Repositories
              <button className='select-repo-btn' onClick={listRepo}>
                Select Repositories
              </button>
            </label>
          </div>
        )}

        {/* Display selected repos below the dropdown only if repositories are selected */}
        {selectedRepo.length > 0 && (
          <>
            <div className='selected-repo-box'>
              <table className='git-table'>
                <thead className='git-table-head'>
                  <tr>
                    <th>
                      {/* <input
                        type="checkbox"
                        checked={
                          selectedReposForGit.length === selectedRepo.length
                        }
                        onChange={toggleAllRepos}
                      /> */}
                      Select
                    </th>
                    <th>Repository Name</th>
                    <th>Branch</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Deploy</th>
                    <th>
                      <button className='refresh-btn' onClick={refreshStatus}>
                        <SlRefresh className={isRotating ? "rotate" : ""} />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className='git-table-body'>
                  {selectedRepoDetails.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type='checkbox'
                          checked={selectedReposForGit.includes(
                            data.git_repo_name,
                          )}
                          disabled={data.status !== "pending"}
                          onChange={() =>
                            toggleRepoSelection(data.git_repo_name)
                          }
                        />
                      </td>
                      <td>{data.git_repo_name}</td>
                      <td>
                        {" "}
                        <input
                          type='text'
                          placeholder='Enter branch...'
                          value={branch[data.git_repo_name] || ""}
                          disabled={data.status === "pending"}
                          onChange={(e) =>
                            onAcessBranchChange(e, data.git_repo_name)
                          }
                        />
                      </td>
                      <td className={statusClassMapping[data.status]}>
                        {updatingLoader ? "updating.." : data.status}
                        {/* {data.status} */}
                        {/* {updateStatus} */}
                      </td>
                      <td>
                        {" "}
                        <button
                          // className="generate-artifact-btn"
                          className={`generate-artifact-btn ${
                            data.status === "pending" ? "disabled" : ""
                          }`}
                          disabled={data.status === "pending"}
                          onClick={() => generateArtifact(data.git_repo_name)}
                        >
                          {/* <img className="ellipse" src={ellipse} alt="" /> */}
                          Create
                        </button>
                      </td>
                      <td>
                        {" "}
                        <button
                          className='deploy-btn'
                          // className={`deploy-btn ${
                          //   data.status !== "pending" ? "disabled" : ""
                          // }`}
                          // disabled={data.status === "pending"}
                          onClick={() => generateArtifact(data.git_repo_name)}
                        >
                          {/* <img className="ellipse" src={ellipse} alt="" /> */}
                          Deploy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {loggedIn && (
          <button
            className='git-button'
            disabled={isPushToGitButtonDisabled}
            style={
              isPushToGitButtonDisabled ? { backgroundColor: "#9F9F9F" } : {}
            }
            // onClick={generateJenkinsVM}
            onClick={pushToGit}
            // disabled={selectedReposForGit.length === 0}
          >
            {loaderGitPush ? (
              <img className='loading-gif' src={LoadingGif} alt='loading...' />
            ) : (
              "Push to Git"
            )}
          </button>
        )}
      </div>

      {showRepoSelect && (
        <RepoSelectPopup
          repositories={repositories}
          onSubmit={handleSelectedRepos}
          onClose={() => setShowRepoSelect(false)}
        />
      )}

      {showArtifactPopup && (
        <GitRepoGenerateArtifact
          repoData={repoDetails}
          onClose={() => setShowArtifactPopup(false)}
        />
      )}
      {popupLoading && <div className='loadinggif'>Cloning the project...</div>}
      {repoAnalysisLoader && (
        <div className='loadinggif'>Analysing the project...</div>
      )}

      {pushGitSuccessPopoup && (
        <div className='migrate-pop-wrapper'>
          <div className='migrate-pop'>
            <div className='migrate-pop-img'>
              <img src={Vector} onClick={onRefreshStatus} />
            </div>
            <div>
              <div>
                {" "}
                <img className='successIcon' src={SuccessIcon} alt='' />{" "}
              </div>
              <p className='app-migrated'>Successfully pushed to git</p>
              {/* <button onClick={onRefreshStatus}>OK</button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GitRepo
