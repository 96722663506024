import React, { useEffect, useState } from "react"
import "../styles/signuplogin.css"
import LoadingGif from "../Images/loader.gif"
import backgroundImage from "../Images/background-image.jpg"
import SuccessIcon from "../Images/verifyImage.png"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6"
import { Logo } from "./constants"

function Signup() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  })
  const [popup, setPopup] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false)

  const navigate = useNavigate()

  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev)
  }

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prev) => !prev)
  }

  const handleChange = (e, label) => {
    setInputData({
      ...inputData,
      [label]: e.target.value,
    })
  }

  useEffect(() => {
    const checkFormFilled = () => {
      for (const key in inputData) {
        if (inputData[key] === "") {
          return true
        }
      }
      return false
    }
    setIsButtonDisabled(checkFormFilled())
  }, [inputData])

  const handleSignup = () => {
    if (!isButtonDisabled) {
      if (inputData.password !== inputData.confirmPassword) {
        alert("Password and Confirm Password do not match")
        return
      }

      setLoader(true)
      axios
        .post(`${baseUrl}/signup`, inputData)
        .then((response) => {
          setPopup(true)
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (error.response.data.error === "USER_EXISTS") {
              alert("User with email already exists. Please try logging in!")
              navigate("/")
            } else if (error.response.data.error === "USER_RESTRICTED") {
              alert("Signup restricted to niveussolutions.com email addresses!")
              setLoader(false)
              setInputData({
                email: "",
                password: "",
                confirmPassword: "",
              })
            }
          } else {
            console.error("An error occurred:", error)
            alert("Something went wrong!")
            setLoader(false)
          }
        })
    }
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && !isButtonDisabled) {
        handleSignup()
      }
    }

    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [isButtonDisabled, inputData])

  const handleOkClick = () => {
    navigate("/")
  }

  const handleLoginClick = () => {
    navigate("/")
  }

  return (
    <div>
      <div className='login-wrapper'>
        <div className='home-img'>
          <img src={backgroundImage} alt='background' />
        </div>
        <div className='login-right'>
          <img src={Logo} alt='logo' />

          <div className='login-details wrapper-l'>
            <p className='login-details-heading'>Sign into your account</p>
            <label>
              Email address
              <input
                className='input-login'
                type='email'
                value={inputData.email}
                onChange={(e) => handleChange(e, "email")}
                placeholder='Enter your Email address'
              />
            </label>
            <label>
              Password
              <div className='password-input-wrapper input-login'>
                <input
                  className='input-password'
                  type={isPasswordVisible ? "text" : "password"}
                  value={inputData.password}
                  onChange={(e) => handleChange(e, "password")}
                  placeholder='Enter your password'
                />
                <div
                  className='password-toggle-icon'
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
                </div>
              </div>
            </label>
            <label>
              Confirm Password
              <div className='password-input-wrapper input-login'>
                <input
                  className='input-password'
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  value={inputData.confirmPassword}
                  onChange={(e) => handleChange(e, "confirmPassword")}
                  placeholder='Enter your password'
                />
                <div
                  className='password-toggle-icon'
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {isConfirmPasswordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
                </div>
              </div>
            </label>
          </div>
          <div className='button-wrap-login'>
            <button
              className='login-button'
              style={isButtonDisabled ? { backgroundColor: "#9F9F9F" } : {}}
              onClick={handleSignup}
            >
              {loader ? (
                <img
                  className='signup-loading-gif'
                  src={LoadingGif}
                  alt='loading...'
                />
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
          <p className='signup-redirect-heading'>
            Already have an account?{" "}
            <span className='signup-redirect' onClick={handleLoginClick}>
              Login
            </span>
          </p>
        </div>
      </div>

      {popup && (
        <div className='migrate-pop-wrapper'>
          <div className='migrate-pop ' id='migrate-pop-height'>
            <div className='pop-wrapper-start'>
              <div>
                <img
                  className='signup-successIcon'
                  src={SuccessIcon}
                  alt='success'
                />
              </div>
              <p className='signup-pop-font'>
                Verification Mail has been sent to {inputData.email}
              </p>
              <button className='signup-pop-button' onClick={handleOkClick}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Signup
