import React, { useState, useEffect } from "react"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import "../../../styles/migrate.css"
import DockerPopup from "../../DockerPop"
import Vector from "../../../Images/vector.png"
import LoadingGif from "../../../Images/loader.gif"
import SuccessIcon from "../../../Images/success.jpg"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6"
import { useAuth } from "../../../hooks/auth"
// import { Logo } from "./constants";
import "../styles/createArtifactPopup.css"
import { useDispatch, useSelector } from "react-redux"
import { selectedRepoRedux } from "../../../redux/actions"
import DockerPopupBulk from "./DockerPopupBulk"
import dockerLoaderGif from "../../../Images/bulkMigration/GenLoadingAnimation.gif"
import { checkUserToken } from "../../../hooks/session"

const GitRepoGenerateArtifact = ({
  // repoData,
  repoIdFromCreateArtifact,
  onClose,
  onSave,
}) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()

  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const deploymentProfilesRedux = useSelector(
    (state) => state.deploymentProfiles,
  )
  const repoAnalysisResponse = useSelector(
    (state) => state.repoAnalysisResponse,
  )

  const [isChecked, setIsChecked] = useState(false)
  const [fieldTitles, setFieldTitles] = useState({})
  const [deploymentOption, setDeploymentOption] = useState("k8")
  const [cloudService, setCloudService] = useState("gcp")
  const [isDockerChecked, setIsDockerChecked] = useState(false)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [containerization, setContainerization] = useState("buildpacks")
  const [isFormFilled, setFormFilled] = useState(false)
  const [migrationPop, setMigrationPop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [migrationDone, setMigrationDone] = useState(false)
  const [repoData, setRepoData] = useState({})
  const [dockerLoader, setDockerLoader] = useState(false)

  const [deploymentProfiles, setDeploymentProfiles] = useState([])
  const [helm, setHelm] = useState(false)

  const [selectedOption, setSelectedOption] = useState("serviceAccount")

  useEffect(() => {
    if (repoAnalysisResponse && Array.isArray(repoAnalysisResponse)) {
      // Find the matching object
      const matchingObject = repoAnalysisResponse.find(
        (obj) => obj.id === repoIdFromCreateArtifact,
      )

      // If a matching object is found, log it
      if (matchingObject) {
        setRepoData(matchingObject)
        console.log(matchingObject)
      }
    }
  }, [repoAnalysisResponse, repoIdFromCreateArtifact])

  const projectId =
    cloudService === "gcp"
      ? "Project ID"
      : cloudService === "aws"
      ? "Account ID"
      : "Resource Group"

  const [windowData, setWindowData] = useState({
    ci_cd_branch: "",
    // entrypoint: "",
    container_port: "",
    // email_id: "",
    cluster_name: "",
    location: "",
    type: "",
    project_id: "",
    helm_branch: "",
    helm_repo: "",
    image: "",
    // builder: "",
    image_name: "",
    // sonarqube_server_url: "",
    // sonarqube_token: "",
    // sonarqube_project_key: "",
  })
  const [sonarqubeRequired, setSonarqubeRequired] = useState(false)
  const [data, setData] = useState({
    builder: "",
    user_email: cookies.email,
    user_id: cookies.userId,
    image_name: "",
    src_folder_name: repoData.repo_name,
    ci_cd_branch: "",
    deployment_metadata_name: "",
    replicas: "",
    image: "",
    container_port: "",
    manifest1: "k8/deployment.yaml",
    manifest2: "k8/service.yaml",
    type: "",
    project_id: "",
    cluster_name: "",
    location: "",
    credentials_id: "",
    application: repoData.app_type,
    entrypoint: "",
    helm_chart_name: "",
    email_id: "",
    sonar_token: "",
    helm_branch: "",
    helm_repo: "",
    helm_repo_username: "",
    helm_repo_access_token: "",
    containerization: containerization,
    // ssh_email: location.state.ssh_email,
    // ssh_email: "",
    aws_cred_id: "",
    acr_cred_id: "",
    service_principal_cred_id: "",
    sonarqube_server_url: "",
    sonarqube_token: "",
    sonarqube_project_key: "",
  })

  const [dockerJson, setDockerJson] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("field_comments_migrate.json")
      const json = await response.json()
      setFieldTitles(json)
    }
    fetchData()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  useEffect(() => {
    // console.log(data)
    const checkFormFilled = () => {
      for (const key in windowData) {
        if (windowData.hasOwnProperty(key) && data[key] === "") {
          return false
        }
      }
      return true
    }
    // console.log("checkFormFilled(): ", checkFormFilled())
    setFormFilled(checkFormFilled())
  }, [data])

  useEffect(() => {
    const commonProps = {
      ci_cd_branch: "",
      // entrypoint: "",
      container_port: "",
      // email_id: "",
      cluster_name: "",
      location: "",
      type: "",
      project_id: "",
      image: "",
      // builder: "",
      image_name: "",
      // sonarqube_server_url: "",
      // sonarqube_token: "",
      // sonarqube_project_key: "",
    }

    let specificProps = {}

    if (cloudService === "gcp") {
      // specificProps =
      //   deploymentOption === "helm" ? { helm_branch: "", helm_repo: "" } : ""
      // { deployment_metadata_name: "", replicas: "", credentials_id: "" }
      if (deploymentOption === "helm") {
        if (sonarqubeRequired) {
          specificProps = {
            sonarqube_server_url: "",
            sonarqube_token: "",
            sonarqube_project_key: "",
          }
        } else {
          specificProps = { helm_branch: "", helm_repo: "" }
        }
      } else {
        specificProps = {
          // deployment_metadata_name: "",
          // replicas: "",
          // credentials_id: "",
        }
      }
    } else if (cloudService === "aws") {
      specificProps =
        deploymentOption === "helm"
          ? { aws_cred_id: "", helm_branch: "", helm_repo: "" }
          : { aws_cred_id: "", replicas: "", credentials_id: "" }
    } else if (cloudService === "azure") {
      specificProps =
        deploymentOption === "helm"
          ? {
              acr_cred_id: "",
              service_principal_cred_id: "",
              helm_branch: "",
              helm_repo: "",
            }
          : {
              acr_cred_id: "",
              service_principal_cred_id: "",
              replicas: "",
              credentials_id: "",
            }
    }

    setWindowData({ ...commonProps, ...specificProps })
  }, [cloudService, deploymentOption, sonarqubeRequired])

  // console.log("windowData: ", windowData)

  // checkbox change handler
  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked)

    const defaultData = {
      builder: "",
      user_email: cookies.email,
      user_id: cookies.userId,
      image_name: "",
      src_folder_name: repoData.repo_name,
      ci_cd_branch: "",
      deployment_metadata_name: "",
      replicas: "",
      // selector_name: "",
      image: "",
      container_port: "",
      manifest1: "k8/deployment.yaml",
      manifest2: "k8/service.yaml",
      type: "",
      project_id: "",
      cluster_name: "",
      location: "",
      credentials_id: "",
      application: repoData.app_type,
      token: "",
      branch: "",
      repo_type: "",
      entrypoint: "",
      helm_chart_name: "",
      email_id: "",
      sonar_token: "",
      helm_branch: "",
      helm_repo: "",
      helm_repo_username: "",
      helm_repo_access_token: "",
      containerization: containerization,
      // ssh_email: "",
      aws_cred_id: "",
      acr_cred_id: "",
      service_principal_cred_id: "",
      sonarqube_server_url: "",
      sonarqube_token: "",
      sonarqube_project_key: "",
    }

    const response = isChecked
      ? { json: () => defaultData }
      : await fetch("../config.json")

    const json = await response.json()
    setData({ ...defaultData, ...json })
  }

  useEffect(() => {
    if (deploymentProfilesRedux) {
      const profile = deploymentProfilesRedux.map((profile) => ({
        jobName: profile.job_name,
        profile: profile.ci_cd_type,
      }))
      setDeploymentProfiles(profile)
    } else {
      setDeploymentProfiles([])
    }
  }, [deploymentProfilesRedux])

  const handleSave = () => {
    console.log("deployment option=====>", deploymentOption)
    onSave({
      data,
      deploymentOption,
      cloudService,
      sonarqubeRequired,
      repoId: repoIdFromCreateArtifact,
      selectedOption,
    })
    onClose()
  }

  const handleDockerCheckbox = () => {
    setIsDockerChecked(!isDockerChecked)
    if (!isDockerChecked) {
      const newData = { ...data, containerization: "dockerfile" }
      setData(newData)
    } else {
      const newData = { ...data, containerization: "buildpacks" }
      setData(newData)
    }
  }

  const handleDockerClick = async () => {
    setDockerLoader(true)
    try {
      const response = await axios.post(`${baseUrl}/convert_dockerfile`, {
        projectType: data.application,
        repoHasDocker: repoData.has_dockerfile,
        repo_name: repoData.repo_name,
        user_email: cookies.email,
      })
      setDockerJson(response.data.dockerfileJSON)
    } catch (error) {
      console.error("Error:", error)
    }
    setDockerLoader(false)
    setPopupOpen(true)
  }

  const handlPopup = (data) => {
    setPopupOpen(data)
  }
  const handleSonarqubeChange = () => {
    setSonarqubeRequired(!sonarqubeRequired)
  }
  const handleHelmChange = () => {
    setHelm(!helm)
    if (!helm) {
      setDeploymentOption("helm")
    } else {
      setDeploymentOption("k8")
    }
  }

  const handleClose = () => {
    setMigrationPop(false)
    setMigrationDone(false)
  }

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
  }

  return (
    <div className='artifact-wrapper'>
      <div className='artifact-data-wrapper'>
        <div className='close-container'>
          <h4>Deployment Inputs</h4>
          <button className='close-btn' onClick={onClose}>
            &times;
          </button>
        </div>
        <div className='artifact-data-padding'>
          <div className='migrate-options'>
            <label className='checkBox'>
              Select user authentication
              <div className='artifact-radio-buttons'>
                <label
                  className={`radio-button ${
                    selectedOption === "serviceAccount" ? "selected" : ""
                  }`}
                >
                  <input
                    type='radio'
                    value='serviceAccount'
                    checked={selectedOption === "serviceAccount"}
                    onChange={handleOptionChange}
                  />
                  Service Account
                </label>
                <label
                  className={`radio-button ${
                    selectedOption === "adc" ? "selected" : ""
                  }`}
                >
                  <input
                    type='radio'
                    value='adc'
                    checked={selectedOption === "adc"}
                    onChange={handleOptionChange}
                  />
                  ADC
                </label>
              </div>
            </label>
            <div className='checkBox'>
              <label>
                <input
                  className='assess-input'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  type='checkbox'
                />{" "}
                Show sample inputs
              </label>
            </div>

            <div className='checkBox'>
              <label>
                <input
                  className='assess-input'
                  checked={sonarqubeRequired}
                  onChange={handleSonarqubeChange}
                  type='checkbox'
                />{" "}
                Sonarqube
              </label>
            </div>
            <div className='checkBox'>
              <label>
                <input
                  className='assess-input'
                  checked={helm}
                  onChange={handleHelmChange}
                  type='checkbox'
                />{" "}
                Helm
              </label>
            </div>
          </div>
          <div className='details-wrapper'>
            <div className='detail-box'>
              <div className='detail-box-header'>
                <p>Application details</p>
              </div>
              <label className='detail-box-label'>
                {" "}
                CI/CD branch name{" "}
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='ci_cd_branch'
                  value={data.ci_cd_branch}
                  title={fieldTitles.ci_cd_branch_title}
                  onChange={handleChange}
                  placeholder='branch name'
                />
              </label>
              {data.application === "Node.js" && (
                <label className='detail-box-label'>
                  Entrypoint
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='entrypoint'
                    value={data.entrypoint}
                    title={fieldTitles.entrypoint_title}
                    onChange={handleChange}
                    placeholder='Application entry file'
                  />
                </label>
              )}

              <label className='detail-box-label'>
                Container Port
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='container_port'
                  value={data.container_port}
                  title={fieldTitles.container_port_title}
                  onChange={handleChange}
                  placeholder='Application port'
                />
              </label>
            </div>

            <div className='detail-box'>
              <div className='detail-box-header'>
                <p> Deployment cluster details</p>
              </div>

              <label className='detail-box-label'>
                {" "}
                Cluster name{" "}
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='cluster_name'
                  value={data.cluster_name}
                  title={fieldTitles.cluster_name_title}
                  onChange={handleChange}
                  placeholder='Cluster name'
                />
              </label>

              <label className='detail-box-label'>
                {" "}
                Cluster location{" "}
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='location'
                  value={data.location}
                  title={fieldTitles.location}
                  onChange={handleChange}
                  placeholder='Cluster location'
                />
              </label>

              <label className='detail-box-label'>
                Service type
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='type'
                  value={data.type}
                  title={fieldTitles.type_title}
                  onChange={handleChange}
                  placeholder='Service type'
                />
              </label>
              <label className='detail-box-label'>
                {projectId}
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='project_id'
                  value={data.project_id}
                  title={fieldTitles.project_id_title}
                  onChange={handleChange}
                  placeholder={projectId}
                />
              </label>
            </div>

            {deploymentOption === "k8" && (
              <div className='detail-box'>
                <div className='detail-box-header'>
                  <p>Jenkins deployment details</p>
                </div>

                <label className='detail-box-label'>
                  {" "}
                  Replicas{" "}
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='replicas'
                    value={data.replicas}
                    title={fieldTitles.replicas_title}
                    onChange={handleChange}
                    placeholder='Cluster replicas'
                  />
                </label>
                <label className='detail-box-label'>
                  Deployment name
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='deployment_metadata_name'
                    value={data.deployment_metadata_name}
                    title={fieldTitles.deployment_metadata_name_title}
                    onChange={handleChange}
                    placeholder='Deployment name'
                  />
                </label>

                {cloudService === "gcp" &&
                  selectedOption === "serviceAccount" && (
                    <label className='detail-box-label'>
                      Credentials ID
                      <input
                        className='migrate-input-assess'
                        type='text'
                        name='credentials_id'
                        value={data.credentials_id}
                        title={fieldTitles.credentials_id_title}
                        onChange={handleChange}
                        placeholder='Credentials id'
                      />
                    </label>
                  )}
              </div>
            )}

            {deploymentOption === "helm" && (
              <div className='detail-box'>
                <div className='detail-box-header'>
                  <p>Helm details</p>
                </div>

                <label className='detail-box-label'>
                  Helm repo
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='helm_repo'
                    value={data.helm_repo}
                    title={fieldTitles.helm_repo_title}
                    onChange={handleChange}
                    placeholder='Repository to add helm'
                  />
                </label>
                <label className='detail-box-label'>
                  Helm Chart Name
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='helm_chart_name'
                    value={data.helm_chart_name}
                    title={fieldTitles.helm_chart_name_title}
                    onChange={handleChange}
                    placeholder='Repository to add helm'
                  />
                </label>

                <label className='detail-box-label'>
                  Branch name
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='helm_branch'
                    value={data.helm_branch}
                    title={fieldTitles.helm_branch_title}
                    onChange={handleChange}
                    placeholder='Branch name'
                  />
                </label>
              </div>
            )}

            <div className='detail-box'>
              {isPopupOpen && (
                <DockerPopupBulk handlPopup={handlPopup} json={dockerJson} />
              )}
              <div className='detail-box-header'>
                <p>Containerization details</p>
              </div>

              <div className='Dockerfile'>
                <label>
                  <input
                    className='assess-input'
                    type='checkbox'
                    checked={isDockerChecked}
                    onChange={handleDockerCheckbox}
                  />
                  Dockerfile
                </label>
                {isDockerChecked && (
                  <p onClick={handleDockerClick}>
                    {dockerLoader ? (
                      <img
                        className='dockerLoaderGif'
                        src={dockerLoaderGif}
                        alt='...'
                      ></img>
                    ) : (
                      "Edit"
                    )}
                  </p>
                )}
              </div>

              <label className='detail-box-label'>
                Image name
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='image_name'
                  value={data.image_name}
                  title={fieldTitles.image_name_title}
                  onChange={handleChange}
                  placeholder='Image name'
                />
              </label>
              {!isDockerChecked && (
                <label className='detail-box-label'>
                  {" "}
                  Buildpacks builder{" "}
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='builder'
                    value={data.builder}
                    title={fieldTitles.builder_title}
                    onChange={handleChange}
                    placeholder='Buildpacks builder'
                  />
                </label>
              )}

              <label className='detail-box-label'>
                Artifact registry path
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='image'
                  value={data.image}
                  title={fieldTitles.image_title}
                  onChange={handleChange}
                  placeholder='Artifact registry path'
                />
              </label>
            </div>

            {sonarqubeRequired && (
              <div className='detail-box'>
                <div className='detail-box-header'>
                  <p>Sonarqube details</p>
                </div>
                <label className='detail-box-label'>
                  Server url
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='sonarqube_server_url'
                    value={data.sonarqube_server_url}
                    title={fieldTitles.helm_repo_title}
                    onChange={handleChange}
                    placeholder='Sonarqube server url'
                  />
                </label>

                <label className='detail-box-label'>
                  Project token
                  <input
                    className='migrate-input-assess'
                    type='password'
                    name='sonarqube_token'
                    value={data.sonarqube_token}
                    onChange={handleChange}
                    placeholder='Sonarqube project token'
                  />
                </label>

                <label className='detail-box-label'>
                  Server key
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='sonarqube_project_key'
                    value={data.sonarqube_project_key}
                    onChange={handleChange}
                    placeholder='Sonarqube Project key'
                  />
                </label>
              </div>
            )}

            <div className='artifact-button-wrapper'>
              <div>
                <button
                  className={`save-button-pop ${
                    !isFormFilled ? "disabled" : ""
                  } `}
                  onClick={() => {
                    handleSave()
                    onClose()
                  }}
                  disabled={!isFormFilled}
                >
                  Generate Artifact
                </button>
              </div>
            </div>

            {migrationPop && (
              <div className='migrate-pop-wrapper'>
                <div className='migrate-pop'>
                  <div className='migrate-pop-img'>
                    <img src={Vector} onClick={handleClose} />
                  </div>
                  {!migrationDone ? (
                    <div>
                      <p className='migrate-pop-head'>
                        Would you like to start the Artifacts migration Process?
                      </p>
                      <p className='migrate-pop-desc'>
                        If yes, please click "Start" button to proceed
                      </p>
                      <button
                        className='assess-button'
                        style={loading ? { backgroundColor: "#5a57ff" } : {}}
                        // onClick={saveAndMigrate}
                      >
                        {loading ? (
                          <img className='loading-gif' src={LoadingGif} />
                        ) : (
                          "Start"
                        )}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div>
                        {" "}
                        <img className='successIcon' src={SuccessIcon} />{" "}
                      </div>
                      <p className='app-migrated'>
                        Files are updated in repository
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GitRepoGenerateArtifact
