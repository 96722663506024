import React, { useState, useEffect } from "react"
import axios from "axios"
import { useAuth } from "../../../hooks/auth"
import plus from "../../../Images/bulkMigration/Vectorplus.png"
import loaderGif from "../../../Images/loader.gif"
import "../styles/addProfilePopup.css"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { checkUserToken } from "../../../hooks/session"
import { setArgoApp } from "../../../redux/actions"

const AddAppPopup = ({ onClose }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const [deploymentProfiles, setDeploymentProfiles] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const dispatch = useDispatch()

  const argoConnectedRepoRedux = useSelector((state) => state.connectedRepo)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const [formData, setFormData] = useState({
    appName: "",
    connectedRepo: "",
    helmFolder: "",
    helmValues: "",
  })

  const [helmResponse, setHelmResponse] = useState([])
  const [helmValueFile, setHelmValueFile] = useState([])

  useEffect(() => {
    const allFieldsFilled =
      formData.appName !== "" &&
      formData.connectedRepo !== "" &&
      formData.helmFolder !== "" &&
      formData.helmValues !== ""
    setIsButtonDisabled(!allFieldsFilled)
  }, [formData])

  const [createCredentialLoader, setCreateCredentialLoader] = useState(false)

  const handleChange = async (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (name === "connectedRepo" && value) {
      try {
        const response = await axios.post(
          `${baseUrl}/argocd/helm-folder-name`,
          {
            repoUrl: value,
          },
        )
        console.log("/argocd/helm-folder-name:", response.data)
        setHelmResponse(response.data.items)
      } catch (error) {
        console.error("Error during API call:", error)
      }
    }

    if (name === "helmFolder" && value) {
      try {
        const response = await axios.post(
          `${baseUrl}/argocd/helm-app-details`,
          {
            repoURL: formData.connectedRepo,
            path: value,
          },
        )
        console.log("/argocd/helm-app-details:", [response.data.helm])
        setHelmValueFile([response.data.helm])
      } catch (error) {
        console.error("Error during API call:", error)
      }
    }
  }

  const handleCreateApp = async () => {
    try {
      let data = {
        name: formData.appName,
        repoURL: formData.connectedRepo,
        path: formData.helmFolder,
        valueFile: formData.helmValues,
      }

      console.log("FormData:", data)
      onClose()
      const response = await axios.post(`${baseUrl}/argocd/create-app`, data)
      console.log("/argocd/create-app:", response.data)

      // Fetch connected Repo from ArgoCD
      const ArgocdAppResponse = await axios.get(`${baseUrl}/argocd/list-app`)
      const app = ArgocdAppResponse.data.items
      console.log("list app>>>>", app)
      if (app) {
        dispatch(setArgoApp(app))
      } else {
        dispatch(setArgoApp([]))
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  console.log("helmValueFile>>", helmValueFile)

  return (
    <div className='add-profile-wrapper'>
      <div className='add-profile-data-wrapper'>
        <div className='ap-close-container'>
          <h4>Add Application</h4>
          <button className='ap-close-btn' onClick={onClose}>
            &times;
          </button>
        </div>

        <div className='ap-content-container'>
          <div>
            <label className='ap-box-label'>
              App Name
              <input
                className='ap-input'
                type='text'
                name='appName'
                value={formData.appName}
                onChange={handleChange}
                placeholder='Enter App Name'
              />
            </label>

            <label className='ap-box-label'>
              Connected Repositories
              <select
                className='ap-input'
                name='connectedRepo'
                value={formData.connectedRepo}
                onChange={handleChange}
              >
                <option value=''>Choose Connected Repo</option>
                {argoConnectedRepoRedux.map((repo) => (
                  <option key={repo.repo} value={repo.repo}>
                    {repo.repo}
                  </option>
                ))}
              </select>
            </label>

            <label className='ap-box-label'>
              Helm Folder
              <select
                className='ap-input'
                name='helmFolder'
                value={formData.helmFolder}
                onChange={handleChange}
              >
                <option value=''>Choose Helm Folder</option>
                {helmResponse.map((helm) => (
                  <option key={helm.path} value={helm.path}>
                    {helm.path}
                  </option>
                ))}
              </select>
            </label>

            <label className='ap-box-label'>
              Helm Values
              <select
                className='ap-input'
                name='helmValues'
                value={formData.helmValues}
                onChange={handleChange}
              >
                <option value=''>Choose Helm Value File</option>
                {helmValueFile.map((helm) => (
                  <option key={helm.valueFiles} value={helm.valueFiles}>
                    {helm.valueFiles}
                  </option>
                ))}
              </select>
            </label>

            <button
              // className='create-profile-btn'
              className={`create-profile-btn ${
                isButtonDisabled ? "disabled-btn" : ""
              }`}
              onClick={handleCreateApp}
              disabled={isButtonDisabled}
            >
              <img
                className='plus-icon'
                src={createCredentialLoader ? loaderGif : plus}
                alt='plus'
              />
              Create App
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAppPopup
