import React, { useEffect, useState } from "react"
import axios from "axios"
import { useAuth } from "../../../hooks/auth"
import deployIcon from "../../../Images/bulkMigration/deployBtn.png"
import "../styles/deployPopup.css"
import { useSelector } from "react-redux"
import { checkUserToken } from "../../../hooks/session"
import { useNavigate } from "react-router-dom"

const DeployPopup = ({ repoDetailsForDeploy, onClose, onSaveDeploy }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  // const baseUrl =
  //   process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const navigate = useNavigate()
  const deploymentProfilesRedux = useSelector(
    (state) => state.deploymentProfiles,
  )

  const createArtifactCICDBranch = useSelector(
    (state) => state.createArtifactCICDBranch,
  )

  const [deploymentProfiles, setDeploymentProfiles] = useState([])
  const [jobName, setJobName] = useState("")
  const [selectedProfile, setSelectedProfile] = useState("")

  console.log("repoDetailsForDeploy(DP): ", repoDetailsForDeploy)

  useEffect(() => {
    if (deploymentProfilesRedux) {
      setDeploymentProfiles(deploymentProfilesRedux)
    } else {
      setDeploymentProfiles([])
    }
  }, [deploymentProfilesRedux])

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])
  const handleDeploy = () => {
    // Find selected profile data
    const selectedProfileData = deploymentProfiles.find(
      (profile) => profile.profile_name === selectedProfile,
    )

    let cicd_branch
    let repo_id

    // Find cicd_branch
    createArtifactCICDBranch.forEach((repo) => {
      if (repo.id === repoDetailsForDeploy.id) {
        cicd_branch = repo.cicd_branch
        repo_id = repo.id
        console.log("repo:", cicd_branch)
      }
    })
    onSaveDeploy({
      repoId: repo_id,
      profile_name: selectedProfileData.profile_name,
      job_name: jobName,
      repository_path_name: repoDetailsForDeploy.repo_path,
      deployment_branch: cicd_branch,
    })
    onClose()
  }

  const isButtonDisabled = !jobName || !selectedProfile

  return (
    <div className='deploy-wrapper'>
      <div className='deploy-data-wrapper'>
        <div className='deploy-close-container'>
          <h5>Deployment</h5>
          <button className='deploy-close-btn' onClick={onClose}>
            &times;
          </button>
        </div>
        <div className='deploy-content-container'>
          <label className='ap-box-label'>
            {/* Replicas */}
            <input
              className='ap-input'
              type='text'
              name='replicas'
              value={jobName}
              // title={fieldTitles.replicas_title}
              onChange={(e) => setJobName(e.target.value)}
              placeholder='Enter Job name'
            />
          </label>

          <label className='ap-box-label'>
            {/* Deployment Profile */}
            <select
              className='ap-input'
              style={{ marginTop: "1rem" }}
              name='pipeline'
              value={selectedProfile}
              onChange={(e) => setSelectedProfile(e.target.value)}
            >
              <option value=''>Select a deployment Profile</option>
              {deploymentProfiles.map((profile, index) => (
                <option key={index} value={profile.profile_name}>
                  {profile.profile_name} / {profile.vm_ip}
                </option>
              ))}
            </select>
          </label>
          <button
            className='deploy-btn'
            onClick={() => {
              handleDeploy()
              onClose()
            }}
            disabled={isButtonDisabled}
          >
            <img className='plus-icon' src={deployIcon} alt='plus' />
            Trigger Deployment
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeployPopup
