const initialState = {
  repositories: [],
  gitLogged: false,
  selectedRepoRedux: null,
  clearSelectedRepoRedux: null,
  selectedRepoDetailsRedux: null,
  gitCredentials: null,
  setDeploymentProfiles: null,
  jobHistory: [],
  jenkinsCredentials: null,
  deploymentCurrentStatus: [],
  repoAnalysisResponse: [],
  dependencyCheckResponse: [],
  createArtifactCICDBranch: [],
  selectedBranch: {},
  connectedRepo: null,
  argoApp: null,
  argoLoginData: {
    argocdUrl: "",
    argocdUsername: "",
    argocdPassword: "",
  },
  isLoggedIn: false,
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPOSITORIES":
      return {
        ...state,
        repositories: action.payload,
      }
    case "SET_GIT_LOGGED":
      return {
        ...state,
        gitLogged: action.payload,
      }
    case "SET_SELECTED_REPO":
      return {
        ...state,
        selectedRepoRedux: action.payload,
      }
    case "SET_SELECTED_BRANCH":
      return {
        ...state,
        selectedBranch: action.payload,
      }
    case "CLEAR_SELECTED_REPO":
      return {
        ...state,
        selectedRepoRedux: null,
        // deploymentCurrentStatus: [],
      }
    case "CLEAR_DEPLOYMENT_CURRENT_STATUS":
      return {
        ...state,
        deploymentCurrentStatus: [],
      }

    case "CLEAR_DEPENDENCY_CHECK_RESPONSE":
      return {
        ...state,
        dependencyCheckResponse: [],
      }

    case "CLEAR_JENKINS_CREDENTIALS":
      return {
        ...state,
        jenkinsCredentials: null,
      }

    case "CLEAR_CONNECTED_REPO":
      return {
        ...state,
        connectedRepo: null,
      }

    case "CLEAR_ARGO_APP":
      return {
        ...state,
        argoApp: null,
      }

    case "CLEAR_ARGO_LOGIN_DATA":
      return {
        ...state,
        argoLoginData: {
          argocdUrl: "",
          argocdUsername: "",
          argocdPassword: "",
          // isArgocdSignedin: false,
        },
      }

    case "CLEAR_ARGO_LOGIN":
      return {
        ...state,
        isLoggedIn: null,
      }

    case "SET_SELECTED_REPO_DETAILS":
      return {
        ...state,
        selectedRepoDetailsRedux: action.payload,
      }
    case "SET_GIT_CREDENTIALS":
      return {
        ...state,
        gitCredentials: action.payload,
      }
    case "SET_DEPLOYMENT_PROFILES":
      return {
        ...state,
        deploymentProfiles: action.payload,
      }
    case "SET_JENKINS_CREDENTIALS":
      return {
        ...state,
        jenkinsCredentials: action.payload,
      }
    case "SET_JOB_HISTORY":
      return {
        ...state,
        jobHistory: action.payload,
      }

    case "DEPLOYMENT_CURRENT_STATUS":
      const existingIndexForJobHistory =
        state.deploymentCurrentStatus.findIndex(
          (obj) => obj.id === action.payload.id,
        )
      if (existingIndexForJobHistory !== -1) {
        return {
          ...state,
          deploymentCurrentStatus: [
            ...state.deploymentCurrentStatus.slice(
              0,
              existingIndexForJobHistory,
            ),
            action.payload,
            ...state.deploymentCurrentStatus.slice(
              existingIndexForJobHistory + 1,
            ),
          ],
        }
      } else {
        return {
          ...state,
          deploymentCurrentStatus: [
            ...state.deploymentCurrentStatus,
            action.payload,
          ],
        }
      }

    case "SET_REPO_ANALYSIS_RESPONSE":
      const existingIndex = state.repoAnalysisResponse.findIndex(
        (obj) => obj.id === action.payload.id,
      )
      if (existingIndex !== -1) {
        // If the object already exists, replace it
        return {
          ...state,
          repoAnalysisResponse: [
            ...state.repoAnalysisResponse.slice(0, existingIndex),
            action.payload,
            ...state.repoAnalysisResponse.slice(existingIndex + 1),
          ],
        }
      } else {
        // If the object doesn't exist, add it
        return {
          ...state,
          repoAnalysisResponse: [...state.repoAnalysisResponse, action.payload],
        }
      }

    case "SET_DEPENDENCY_CHECK_RESPONSE":
      const dependencyIndex = state.dependencyCheckResponse.findIndex(
        (obj) => obj.id === action.payload.id,
      )
      if (dependencyIndex !== -1) {
        // If the object already exists, replace it
        return {
          ...state,
          dependencyCheckResponse: [
            ...state.dependencyCheckResponse.slice(0, dependencyIndex),
            action.payload,
            ...state.dependencyCheckResponse.slice(dependencyIndex + 1),
          ],
        }
      } else {
        // If the object doesn't exist, add it
        return {
          ...state,
          dependencyCheckResponse: [
            ...state.dependencyCheckResponse,
            action.payload,
          ],
        }
      }

    case "SET_CICD_BRANCH":
      const existingIndexForCICD = state.createArtifactCICDBranch.findIndex(
        (obj) => obj.id === action.payload.id,
      )
      if (existingIndexForCICD !== -1) {
        return {
          ...state,
          createArtifactCICDBranch: [
            ...state.createArtifactCICDBranch.slice(0, existingIndexForCICD),
            action.payload,
            ...state.createArtifactCICDBranch.slice(existingIndexForCICD + 1),
          ],
        }
      } else {
        return {
          ...state,
          createArtifactCICDBranch: [
            ...state.createArtifactCICDBranch,
            action.payload,
          ],
        }
      }

    case "SET_CONNECTED_REPO":
      return {
        ...state,
        connectedRepo: action.payload,
      }

    case "SET_ARGO_APP":
      return {
        ...state,
        argoApp: action.payload,
      }

    case "SET_ARGOCD_LOGIN_DATA":
      return {
        ...state,
        argoLoginData: action.payload,
      }

    case "SET_ARGOCD_LOGGED_IN":
      return {
        ...state,
        isLoggedIn: action.payload,
      }

    default:
      return state
  }
}

export default rootReducer
