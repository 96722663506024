// Deployments.js
import React, { useEffect, useState } from "react"
import LeftPanel from "./LeftPanel"
import plus from "../../Images/bulkMigration/Vectorplus.png"
import deleteIcon from "../../Images/bulkMigration/delete.png"
import loaderGif from "../../Images/loader.gif"
import "./styles/deploymentProf.css"
import "./styles/jenkinsCredentials.css"
import AddJenkinsCredPopup from "./popup_screens/JenkinsCredPopup"
import { useAuth } from "../../hooks/auth"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { setJenkinsCredentialsRedux } from "../../redux/actions"
import { checkUserToken } from "../../hooks/session"
import { useNavigate } from "react-router-dom"

function JenkinsCredentials() {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const jenkinsCredentialsRedux = useSelector(
    (state) => state.jenkinsCredentials,
  )

  const [showAddJenkinsCredPopup, setShowAddJenkinsCredPopup] = useState(false)
  const [jenkinsCredentials, setJenkinsCredentials] = useState([])
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false)
  const [clickedCredentialName, setClickedCredentialName] = useState("")
  const [deleteLoader, setDeleteLoader] = useState(false)

  useEffect(() => {
    if (jenkinsCredentialsRedux) {
      setJenkinsCredentials(jenkinsCredentialsRedux)
    } else {
      setJenkinsCredentials([])
    }
  }, [jenkinsCredentialsRedux])

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const handleAddProfile = () => {
    setShowAddJenkinsCredPopup(true)
  }

  const onDeleteIconClick = (cred) => {
    setClickedCredentialName(cred)
    setShowDeleteConfirmPopup(true)
  }

  const onConfirmDeleteProfile = async () => {
    setDeleteLoader(true)
    let data = {
      id: clickedCredentialName.id,
      cred_id: clickedCredentialName.cred_id,
    }

    await axios
      .post(`${baseUrl}/jenkins/delete_credential`, data)
      .then((response) => {
        console.log(response.data)
        setShowDeleteConfirmPopup(false)
      })
      .catch((error) => {
        console.error(error)
        alert("Somethingwent wrong in delete!")
        setShowDeleteConfirmPopup(false)
      })

    await axios
      .get(`${baseUrl}/jenkins/get_credential`)
      .then((response) => {
        console.log("/deployment/get after delete: ", response.data)
        setShowDeleteConfirmPopup(false)
        const cred_data = response.data
        dispatch(setJenkinsCredentialsRedux(cred_data))
        setDeleteLoader(false)
      })
      .catch((error) => {
        console.error(error)
        alert("Somethingwent wrong in get!")
        setShowDeleteConfirmPopup(false)
        setDeleteLoader(false)
      })
  }

  return (
    <div className='bulk-migration-container'>
      <LeftPanel />
      <div className='right-panel'>
        <div className='heading-btn-container'>
          <h4>Jenkins Credentials</h4>
          <button className='add-profile-btn' onClick={handleAddProfile}>
            <img className='plus-icon' src={plus} alt='plus' />
            Add credential
          </button>
        </div>

        <div className='profile-table-container'>
          <table className='profile-table'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Kind</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {jenkinsCredentials.map((credential, index) => (
                <tr key={index}>
                  <td>{credential.cred_id}</td>
                  <td>{credential.cred_name}</td>
                  <td>{credential.cred_kind}</td>
                  <td>{credential.description}</td>
                  <td
                    className='jc-delete'
                    onClick={() => onDeleteIconClick(credential)}
                  >
                    <img src={deleteIcon} alt='delete'></img>
                    <p>Delete</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showAddJenkinsCredPopup && (
        <AddJenkinsCredPopup
          onClose={() => setShowAddJenkinsCredPopup(false)}
        />
      )}

      {showDeleteConfirmPopup && (
        <div className='add-delete-wrapper '>
          <div className='success-delete-data-wrapper'>
            <p>Are you sure want to delete this Credential?</p>
            <div className='button-delete-container'>
              <button
                className='cancel-btn'
                onClick={() => setShowDeleteConfirmPopup(false)}
              >
                Cancel
              </button>
              <button className='confirm-btn' onClick={onConfirmDeleteProfile}>
                {deleteLoader ? (
                  <img className='plus-icon' src={loaderGif} alt='plus' />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default JenkinsCredentials
