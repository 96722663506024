import { useState, useEffect } from "react"
import axios from "axios"
import { Bars, ColorRing } from "react-loader-spinner"
import { useNavigate } from "react-router-dom"
import SuccessIcon from "../Images/success.jpg"
import FailIcon from "../Images/bulkMigration/failIcon.png"
import { useAuth } from "../hooks/auth"
import { checkUserToken } from "../hooks/session"
import { BiSolidSend } from "react-icons/bi"
import LoadingGif from "../Images/loader.gif"
import "../styles/jenkinsLbGen.css"

const JenkinsLb = ({ project, region, zone, instanceName }) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const navigate = useNavigate()

  const [instanceGroup, setInstanceGroup] = useState("")
  const [loadBalancer, setLoadBalancer] = useState("")
  const [loader, setLoader] = useState(false)
  const [popup, setPopup] = useState(false)

  const [error, setError] = useState(false)
  const [isLbGenerated, setIsLbGenerated] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const handleInstanceGroupChange = (e) => {
    setInstanceGroup(e.target.value)
  }

  const handleLoadBalancerChange = (e) => {
    setLoadBalancer(e.target.value)
  }

  const handleClose = () => {
    setPopup(false)
  }

  const generateJenkinsLB = () => {
    setLoader(true)
    try {
      const requestData = {
        project: project,
        region: region,
        zone: zone,
        vm_name: instanceName,
        instance_group_name: instanceGroup,
        lb_name: loadBalancer,
        user_email: cookies.email,
      }

      axios.post(`${baseUrl}/createLB`, requestData).then((response) => {
        setLoader(false)
        // console.log("/createLB: ", response.data.terraformOutput.signal)
        if (response.data.terraformOutput.signal === null) {
          setError(true)
        } else {
          setIsLbGenerated(true)
        }
        setPopup(true)
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className='lb-heading'>
        <p className='jenkin-details-heading '>Create Jenkins LB</p>
      </div>
      <div className='lb-wrapper'>
        <div className='region-zone-container-lb'>
          <div>
            <label className='jenkins-input-lb region-input'>
              Instance Group Name
              <input
                type='text'
                placeholder='test-group'
                value={instanceGroup}
                onChange={handleInstanceGroupChange}
              />
            </label>
            <label className='jenkins-input-lb'>
              Load Balancer Name
              <input
                type='text'
                placeholder='test-lb'
                value={loadBalancer}
                onChange={handleLoadBalancerChange}
              />
            </label>
          </div>
          <div>
            <button
              className=' lb-button'
              style={
                !project ||
                !region ||
                !zone ||
                !instanceName ||
                !instanceGroup ||
                !loadBalancer
                  ? { backgroundColor: "#9F9F9F" }
                  : {}
              }
              disabled={
                !project ||
                !region ||
                !zone ||
                !instanceName ||
                !instanceGroup ||
                !loadBalancer ||
                isLbGenerated
              }
              onClick={generateJenkinsLB}
            >
              {loader ? (
                <img
                  className='loading-gif-vm'
                  src={LoadingGif}
                  alt='loading...'
                />
              ) : isLbGenerated ? (
                "Generated Jenkins LB"
              ) : (
                "Generate Jenkins LB"
              )}
            </button>
          </div>
          {popup && (
            <div className='migrate-pop-wrapper'>
              <div className='migrate-pop ' id='migrate-pop-height'>
                {error ? (
                  <div className='pop-wrapper-start'>
                    <div>
                      <img className='failIconLb' src={FailIcon} alt='fail' />
                    </div>
                    <p className='app-migrated'>Error creating Jenkins LB</p>
                    <button className='assess-button-lb ' onClick={handleClose}>
                      OK
                    </button>
                  </div>
                ) : (
                  <div className='pop-wrapper-start'>
                    <div>
                      <img
                        className='successIconLb'
                        src={SuccessIcon}
                        alt='success'
                      />
                    </div>
                    <p className='app-migrated'>Jenkins LB is created</p>
                    <button className='assess-button-lb' onClick={handleClose}>
                      OK
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default JenkinsLb
