import React, { useState, useEffect } from "react"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import "../../styles/migrate.css"
import { useAuth } from "../../hooks/auth"
import "../bulkMigration/styles/createArtifactPopup.css"
import "../../styles/repoSelectPopup.css"
import LoadingGif from "../../Images/loader.gif"

const RepoSelectPopup = ({ onClose, repositories, onSubmit }) => {
  const [selectedRepos, setSelectedRepos] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false)

  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`
  const navigate = useNavigate()

  const { cookies } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  useEffect(() => {
    if (cookies.token == null) {
      navigate("/")
    }
  })

  const handleCheckboxChange = (repo) => {
    if (selectedRepos.includes(repo)) {
      setSelectedRepos(selectedRepos.filter((r) => r !== repo))
    } else {
      setSelectedRepos([...selectedRepos, repo])
    }
  }

  const handleSubmit = () => {
    setSubmitLoader(true)
    // console.log("Selected Repositories:", selectedRepos);
    // Add your logic here to handle the selected repositories

    axios
      .post(`${baseUrl}/bulk/get_repo_details`, {
        repositories: selectedRepos,
      })
      .then((response) => {
        console.log("=======response here")
        console.log(response.data)
        // setPopupLoading(false);
        // setShowArtifactPopup(true);
        onSubmit(selectedRepos, response.data)
        onClose()
        setSubmitLoader(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // console.log(repositories);

  return (
    <div className='artifact-wrapper'>
      <div className='repo-data-wrapper'>
        <div className='repo-close-container'>
          <button className='repo-close-btn' onClick={onClose}>
            &times;
          </button>
        </div>
        <div className='repo-table-container'>
          <table className='repo-table'>
            <thead className='repo-table-head'>
              <tr>
                <th>Repository Name</th>
                <th>Select</th>
                {/* <th>Status</th>
                    <th>Action</th> */}
              </tr>
            </thead>

            <tbody className='repo-table-body'>
              {repositories.map((repo, index) => (
                <tr key={index} onClick={() => handleCheckboxChange(repo)}>
                  <td>{repo}</td>
                  <td>
                    <input
                      type='checkbox'
                      onChange={() => {}}
                      checked={selectedRepos.includes(repo)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='submit-btn-container'>
          <button className='repo-submit-btn' onClick={handleSubmit}>
            {submitLoader ? (
              <img
                className='repo-loading-gif'
                src={LoadingGif}
                alt='loading...'
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RepoSelectPopup
