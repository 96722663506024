import React, { useState } from "react"
import "../../../index.css"
import axios from "axios"
// import "../../styles/docker.css"
import "../../bulkMigration/styles/dockerPopupBulk.css"
import { useEffect, useRef } from "react"
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import Vector from "../../../Images/vector.png"
import { useAuth } from "../../../hooks/auth"
import saveBtnLoaderGif from "../../../Images/bulkMigration/GenLoadingAnimation.gif"
import { checkUserToken } from "../../../hooks/session"
import { useNavigate } from "react-router-dom"

const DockerPopupBulk = (props) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const dockerfileArray = props.json.map((instruction) => ({
    key: instruction.lineno.toString(),
    property: instruction.name,
    value: Array.isArray(instruction.args)
      ? instruction.args.join(" ")
      : instruction.args,
  }))

  const dockerfileContent = props.json
    .map((instruction) => {
      let argsString = ""
      if (Array.isArray(instruction.args)) {
        argsString = instruction.args.join(" ")
      } else {
        argsString = instruction.args
      }

      return `${instruction.name} ${argsString}\n`
    })
    .join("")

  const [dockerfile, setDockerfile] = useState(dockerfileArray)
  const [showTextarea, setShowTextarea] = useState(false)
  const [textareaValue, setTextareaValue] = useState(dockerfileContent)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [saveBtnLoader, setSaveBtnLoader] = useState(false)

  const closePopup = () => {
    props.handlPopup(false)
  }

  const handleSaveClick = async () => {
    setSaveBtnLoader(true)
    try {
      const response = await axios.post(`${baseUrl}/arraytodocker`, dockerfile)
      if (response.data.merror === "error") {
        console.log(response.data.message)
        setShowError(true)
        setErrorMessage(
          `${response.data.message.message} at line ${response.data.message.line}`,
        )
      } else {
        props.handlPopup(false)
      }
    } catch (error) {
      console.error("Error:", error)
    }
    setSaveBtnLoader(true)
  }

  const handleErrorClose = () => {
    setShowError(false)
  }

  const editorRef = useRef(null)

  useEffect(() => {
    if (editorRef.current && !showTextarea) {
      // Set up Monaco Editor for Dockerfile
      monaco.editor.defineTheme("dockerfileTheme", {
        base: "vs",
        inherit: false,
        rules: [{ background: "EDF2F7" }],
        colors: {
          "editor.background": "#FFFFFF",
        },
      })

      const monacoEditor = monaco.editor.create(editorRef.current, {
        value: textareaValue, // Set initial content
        language: "dockerfile", // Set language mode to Dockerfile
        theme: "dockerfileTheme", // Optional: Set a custom theme
      })
      const editorElement = monacoEditor.getDomNode()

      if (editorElement) {
        editorElement.style.backgroundColor = "#F0F0F0"
      }

      monacoEditor.onDidChangeModelContent(() => {
        setTextareaValue(monacoEditor.getValue().split("\n"))
        const lines = monacoEditor.getValue().split("\n") // Split the string into lines
        const dockerfileArrayFromTextarea = lines.map((line, index) => {
          const words = line.split(/\s+/) // Split the line into words using whitespace as separator
          const property = words[0] // The first word is the property
          const value = words.slice(1).join(" ") // Join the rest of the words as the value

          return {
            key: index.toString(), // You can use the line index as the key
            property: property,
            value: value,
          }
        })

        setDockerfile(dockerfileArrayFromTextarea)
      })

      // Cleanup function to dispose of the editor when the component is unmounted
      return () => {
        monacoEditor.dispose()
      }
    }
  }, [showTextarea])

  return (
    <>
      <div className='docker-popup-bulk'>
        <div className='docker-wrapper-bulk'>
          <div className='docker-pop-header-bulk'>
            <p>Dockerfile</p> <img src={Vector} onClick={closePopup} />
          </div>
          <div className='monoco-wrapper-bulk'>
            <div ref={editorRef} className='monoco-editor' />
          </div>

          <button className='save-docker-button' onClick={handleSaveClick}>
            {saveBtnLoader ? (
              <img
                className='saveBtnLoader'
                src={saveBtnLoaderGif}
                alt='...'
              ></img>
            ) : (
              "Save"
            )}
          </button>
          {showError && (
            <div className='errorpopup'>
              <div className='docker-errorpopup-content'>
                <h3>Error</h3>
                <p>{errorMessage}</p>
                <button className='error-close' onClick={handleErrorClose}>
                  close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DockerPopupBulk
