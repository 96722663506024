import React, { useState, useEffect } from "react"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import "../styles/migrate.css"
import DockerPopup from "./DockerPop"
import Vector from "../Images/vector.png"
import LoadingGif from "../Images/loader.gif"
import SuccessIcon from "../Images/success.jpg"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6"
import { useAuth } from "../hooks/auth"
import { Logo } from "./constants"
import { checkUserToken } from "../hooks/session"

const Migrate = () => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }
  const navigate = useNavigate()

  const location = useLocation()
  const [isChecked, setIsChecked] = useState(false)
  const [fieldTitles, setFieldTitles] = useState({})
  const [deploymentOption, setDeploymentOption] = useState("helm")
  const [cloudService, setCloudService] = useState("gcp")
  const [isDockerChecked, setIsDockerChecked] = useState(false)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [containerization, setContainerization] = useState("buildpacks")
  const [isFormFilled, setFormFilled] = useState(false)
  const [migrationPop, setMigrationPop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState({})
  const [migrationDone, setMigrationDone] = useState(false)
  const [oneTimeMigration, setOneTimeMigration] = useState(false)
  const [dockerJson, setDockerJson] = useState({})

  const projectId =
    cloudService === "gcp"
      ? "Project ID"
      : cloudService === "aws"
      ? "Account ID"
      : "Resource Group"
  const [windowData, setWindowData] = useState({
    ci_cd_branch: "",
    entrypoint: "",
    container_port: "",
    email_id: "",

    cluster_name: "",
    location: "",
    type: "",
    project_id: "",

    helm_branch: "",
    helm_repo: "",

    image: "",
    builder: "",
    image_name: "",
  })
  const [sonarqubeRequired, setSonarqubeRequired] = useState(false)
  const [data, setData] = useState({
    git_repo: "",
    builder: "",
    image_name: "",
    src_folder_name: location.state.repoName,
    ci_cd_branch: "",
    deployment_metadata_name: "",
    replicas: "",
    selector_name: "",
    image: "",
    container_port: "",
    manifest1: "k8/deployment.yaml",
    manifest2: "k8/service.yaml",
    type: "",
    project_id: "",
    aws_id: "",
    cluster_name: "",
    location: "",
    credentials_id: "",
    application: location.state.application,
    entrypoint: "",
    helm_chart_name: "",
    email_id: "",
    sonar_token: "",
    helm_branch: "",
    helm_repo: "",
    helm_repo_username: "",
    helm_repo_access_token: "",
    containerization: containerization,
    user_email: location.state.user_email,
    aws_cred_id: "",
    acr_cred_id: "",
    service_principal_cred_id: "",
    sonarqube_server_url: "",
    sonarqube_token: "",
    sonarqube_project_key: "",
  })

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("field_comments_migrate.json")
      const json = await response.json()
      setFieldTitles(json)
    }
    fetchData()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  useEffect(() => {
    console.log(data)
    const checkFormFilled = () => {
      for (const key in windowData) {
        if (windowData.hasOwnProperty(key) && data[key] === "") {
          return false
        }
      }
      return true
    }
    setFormFilled(checkFormFilled())
  }, [data])

  useEffect(() => {
    if (cloudService === "gcp") {
      if (deploymentOption === "helm") {
        setWindowData({
          ci_cd_branch: "",
          entrypoint: "",
          container_port: "",
          email_id: "",

          cluster_name: "",
          location: "",
          type: "",
          project_id: "",

          helm_branch: "",
          helm_repo: "",

          image: "",
          builder: "",
          image_name: "",
        })
      } else {
        setWindowData({
          ci_cd_branch: "",
          entrypoint: "",
          container_port: "",
          email_id: "",

          cluster_name: "",
          location: "",
          type: "",
          project_id: "",

          deployment_metadata_name: "",
          replicas: "",
          credentials_id: "",

          image: "",
          builder: "",
          image_name: "",
        })
      }
    } else if (cloudService === "aws") {
      if (deploymentOption === "helm") {
        setWindowData({
          ci_cd_branch: "",
          entrypoint: "",
          container_port: "",
          email_id: "",

          cluster_name: "",
          location: "",
          type: "",
          project_id: "",
          aws_cred_id: "",

          helm_branch: "",
          helm_repo: "",

          image: "",
          builder: "",
          image_name: "",
        })
      } else {
        setWindowData({
          ci_cd_branch: "",
          entrypoint: "",
          container_port: "",
          email_id: "",

          cluster_name: "",
          location: "",
          type: "",
          project_id: "",
          aws_cred_id: "",

          // deployment_metadata_name: "",
          replicas: "",
          credentials_id: "",

          image: "",
          builder: "",
          image_name: "",
        })
      }
    } else if (cloudService === "azure") {
      if (deploymentOption === "helm") {
        setWindowData({
          ci_cd_branch: "",
          entrypoint: "",
          container_port: "",
          email_id: "",

          cluster_name: "",
          location: "",
          type: "",
          project_id: "",
          acr_cred_id: "",
          service_principal_cred_id: "",

          helm_branch: "",
          helm_repo: "",

          image: "",
          builder: "",
          image_name: "",
        })
      } else {
        setWindowData({
          ci_cd_branch: "",
          entrypoint: "",
          container_port: "",
          email_id: "",

          cluster_name: "",
          location: "",
          type: "",
          project_id: "",
          acr_cred_id: "",
          service_principal_cred_id: "",

          // deployment_metadata_name: "",
          replicas: "",
          credentials_id: "",

          image: "",
          builder: "",
          image_name: "",
        })
      }
    }
  }, [cloudService, deploymentOption])

  // checkbox change handler
  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked)
    if (!isChecked) {
      const response = await fetch("config.json")
      const json = await response.json()
      setData({
        git_repo: location.state.repo,
        builder: json.builder,
        image_name: json.image_name,
        src_folder_name: location.state.repoName,
        ci_cd_branch: json.ci_cd_branch,
        deployment_metadata_name: json.deployment_metadata_name,
        replicas: json.replicas,
        selector_name: json.selector_name,
        image: json.image,
        container_port: json.container_port,
        manifest1: json.manifest1,
        manifest2: json.manifest2,
        type: json.type,
        project_id: json.project_id,
        aws_id: json.aws_id,
        cluster_name: json.cluster_name,
        location: json.location,
        credentials_id: json.credentials_id,
        application: location.state.application,
        token: location.state.token,
        branch: location.state.branch,
        repo_type: location.state.selectedOption,
        entrypoint: json.entrypoint,
        helm_chart_name: json.helm_chart_name,
        email_id: json.email_id,
        sonar_token: json.sonar_token,
        helm_branch: json.helm_branch,
        helm_repo: json.helm_repo,
        helm_repo_username: json.helm_repo_username,
        helm_repo_access_token: json.helm_repo_access_token,
        containerization: containerization,
        user_email: location.state.user_email,
        aws_cred_id: json.aws_cred_id,
        acr_cred_id: json.acr_cred_id,
        service_principal_cred_id: json.service_principal_cred_id,
        sonarqube_server_url: json.sonarqube_server_url,
        sonarqube_token: json.sonarqube_token,
        sonarqube_project_key: json.sonarqube_project_key,
      })
    } else {
      setData({
        git_repo: location.state.repo,
        builder: "",
        image_name: "",
        src_folder_name: location.state.repoName,
        ci_cd_branch: "",
        deployment_metadata_name: "",
        replicas: "",
        selector_name: "",
        image: "",
        container_port: "",
        manifest1: "k8/deployment.yaml",
        manifest2: "k8/service.yaml",
        type: "",
        project_id: "",
        aws_id: "",
        cluster_name: "",
        location: "",
        credentials_id: "",
        application: location.state.application,
        token: "",
        branch: "",
        repo_type: "",
        entrypoint: "",
        helm_chart_name: "",
        email_id: "",
        sonar_token: "",
        helm_branch: "",
        helm_repo: "",
        helm_repo_username: "",
        helm_repo_access_token: "",
        containerization: containerization,
        user_email: location.state.user_email,
        aws_cred_id: "",
        acr_cred_id: "",
        service_principal_cred_id: "",
        sonarqube_server_url: "",
        sonarqube_token: "",
        sonarqube_project_key: "",
      })
    }
  }

  const handleSave = async () => {
    setMigrationPop(true)
  }

  const saveAndMigrate = async () => {
    setLoading(true)
    console.log(data)
    await axios
      .post(`${baseUrl}/`, data)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })

    await axios
      .get(
        `${baseUrl}/run-script?deployment=${deploymentOption}&repo_name=${data.src_folder_name}&cloud_service=${cloudService}&sonar_required=${sonarqubeRequired}&user=${data.user_email}`,
      )
      .then((response) => {
        response.status === 200
          ? setResponseData(response.data)
          : setResponseData(null)
        setLoading(false)
        setMigrationDone(true)
        setOneTimeMigration(true)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const handleLogoClick = async () => {
    try {
      navigate("/dashboard")
      const response = await axios.get(`${baseUrl}/clear_project_directory`)
      console.log(response)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  // Function to handle changes in the selected option
  const handleOptionChange = (event) => {
    setDeploymentOption(event.target.value)
  }

  const handleDeploymentOptionChange = (e) => {
    setCloudService(e.target.value)
  }

  //change Dockerfile or buildpacks option
  const handleDockerCheckbox = () => {
    setIsDockerChecked(!isDockerChecked)
    if (!isDockerChecked) {
      const newData = { ...data, containerization: "dockerfile" }
      setData(newData)
    } else {
      const newData = { ...data, containerization: "buildpacks" }
      setData(newData)
    }
  }

  //dockerfile button click to open a popup
  const handleDockerClick = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/convert_dockerfile?type=${data.application}&repoHasDocker=${location.state.repoHasDocker}`,
      )
      // console.log(response)
      setDockerJson(response.data.dockerfileJSON)
    } catch (error) {
      console.error("Error:", error)
    }
    setPopupOpen(true)
  }

  const handlPopup = (data) => {
    setPopupOpen(data)
  }
  const handleSonarqubeChange = () => {
    setSonarqubeRequired(!sonarqubeRequired)
  }
  const handlePipelineCreation = () => {
    const jsonData = {
      branch: location.state.branch,
      repoHasDocker: location.state.repoHasDocker,
      repoName: location.state.repoName,
      data: data.git_repo,
      application: data.application,
      token: data.token,
      repo_type: data.repo_type,
      deployment: deploymentOption,
      cloudService: cloudService,
      user_email: data.user_email,
    }
    navigate("/start", {
      state: jsonData,
    })
  }

  const handleClose = () => {
    setMigrationPop(false)
    setMigrationDone(false)
  }

  const handleBackwardClick = () => {
    navigate("/assess", {
      state: {
        application: location.state.application,
        repo: location.state.repo,
        token: location.state.token,
        branch: location.state.branch,
        selectedOption: location.state.selectedOption,
        repoHasDocker: location.state.repoHasDocker,
        repoName: location.state.repoName,
        user_email: location.state.user_email,
      },
    })
  }

  return (
    <div className='migrate-wrapper'>
      <div className='Navbar'>
        <div className='header-container'>
          <div className='logo-container'>
            <img src={Logo} onClick={handleLogoClick} />
          </div>
          <div className='nav-container'>
            <button
              className='nav-forward'
              disabled={!isFormFilled}
              style={!isFormFilled ? { backgroundColor: "#9F9F9F" } : {}}
              onClick={handlePipelineCreation}
            >
              <FaArrowRight />
            </button>
            <button className='nav-forward' onClick={handleBackwardClick}>
              <FaArrowLeft />
            </button>
          </div>
        </div>
      </div>
      <div className='migrate-data-wrapper'>
        <div className='migrate-options'>
          <p>Select cloud service</p>
          <div className='deployment-op'>
            <label>
              <input
                className='assess-input'
                type='radio'
                name='cloudService'
                value='gcp'
                checked={cloudService === "gcp"}
                onChange={handleDeploymentOptionChange}
              />
              GCP
            </label>
            <label>
              <input
                className='assess-input'
                type='radio'
                name='cloudService'
                value='aws'
                checked={cloudService === "aws"}
                onChange={handleDeploymentOptionChange}
              />
              AWS
            </label>
            <label>
              <input
                className='assess-input'
                type='radio'
                name='cloudService'
                value='azure'
                checked={cloudService === "azure"}
                onChange={handleDeploymentOptionChange}
              />
              Azure
            </label>
          </div>
          <p>Select deployment option</p>
          <div className='deployment-op'>
            <label>
              <input
                className='assess-input'
                type='radio'
                name='deploymentOption'
                value='helm'
                checked={deploymentOption === "helm"}
                onChange={handleOptionChange}
              />
              Helm
            </label>
            <label>
              <input
                className='assess-input'
                type='radio'
                name='deploymentOption'
                value='k8'
                checked={deploymentOption === "k8"}
                onChange={handleOptionChange}
              />
              Jenkins
            </label>
          </div>

          <div className='checkBox'>
            <label>
              <input
                className='assess-input'
                checked={isChecked}
                onChange={handleCheckboxChange}
                type='checkbox'
              />{" "}
              Show sample inputs
            </label>
          </div>

          <div className='checkBox'>
            <label>
              <input
                className='assess-input'
                checked={sonarqubeRequired}
                onChange={handleSonarqubeChange}
                type='checkbox'
              />{" "}
              Sonarqube
            </label>
          </div>
        </div>
        <div className='details-wrapper'>
          <div className='detail-box'>
            <div className='detail-box-header'>
              <p>Application details</p>
            </div>
            <label className='detail-box-label'>
              {" "}
              CI/CD branch name{" "}
              <input
                className='migrate-input-assess'
                type='text'
                name='ci_cd_branch'
                value={data.ci_cd_branch}
                title={fieldTitles.ci_cd_branch_title}
                onChange={handleChange}
                placeholder='branch name'
              />
            </label>
            {data.application === "Node.js" && (
              <label className='detail-box-label'>
                Entrypoint
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='entrypoint'
                  value={data.entrypoint}
                  title={fieldTitles.entrypoint_title}
                  onChange={handleChange}
                  placeholder='Application entry file'
                />
              </label>
            )}

            <label className='detail-box-label'>
              Container Port
              <input
                className='migrate-input-assess'
                type='text'
                name='container_port'
                value={data.container_port}
                title={fieldTitles.container_port_title}
                onChange={handleChange}
                placeholder='Application port'
              />
            </label>
          </div>

          <div className='detail-box'>
            <div className='detail-box-header'>
              <p>Cluster details</p>
            </div>

            <label className='detail-box-label'>
              {" "}
              Cluster name{" "}
              <input
                className='migrate-input-assess'
                type='text'
                name='cluster_name'
                value={data.cluster_name}
                title={fieldTitles.cluster_name_title}
                onChange={handleChange}
                placeholder='Cluster name'
              />
            </label>
            {cloudService !== "azure" && (
              <label className='detail-box-label'>
                Cluster region
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='location'
                  value={data.location}
                  title={fieldTitles.location_title}
                  onChange={handleChange}
                  placeholder='Clusters region'
                />
              </label>
            )}

            <label className='detail-box-label'>
              Service type
              <input
                className='migrate-input-assess'
                type='text'
                name='type'
                value={data.type}
                title={fieldTitles.type_title}
                onChange={handleChange}
                placeholder='Service type'
              />
            </label>
            <label className='detail-box-label'>
              {projectId}
              <input
                className='migrate-input-assess'
                type='text'
                name='project_id'
                value={data.project_id}
                title={fieldTitles.project_id_title}
                onChange={handleChange}
                placeholder={projectId}
              />
            </label>
            {cloudService === "aws" && (
              <label className='detail-box-label'>
                AWS Credential ID
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='aws_cred_id'
                  value={data.aws_cred_id}
                  // title={fieldTitles.project_id_title}
                  onChange={handleChange}
                  placeholder='AWS credential ID'
                />
              </label>
            )}
            {cloudService === "azure" && (
              <label className='detail-box-label'>
                ACR Credential ID
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='acr_cred_id'
                  value={data.acr_cred_id}
                  // title={fieldTitles.project_id_title}
                  onChange={handleChange}
                  placeholder='ACR credential ID'
                />
              </label>
            )}
            {cloudService === "azure" && (
              <label className='detail-box-label'>
                Service Principal Credential ID
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='service_principal_cred_id'
                  value={data.service_principal_cred_id}
                  // title={fieldTitles.project_id_title}
                  onChange={handleChange}
                  placeholder='Service Principal credential ID'
                />
              </label>
            )}
          </div>
          {deploymentOption === "helm" && (
            <div className='detail-box'>
              <div className='detail-box-header'>
                <p>Helm details</p>
              </div>

              <label className='detail-box-label'>
                Helm repo
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='helm_repo'
                  value={data.helm_repo}
                  title={fieldTitles.helm_repo_title}
                  onChange={handleChange}
                  placeholder='Repository to add helm'
                />
              </label>

              <label className='detail-box-label'>
                Branch name
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='helm_branch'
                  value={data.helm_branch}
                  title={fieldTitles.helm_branch_title}
                  onChange={handleChange}
                  placeholder='Branch name'
                />
              </label>
            </div>
          )}
          {deploymentOption === "k8" && (
            <div className='detail-box'>
              <div className='detail-box-header'>
                <p>Jenkins deployment details</p>
              </div>

              <label className='detail-box-label'>
                {" "}
                Replicas{" "}
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='replicas'
                  value={data.replicas}
                  title={fieldTitles.replicas_title}
                  onChange={handleChange}
                  placeholder='Cluster replicas'
                />
              </label>
              <label className='detail-box-label'>
                Deployment name
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='deployment_metadata_name'
                  value={data.deployment_metadata_name}
                  title={fieldTitles.deployment_metadata_name_title}
                  onChange={handleChange}
                  placeholder='Deployment name'
                />
              </label>

              {cloudService === "gcp" && (
                <label className='detail-box-label'>
                  Credentials ID
                  <input
                    className='migrate-input-assess'
                    type='text'
                    name='credentials_id'
                    value={data.credentials_id}
                    title={fieldTitles.credentials_id_title}
                    onChange={handleChange}
                    placeholder='Credentials id'
                  />
                </label>
              )}
            </div>
          )}

          <div className='detail-box'>
            {isPopupOpen && (
              <DockerPopup handlPopup={handlPopup} json={dockerJson} />
            )}
            <div className='detail-box-header'>
              <p>Containerization details</p>
            </div>

            <div className='Dockerfile'>
              <label>
                <input
                  className='assess-input'
                  type='checkbox'
                  checked={isDockerChecked}
                  onChange={handleDockerCheckbox}
                />
                Dockerfile
              </label>
              {isDockerChecked && <p onClick={handleDockerClick}>Edit</p>}
            </div>

            <label className='detail-box-label'>
              Image name
              <input
                className='migrate-input-assess'
                type='text'
                name='image_name'
                value={data.image_name}
                title={fieldTitles.image_name_title}
                onChange={handleChange}
                placeholder='Image name'
              />
            </label>
            {!isDockerChecked && (
              <label className='detail-box-label'>
                {" "}
                Buildpacks builder{" "}
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='builder'
                  value={data.builder}
                  title={fieldTitles.builder_title}
                  onChange={handleChange}
                  placeholder='Buildpacks builder'
                />
              </label>
            )}

            <label className='detail-box-label'>
              Artifact registry path
              <input
                className='migrate-input-assess'
                type='text'
                name='image'
                value={data.image}
                title={fieldTitles.image_title}
                onChange={handleChange}
                placeholder='Artifact registry path'
              />
            </label>
          </div>

          {sonarqubeRequired && (
            <div className='detail-box'>
              <div className='detail-box-header'>
                <p>Sonarqube details</p>
              </div>
              <label className='detail-box-label'>
                Server url
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='sonarqube_server_url'
                  value={data.sonarqube_server_url}
                  title={fieldTitles.helm_repo_title}
                  onChange={handleChange}
                  placeholder='Sonarqube server url'
                />
              </label>

              <label className='detail-box-label'>
                Project token
                <input
                  className='migrate-input-assess'
                  type='password'
                  name='sonarqube_token'
                  value={data.sonarqube_token}
                  // title={fieldTitles.helm_branch_title}
                  onChange={handleChange}
                  placeholder='Sonarqube project token'
                />
              </label>

              <label className='detail-box-label'>
                Server name
                <input
                  className='migrate-input-assess'
                  type='text'
                  name='sonarqube_project_key'
                  value={data.sonarqube_project_key}
                  // title={fieldTitles.helm_branch_title}
                  onChange={handleChange}
                  placeholder='Sonarqube Project key'
                />
              </label>
            </div>
          )}

          <div className='migrate-button-wrapper'>
            <div>
              {oneTimeMigration && (
                <button
                  className='assess-button'
                  onClick={handlePipelineCreation}
                  disabled={!isFormFilled}
                  style={{ marginLeft: "2%" }}
                >
                  Create pipeline
                </button>
              )}

              <button
                className='assess-button'
                onClick={handleSave}
                disabled={!isFormFilled}
                style={{
                  ...(!isFormFilled ? { backgroundColor: "#9F9F9F" } : {}),
                  ...(oneTimeMigration
                    ? {
                        backgroundColor: "white",
                        border: "1px solid #5a57ff",
                        color: "#5a57ff",
                      }
                    : {}),
                }}
              >
                Push Artifacts
              </button>
            </div>
          </div>
          {migrationPop && (
            <div className='migrate-pop-wrapper'>
              <div className='migrate-pop'>
                <div className='migrate-pop-img'>
                  <img src={Vector} onClick={handleClose} />
                </div>
                {!migrationDone ? (
                  <div>
                    <p className='migrate-pop-head'>
                      Would you like to start the Artifacts migration Process?
                    </p>
                    <p className='migrate-pop-desc'>
                      If yes, please click "Start" button to proceed
                    </p>
                    <button
                      className='assess-button'
                      style={loading ? { backgroundColor: "#5a57ff" } : {}}
                      onClick={saveAndMigrate}
                    >
                      {loading ? (
                        <img className='loading-gif' src={LoadingGif} />
                      ) : (
                        "Start"
                      )}
                    </button>
                  </div>
                ) : (
                  <div>
                    <div>
                      {" "}
                      <img className='successIcon' src={SuccessIcon} />{" "}
                    </div>
                    <p className='app-migrated'>
                      Files are updated in repository
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Migrate
