import backgroundimage from "../Images/background-image.jpg"
import "../styles/sshgen.css"
import Copyimg from "../Images/copy.jpg"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/auth"
import axios from "axios"
import { Logo } from "./constants"
import { checkUserToken } from "../hooks/session"

const Sshgen = (props) => {
  const hostIp = process.env.REACT_APP_HOST_IP || "localhost"
  const baseUrl =
    process.env.REACT_APP_BASE_URL || `http://${hostIp}:5000/node/api`

  const { cookies, setCookies, logout } = useAuth()
  axios.defaults.headers.common = { Authorization: `${cookies.token}` }

  const navigate = useNavigate()
  const [sshKeyText, setSshKeyText] = useState("")
  const userEmail = cookies.email

  async function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy)
    } else {
      const textArea = document.createElement("textarea")
      textArea.value = textToCopy

      textArea.style.position = "absolute"
      textArea.style.left = "-999999px"

      document.body.prepend(textArea)
      textArea.select()

      try {
        document.execCommand("copy")
      } catch (error) {
        console.error(error)
      } finally {
        textArea.remove()
      }
    }
  }

  const handleLogoClick = async () => {
    try {
      navigate("/dashboard")
      await axios.get(`${baseUrl}/clear_project_directory`)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkUserToken(cookies, setCookies, logout)
      if (!isAuthenticated) {
        navigate("/")
      }
    }

    fetchData()
  }, [cookies.token, logout, navigate])

  const copySshKey = async () => {
    try {
      await copyToClipboard(sshKeyText)
      alert("SSH key copied to clipboard!")
    } catch (error) {
      console.error(error)
    }
  }
  const generateSshKey = () => {
    setSshKeyText("")
    try {
      axios
        .post(`${baseUrl}/gen-ssh-key`, { email: userEmail })
        .then((response) => {
          if (response.data.message) {
            alert("Username/Email already taken choose another")
          } else {
            setSshKeyText(response.data.publicKey)
          }
        })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className='home-wrapper'>
      <div className='home-img'>
        <img src={backgroundimage} />
      </div>
      <div className='home-data'>
        <img src={Logo} onClick={handleLogoClick} />
        <div className='email-wrapper'>
          <button
            className='assess-button'
            style={{ width: "100%" }}
            onClick={generateSshKey}
          >
            Generate SSH key
          </button>
        </div>
        <div className='ssh-section'>
          <p>Copy your public key ... </p>
          <div className='ssh-key-holder'>
            <div className='copyIcon'>
              <img src={Copyimg} onClick={copySshKey} />
            </div>
            <p>{sshKeyText}</p>
          </div>
          <div>
            <p>Note </p>
            <div className='ssh-note'>
              <p>
                Copy the pulic key and add it for authentication in git and If
                you are using this tool for deployment, add it in
                <strong> authorized_keys</strong> file in Jenkins VM
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Sshgen
