import React from "react"
import { Route, Routes } from "react-router-dom"
import Migrate from "./components/Migrate"
import Assess from "./components/Assess"
import Sshgen from "./components/SshGen"
import Jenkinsgen from "./components/Jenkinsgen"
import Deployment from "./components/Deployment"
import Login from "./components/Login"
import Signup from "./components/Register"
import Dashboard from "./components/Dashboard"
import GitRepo from "./components/MultiRepo"
import BulkMigration from "./components/bulkMigration/BulkMigration"
import BulkMigrationGitLogin from "./components/bulkMigration/GitLogin"
import { ProtectRoutes } from "./hooks/protectRoutes"
import AllRepositories from "./components/bulkMigration/AllRepo"
import JobHistory from "./components/bulkMigration/JobHistory"
import Deployments from "./components/bulkMigration/DeploymentProf"
import GenArtifact from "./components/bulkMigration/GenArtifact"
import Settings from "./components/bulkMigration/Settings"
import JenkinsCredentials from "./components/bulkMigration/JenkinsCredentials"
import CreateCluster from "./components/Cluster"
import ArgoCD from "./components/ArgoCD"
import Argocd from "./components/bulkMigration/Argocd"

function App() {
  return (
    <div>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/signup' element={<Signup />} />

        <Route element={<ProtectRoutes />}>
          <Route exact path='/sshgen' element={<Sshgen />} />
          <Route exact path='/jenkins' element={<Jenkinsgen />} />
          <Route exact path='/cluster' element={<CreateCluster />} />
          <Route exact path='/argocd' element={<ArgoCD />} />
          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/assess' element={<Assess />} />
          <Route exact path='/migrate' element={<Migrate />} />
          <Route exact path='/start' element={<Deployment />} />
          <Route exact path='/git' element={<GitRepo />} />
          <Route
            exact
            path='/bulk-migration/git-login'
            element={<BulkMigrationGitLogin />}
          />
          <Route
            exact
            path='/bulk-migration/all-repo'
            element={<AllRepositories />}
          />
        </Route>
        <Route
          exact
          path='/bulk-migration/generate-artifact'
          element={<GenArtifact />}
        />
        <Route
          exact
          path='/bulk-migration/jenkins-credentials'
          element={<JenkinsCredentials />}
        />
        <Route
          path='/bulk-migration/deployment-profile'
          element={<Deployments />}
        />
        <Route path='/bulk-migration/job-history' element={<JobHistory />} />
        <Route path='/bulk-migration/settings' element={<Settings />} />
        <Route path='/bulk-migration/argocd' element={<Argocd />} />
      </Routes>
    </div>
  )
}

export default App
